import { ActionIcon, Image, Text, rem } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useLazyLoadQuery } from "react-relay";

import aiStarElement from "../assets/ai_star_element.svg";
import { genCurrentWaveDate } from "../util/dataProcessing";
import { FontFamily } from "../util/fontFamily";
import { VisualisationProps } from "./Visualisation/types";
import { AiSummaryQuery as AiSummaryQueryType } from "./__generated__/AiSummaryQuery.graphql";

const AiSummaryQuery = graphql`
  query AiSummaryQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    aiSummary(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      GPT_COMMENTARY
    }
  }
`;

type AiSummaryProps = {
  readonly onClose: () => void;
  readonly bodyText?: string;
} & Pick<VisualisationProps, "dashBoardFilters">;

export const AiSummary: React.FC<AiSummaryProps> = (props: AiSummaryProps) => {
  const { dashBoardFilters, onClose, bodyText } = props;
  const { clientId, metric, roll, category, firstAudience, secondAudience } =
    dashBoardFilters;
  const { aiSummary } = useLazyLoadQuery<AiSummaryQueryType>(AiSummaryQuery, {
    clientId,
    metric,
    roll,
    category,
    firstAudience,
    secondAudience,
  });
  const summaryText = bodyText || aiSummary?.[0]?.GPT_COMMENTARY;

  const [lastUpdated, setLastUpdated] = useState<string>("");

  useEffect(() => {
    const lastCompletedWave = genCurrentWaveDate();
    const lastWaveDate = new Date(lastCompletedWave);
    const lastWaveMonth = lastWaveDate.toLocaleString("default", {
      month: "long",
    });
    const lastWaveYear = lastWaveDate.getFullYear();
    setLastUpdated(`${lastWaveMonth} ${lastWaveYear}`);
  }, []);

  return (
    <div className="h-[calc(100vh-50px)] p-5 pb-[150px] w-full">
      <div className="flex flex-row justify-between">
        <Image src={aiStarElement} alt="AI Star Element" className="w-6 h-6 " />
        <ActionIcon onClick={onClose} color="black" variant="white" size={36}>
          <MdOutlineClose style={{ width: rem(24), height: rem(24) }} />
        </ActionIcon>
      </div>
      <Text ff={FontFamily.PPNeueMontrealMedium} size={"16px"} mt={"26px"}>
        AI Summary
      </Text>
      <Text
        ff={FontFamily.PPNeueMontrealMedium}
        size={"10px"}
        opacity={0.3}
        mt={"8px"}
      >{`Last updated: ${lastUpdated}`}</Text>
      <div className="w-full h-full mt-4 overflow-y-auto">
        {summaryText &&
          summaryText.split("\n").map((line, index) => (
            <Text
              key={index}
              ff={FontFamily.PPNeueMontrealRegular}
              size={"12.5px"}
              lh={"16px"}
              pb={6}
            >
              {line}
            </Text>
          ))}
      </div>
    </div>
  );
};

import { colours, tintHexCode } from "./colours";

export enum NpsSentiment {
  Positive = "Positive",
  Neutral = "Neutral",
  Negative = "Negative",
}

/**
 * Converts an NPS sentiment to a hex code colour.
 *
 * @param sentiment The NPS sentiment to convert.
 * @returns The hex code colour.
 */
export const npsSentimentToHexCode = (sentiment: NpsSentiment): string => {
  switch (sentiment) {
    case NpsSentiment.Positive:
      return tintHexCode(colours.secondaryBrand.green, 0.5);
    case NpsSentiment.Neutral:
      return tintHexCode(colours.secondaryBrand.grey, 0.5);
    case NpsSentiment.Negative:
      return tintHexCode(colours.primaryBrand.red, 0.5);
  }
};

/**
 * Converts a percentage to an NPS sentiment.
 *
 * @param percentage The percentage to convert.
 * @returns The NPS sentiment.
 */
export const getSentimentFromPercentage = (
  percentage: number,
): NpsSentiment => {
  if (percentage > 0.5) {
    return NpsSentiment.Positive;
  }
  if (percentage < -0.5) {
    return NpsSentiment.Negative;
  }
  return NpsSentiment.Neutral;
};

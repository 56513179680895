import { BrandConfig, ClientConfig } from "@tra-nz/platform-common";

import { generateDefaultColourPalette } from "./colours";

/**
 * Generates the brand configurations for the client and competitor brands
 * as well as any brands not configured in the client config.
 *
 * It will give the configs of the configure brands even if they are not in the list of brands.
 * This is to ensure that the colours are consistent across the dashboard.
 *
 * @param config
 * @param brands
 * @returns
 */
export const genBrandConfigs = (
  config: ClientConfig,
  brands: ReadonlyArray<string>,
): ReadonlyArray<BrandConfig> => {
  const configuredBrands = [...config.clientBrands, ...config.competitorBrands];
  const unConfiguredBrands = brands.filter(
    (brand) => !configuredBrands.some((b) => b.name === brand),
  );
  const allBrands: ReadonlyArray<BrandConfig> = [
    ...configuredBrands,
    ...unConfiguredBrands.map((name) => ({ name })),
  ];

  const defaultColours = generateDefaultColourPalette(allBrands.length);
  const unusedColours = defaultColours.filter(
    (colour) => !allBrands.some((brand) => brand.colour === colour),
  );
  return allBrands.map((brand) => {
    if (brand.colour) {
      return brand;
    }
    // Use the first unused colour and remove it from the list
    const colour = unusedColours.shift();
    return { ...brand, colour };
  });
};

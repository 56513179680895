import { Collapse, Divider, Image, Text, rem } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { CreativeEdgeTab } from "@tra-nz/platform-common";
import graphql from "babel-plugin-relay/macro";
import { isNumber } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { RxCaretRight } from "react-icons/rx";
import { useLazyLoadQuery } from "react-relay";

import creativeEdgeTriangle from "../../assets/creative_edge_triangle.svg";
import {
  CreativeEdgeMetrics,
  CreativeEdgePillars,
} from "../../util/creativeEdge";
import { FontFamily } from "../../util/fontFamily";
import { sortByOrderThenAlphabeticalFn } from "../../util/sort";
import { FOOTER_HEIGHT } from "../Visualisation/VisualisationFooter";
import { CampaignFooter } from "./CampaignFooter";
import { CAMPAIGN_HEADER_HEIGHT, CampaignHeader } from "./CampaignHeader";
import { CampaignPillarBreakdown } from "./CampaignPillarBreakdown";
import { CreativeEdgeFilters } from "./CreativeEdgeFilterBar";
import { CreativeEdgeSnapshotQuery as CreativeEdgeSnapshotQueryType } from "./__generated__/CreativeEdgeSnapshotQuery.graphql";

type CreativeEdgeSnapshotProps = {
  readonly clientId: string;
  readonly tabItemConfig?: CreativeEdgeTab;
  readonly campaignId: number;
  readonly height: number;
  readonly audienceFilters: CreativeEdgeFilters;
};

const CreativeEdgeSnapshotQuery = graphql`
  query CreativeEdgeSnapshotQuery(
    $clientId: String!
    $campaignId: Int!
    $metric: String!
    $audience: String!
    $secondaryAudience: String
  ) {
    pillars: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        ROLL: $campaignId
        IS_SCORE: 1
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
      }
    ) {
      PROJECT_ID
      BASE
      BRAND
      PERCENTAGE
      STATEMENT
    }
    diagnostics: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        ROLL: $campaignId
        IS_SCORE: 0
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
      }
    ) {
      PERCENTAGE
      STATEMENT
      QUESTION_TEXT
    }
  }
`;

/**
 * Creative Edge Snapshot summary view
 *
 * @param props the options to generate the snapshot
 * @returns the snapshot component
 */
export const CreativeEdgeSnapshot: React.FC<CreativeEdgeSnapshotProps> = (
  props: CreativeEdgeSnapshotProps,
) => {
  const { audienceFilters, clientId, tabItemConfig, campaignId, height } =
    props;

  const [pillarDropdowns, setPillarDropdowns] = useState<
    Record<string, boolean>
  >({});

  const diagnosticOrder = tabItemConfig?.topDiagnostics || [];

  const queryData = useLazyLoadQuery<CreativeEdgeSnapshotQueryType>(
    CreativeEdgeSnapshotQuery,
    {
      clientId: clientId,
      campaignId: campaignId,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...audienceFilters,
    },
  );

  const {
    height: triangleContainerHeight,
    width: triangleContainerWidth,
    ref: triangleContainerRef,
  } = useElementSize();

  const togglePillarDropdown = useCallback(
    (pillar: string) => {
      setPillarDropdowns({
        ...pillarDropdowns,
        [pillar]: !pillarDropdowns[pillar],
      });
    },
    [pillarDropdowns],
  );

  const triangleScaleFactor = useMemo<number>(() => {
    if (triangleContainerHeight && triangleContainerWidth) {
      const baseRatio =
        Math.min(triangleContainerHeight, triangleContainerWidth) /
        triangleContainerWidth;
      return baseRatio * 0.9;
    }
    return 1;
  }, [triangleContainerHeight, triangleContainerWidth]);

  const trackerProjectId = useMemo(() => {
    if (queryData.pillars && queryData.pillars[0]?.PROJECT_ID) {
      return queryData.pillars[0].PROJECT_ID;
    }
  }, [queryData]);

  const campaignTitle = useMemo(() => {
    if (queryData.pillars && queryData.pillars[0]?.STATEMENT) {
      return queryData.pillars[0].BRAND || undefined;
    }
  }, [queryData]);

  const pillarScores = useMemo<{
    [key in CreativeEdgePillars]?: number;
  }>(() => {
    if (!queryData.pillars) {
      return {};
    }
    const findScore = (metric: CreativeEdgePillars) => {
      const foundValue =
        queryData.pillars?.find((dataPoint) => dataPoint?.STATEMENT === metric)
          ?.PERCENTAGE || 0;
      return Math.round(foundValue);
    };
    return {
      [CreativeEdgePillars.CREATIVE_EDGE_SCORE]: findScore(
        CreativeEdgePillars.CREATIVE_EDGE_SCORE,
      ),
      [CreativeEdgePillars.REMARKABLE]: findScore(
        CreativeEdgePillars.REMARKABLE,
      ),
      [CreativeEdgePillars.REWARDING]: findScore(CreativeEdgePillars.REWARDING),
      [CreativeEdgePillars.REMEMBERED]: findScore(
        CreativeEdgePillars.REMEMBERED,
      ),
    };
  }, [queryData.pillars]);

  const baseSize = useMemo(() => {
    if (queryData.pillars && queryData.pillars.length > 0) {
      const filteredData = queryData.pillars
        .map((data) => data?.BASE)
        .filter(isNumber);
      if (filteredData.length > 0) {
        const averageBaseSize =
          filteredData.reduce((acc, curr) => acc + curr, 0) /
          filteredData.length;
        const allBaseSizesSame = filteredData.every(
          (value) => value === averageBaseSize,
        );
        const prefix = allBaseSizesSame ? "Base Size: " : "Average Base Size: ";
        return `${prefix}${Math.round(averageBaseSize)}`;
      }
    }
    return null;
  }, [queryData.pillars]);

  return (
    <div
      className="flex flex-col w-full"
      style={{
        height: height,
      }}
    >
      <CampaignHeader
        trackerProjectId={trackerProjectId}
        campaignId={campaignId}
        campaignTitle={campaignTitle}
      />
      <Divider />
      <div
        className="flex flex-row w-full"
        style={{
          height: height - CAMPAIGN_HEADER_HEIGHT,
        }}
      >
        {/* Creative Edge Triangle */}
        <div className="flex flex-col w-1/3 p-6 pb-0 overflow-y-auto">
          <Text
            ff={FontFamily.PPNeueMontrealMedium}
            size={"18px"}
            className="pb-9"
          >
            Creative Edge Score
          </Text>
          <div
            ref={triangleContainerRef}
            className="relative flex items-center justify-center w-full h-full pb-6"
          >
            <Image
              className="absolute"
              src={creativeEdgeTriangle}
              w={triangleContainerWidth * triangleScaleFactor}
            />
            <div
              className="absolute"
              style={{
                left: `calc(50% - ${triangleContainerWidth * triangleScaleFactor * 0.28}px)`,
              }}
            >
              <Text
                ff={FontFamily.PPNeueMontrealBold}
                size={`${triangleContainerWidth * triangleScaleFactor * 0.25}px`}
              >
                {pillarScores["Creative Edge"]}
              </Text>
            </div>
          </div>
          <CampaignFooter baseSizeText={baseSize} />
        </div>
        {/* Creative Edge Pillars */}
        <div className="flex flex-col w-1/3 h-full p-6 overflow-y-auto">
          <Text
            ff={FontFamily.PPNeueMontrealMedium}
            size={"18px"}
            className="pb-9"
          >
            Pillars
          </Text>
          <Divider />
          <div
            className="flex flex-col w-full overflow-y-auto"
            style={{
              height: triangleContainerHeight + FOOTER_HEIGHT,
            }}
          >
            {Object.entries(pillarScores)
              .filter(([metric]) => metric !== "Creative Edge")
              .map(([metric, value], index) => {
                return (
                  <div key={index} className="flex flex-col w-full">
                    <div
                      className="flex flex-row items-center justify-between w-full cursor-pointer"
                      onClick={() => togglePillarDropdown(metric)}
                    >
                      <div className="flex flex-row items-center">
                        <Text
                          ff={FontFamily.PPNeueMontrealMedium}
                          size={"40px"}
                          p={"md"}
                          pl={0}
                        >
                          {value}
                        </Text>
                        <Text
                          ff={FontFamily.PPNeueMontrealBook}
                          size={"20px"}
                          p={"lg"}
                        >
                          {metric}
                        </Text>
                      </div>
                      <RxCaretRight
                        style={{
                          transform: pillarDropdowns[metric]
                            ? "rotate(90deg)"
                            : "",
                          transition: "transform 0.3s",
                          width: rem(32),
                          height: rem(32),
                        }}
                      />
                    </div>
                    <Collapse in={pillarDropdowns[metric]}>
                      <CampaignPillarBreakdown
                        clientId={clientId}
                        campaignId={campaignId}
                        pillar={metric}
                        audienceFilters={audienceFilters}
                      />
                    </Collapse>
                    <Divider />
                  </div>
                );
              })}
          </div>
        </div>
        <Divider orientation="vertical" />
        {/* Diagnostics */}
        <div className="flex flex-col w-1/3 h-full p-6">
          <Text
            ff={FontFamily.PPNeueMontrealMedium}
            size={"18px"}
            className="pb-9"
          >
            Diagnostics
          </Text>
          <Divider />
          <div
            className="flex flex-col w-full overflow-y-auto"
            style={{
              height: triangleContainerHeight + FOOTER_HEIGHT,
            }}
          >
            {queryData.diagnostics
              ?.filter((diagnostic) => diagnostic?.QUESTION_TEXT === null)
              .sort((a, b) => {
                return sortByOrderThenAlphabeticalFn(diagnosticOrder)(
                  a?.STATEMENT,
                  b?.STATEMENT,
                );
              })
              .map((diagnostic, index) => {
                const percentage =
                  diagnostic?.PERCENTAGE &&
                  Math.round(diagnostic.PERCENTAGE * 100);
                return (
                  <div key={index} className="flex flex-col w-full">
                    <div className="flex flex-row items-center w-full ">
                      <Text
                        ff={FontFamily.PPNeueMontrealBold}
                        size={"20px"}
                        p={"md"}
                        pl={0}
                        w={60}
                      >
                        {percentage}%
                      </Text>
                      <Text
                        ff={FontFamily.PPNeueMontrealBook}
                        size={"20px"}
                        p={"lg"}
                      >
                        {diagnostic?.STATEMENT}
                      </Text>
                    </div>
                    <Divider />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

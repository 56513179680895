import { Button, Text } from "@mantine/core";

import { FontFamily } from "../util/fontFamily";

export type LegendSeries = {
  readonly name: string;
  readonly colour?: string;
  readonly enabled: boolean;
};

type LegendProps = {
  series: ReadonlyArray<LegendSeries>;
  marker?: "circle" | "line";
  onToggleSeries: (seriesName: string) => void;
  onShowOnlySeries?: (seriesName: string) => void;
  justify?: "flex-start" | "flex-end";
};

/**
 * A general legend component that displays a list of series with a toggle button.
 *
 * @param props The props for the component.
 * @returns The Legend component.
 */
export const Legend: React.FC<LegendProps> = (props: LegendProps) => {
  const { series, marker = "line", onToggleSeries, justify = "end" } = props;

  return (
    <div
      className="flex w-full min-w-full pt-2 overflow-y-auto"
      style={{
        ...(justify && { justifyContent: justify }),
      }}
    >
      <div className="flex flex-row flex-wrap items-center justify-end gap-1">
        {series.map((data) => {
          const { name, colour, enabled } = data;
          const leftSection =
            marker === "line" ? (
              <div
                className="w-4 h-[3px]"
                style={{ backgroundColor: colour }}
              />
            ) : (
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: colour }}
              />
            );
          return (
            <Button
              key={`legend-${name}`}
              onClick={() => {
                onToggleSeries(name);
              }}
              // onDoubleClick={() => {
              //   onShowOnlySeries && onShowOnlySeries(name);
              // }}
              autoContrast
              size="compact-xs"
              color="#f0f0f0"
              h={"26px"}
              variant={enabled ? "filled" : "outline"}
              radius={"xl"}
              // a small margin to allow for the change in size when the button is clicked
              m={1}
              leftSection={colour && leftSection}
            >
              <Text
                className="text-xs"
                style={enabled ? {} : { color: "black" }}
                ff={FontFamily.PPNeueMontrealRegular}
              >
                {name}
              </Text>
            </Button>
          );
        })}
      </div>
    </div>
  );
};

import { Divider, Text, TextInput } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useLazyLoadQuery } from "react-relay";

import { CreativeEdgeMetrics } from "../../util/creativeEdge";
import { FontFamily } from "../../util/fontFamily";
import { CodedUnpromptedMessagesBreakdown } from "../Visualisation/CodedUnpromptedMessagesBreakdown";
import { CAMPAIGN_HEADER_HEIGHT, CampaignHeader } from "./CampaignHeader";
import { CreativeEdgeFilters } from "./CreativeEdgeFilterBar";
import { CreativeEdgeUnpromptedMessagesQuery as CreativeEdgeUnpromptedMessagesQueryType } from "./__generated__/CreativeEdgeUnpromptedMessagesQuery.graphql";

const CreativeEdgeUnpromptedMessagesQuery = graphql`
  query CreativeEdgeUnpromptedMessagesQuery(
    $clientId: String!
    $campaignId: Int!
    $metric: String!
  ) {
    campaign: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: $metric, ROLL: $campaignId }
    ) {
      PROJECT_ID
      BRAND
      STATEMENT
    }
  }
`;

type CreativeEdgeUnpromptedMessagesProps = {
  readonly clientId: string;
  readonly campaignId: number;
  readonly height: number;
  readonly audienceFilters: CreativeEdgeFilters;
  readonly useLegacyCommentView: boolean;
};

/**
 * Creative Edge Unprompted Messages component
 *
 * @param props the options to generate the unprompted messages
 * @returns the unprompted messages component
 */
export const CreativeEdgeUnpromptedMessages: React.FC<
  CreativeEdgeUnpromptedMessagesProps
> = (props: CreativeEdgeUnpromptedMessagesProps) => {
  const { clientId, campaignId, height, useLegacyCommentView } = props;

  const [search, setSearch] = useState("");

  const queryData = useLazyLoadQuery<CreativeEdgeUnpromptedMessagesQueryType>(
    CreativeEdgeUnpromptedMessagesQuery,
    {
      clientId: clientId,
      campaignId: campaignId,
      metric: CreativeEdgeMetrics.CAMPAIGN_MESSAGE,
    },
  );

  const campaignTitle = useMemo(() => {
    return queryData.campaign?.[0]?.BRAND || "";
  }, [queryData]);

  const trackerProjectId = useMemo(() => {
    return queryData.campaign?.[0]?.PROJECT_ID || 0;
  }, [queryData]);

  const allStatements = useMemo(() => {
    return queryData.campaign?.map((campaign) => campaign?.STATEMENT) || [];
  }, [queryData.campaign]);

  const searchedStatements = useMemo(() => {
    if (!search) {
      return allStatements;
    }
    const fuse = new Fuse(allStatements, {
      includeScore: true,
      threshold: 0.3,
    });
    return fuse.search(search).map((result) => result.item);
  }, [allStatements, search]);

  const legacyCommentView = (
    <div
      className="flex flex-col w-full h-full px-6"
      style={{
        height: height - CAMPAIGN_HEADER_HEIGHT,
      }}
    >
      <Text ff={FontFamily.PPNeueMontrealMedium} size={"16px"} py={"xl"}>
        Unprompted message outtake
      </Text>
      <TextInput
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        leftSection={<HiSearch size={16} />}
        placeholder={"Search"}
        mb={"lg"}
        maw={600}
      />
      <Divider />
      <div className="flex flex-col overflow-y-auto">
        {searchedStatements.map((statement, index) => (
          <div key={index} className="flex flex-col w-full">
            <div className="flex flex-row w-full py-7">
              <Text ff={FontFamily.PPNeueMontrealBook} size={"32px"} fw={400}>
                {`"${statement}"`}
              </Text>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col w-full"
      style={{
        height,
      }}
    >
      <CampaignHeader
        trackerProjectId={trackerProjectId}
        campaignId={campaignId}
        campaignTitle={campaignTitle}
      />
      <Divider />
      {useLegacyCommentView ? (
        legacyCommentView
      ) : (
        <CodedUnpromptedMessagesBreakdown
          height={height - CAMPAIGN_HEADER_HEIGHT}
          dashBoardFilters={{
            clientId,
            firstAudience: "Total",
            secondAudience: null,
            roll: 1,
            metric: "campaign - topics",
            category: null,
          }}
          metric={"campaign - topics"}
          options={{
            subject: campaignTitle,
            questionNum: "campaign",
          }}
        />
      )}
    </div>
  );
};

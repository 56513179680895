import { Button, Menu, Text } from "@mantine/core";
import { FiChevronDown } from "react-icons/fi";

import { FontFamily } from "../../util/fontFamily";

type NavTabButtonProps = {
  readonly label: string;
  readonly onClick?: () => void;
  readonly isSelected?: boolean;
  menuItems?: {
    readonly title: string;
    readonly description?: string;
    readonly onClick: () => void;
    readonly isSelected?: boolean;
  }[];
};

/**
 * A button for the Navbar that opens a dropdown menu
 *
 * @param props the tab button properties and menu items
 * @returns the tab button component
 */
export const NavTabButton: React.FC<NavTabButtonProps> = (
  props: NavTabButtonProps,
) => {
  const { label, onClick, menuItems, isSelected } = props;

  const button = (
    <Button
      radius="3px"
      size="compact-md"
      className="h-[40px] text-base text-white bg-black  hover:bg-[#3D3D3D] active:bg-white active:text-black"
      style={{
        backgroundColor: isSelected ? "#3D3D3D" : undefined,
        fontFamily: FontFamily.PPNeueMontrealBold,
      }}
      onClick={onClick}
    >
      {label}
      {menuItems && menuItems.length > 0 && (
        <FiChevronDown className="ml-[10px]" strokeWidth={2} />
      )}
    </Button>
  );

  if (!menuItems || menuItems.length === 0) {
    return button;
  }

  return (
    <Menu offset={5}>
      <Menu.Target>{button}</Menu.Target>
      <Menu.Dropdown
        className="border-none rounded-sm"
        px={0}
        style={{
          backgroundColor: "#3D3D3D",
        }}
      >
        {menuItems.map((item) => (
          <Menu.Item
            key={item.title}
            onClick={item.onClick}
            className="text-white bg-transparent hover:bg-black active:bg-white active:text-black"
            style={{
              backgroundColor: item.isSelected ? "black" : undefined,
            }}
          >
            <div className="flex flex-col justify-center m-[16px] w-[300px]">
              <Text ff={FontFamily.PPNeueMontrealBold} size="16px" mb={"8px"}>
                {item.title}
              </Text>
              <Text
                ff={FontFamily.PPNeueMontrealRegular}
                size="14px"
                display={item.description ? undefined : "none"}
              >
                {item.description}
              </Text>
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

import { Image } from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { useUser } from "../../context/UserContext";
import { toUrlParamCase } from "../../util/toUrlParamCase";
import { NavTabButton } from "./NavTabButton";
import { ProfileMenu } from "./ProfileMenu";

export const NAVBAR_HEIGHT = 50;

const navbarParamsSchema = z.object({
  clientId: z.string().optional(),
  tabTitle: z.string().optional(),
  visualisationTitle: z.string().optional(),
});

/**
 * The navigation bar component
 *
 * @returns a navigation bar component
 */
export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, selectedClient } = useUser();

  const location = useLocation();
  const params = useParams();
  const { tabTitle } = navbarParamsSchema.parse(params);

  const homeRoute = `/dashboard/${selectedClient?.id}/home`;

  return (
    <div
      className="flex items-center bg-black px-[24px]"
      style={{
        height: NAVBAR_HEIGHT,
      }}
    >
      <Image
        src={process.env.PUBLIC_URL + "/TRA_logo_white.png"}
        w="auto"
        aria-label="logo"
        className="h-6"
      />
      <div className="flex pl-[40px] gap-[16px]">
        <NavTabButton
          label="Home"
          onClick={() => navigate(homeRoute)}
          isSelected={location.pathname === homeRoute}
        />
        {selectedClient?.config?.tabs?.map((tab) => (
          <NavTabButton
            key={tab.title}
            label={tab.title}
            isSelected={tabTitle === toUrlParamCase(tab.title)}
            menuItems={[
              ...tab.items.map((item) => {
                const itemPath = `/dashboard/${selectedClient?.id}/${toUrlParamCase(tab.title)}/${toUrlParamCase(item.title)}`;
                return {
                  title: item.title,
                  description: item.description,
                  onClick: () => {
                    navigate(itemPath);
                  },
                  isSelected: location.pathname === itemPath,
                };
              }),
            ]}
          />
        ))}
      </div>
      <div className="flex items-center justify-end flex-grow text-white">
        {currentUser && currentUser.clients.length > 1 && (
          <span className="text-white opacity-50">
            {currentUser.clients.find((c) => c.id === selectedClient?.id)?.name}
          </span>
        )}

        <ProfileMenu />
      </div>
    </div>
  );
};

/**
 * @generated SignedSource<<1c53849d820157b0493e75d1defc5008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreativeEdgeUnpromptedMessagesQuery$variables = {
  campaignId: number;
  clientId: string;
  metric: string;
};
export type CreativeEdgeUnpromptedMessagesQuery$data = {
  readonly campaign: ReadonlyArray<{
    readonly BRAND: string | null | undefined;
    readonly PROJECT_ID: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CreativeEdgeUnpromptedMessagesQuery = {
  response: CreativeEdgeUnpromptedMessagesQuery$data;
  variables: CreativeEdgeUnpromptedMessagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v3 = [
  {
    "alias": "campaign",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "campaignId"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PROJECT_ID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeEdgeUnpromptedMessagesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeEdgeUnpromptedMessagesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e94adc5c1ed404ca33b3a38ac7076d62",
    "id": null,
    "metadata": {},
    "name": "CreativeEdgeUnpromptedMessagesQuery",
    "operationKind": "query",
    "text": "query CreativeEdgeUnpromptedMessagesQuery(\n  $clientId: String!\n  $campaignId: Int!\n  $metric: String!\n) {\n  campaign: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, ROLL: $campaignId}) {\n    PROJECT_ID\n    BRAND\n    STATEMENT\n  }\n}\n"
  }
};
})();

(node as any).hash = "6478f8aae0c5e6edbd2f2e38f73e6b0a";

export default node;

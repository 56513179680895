import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";

import { roundValueForDisplay } from "../util/dataProcessing";

type ChangeIndicatorPillProps = {
  readonly value: number | null;
  readonly isSignificant: boolean;
  readonly isScore?: boolean;
};

export const ChangeIndicatorPill: React.FC<ChangeIndicatorPillProps> = (
  props: ChangeIndicatorPillProps,
) => {
  const { value, isScore } = props;

  // TODO: Implement proper significance checking
  const isSignificant = false;

  const roundedValue = value ? roundValueForDisplay(value) : null;
  const valueText = roundedValue === null ? "-" : roundedValue;
  const isPositive = roundedValue ? roundedValue > 0 : false;
  const backgroundColour = isPositive ? "#88A34A33" : "#F8E2DC";
  const arrowColour = isPositive ? "#62BF60" : "#D34B28";
  const prefix = isPositive ? "+" : "";
  const suffix = value === null || isScore ? "" : "%";

  return (
    <div
      className={
        "flex items-center justify-center h-7 rounded-full text-base p-2"
      }
      style={{
        background: isSignificant ? backgroundColour : "#F0F0F0",
      }}
    >
      {isSignificant &&
        (isPositive ? (
          <IoCaretUpSharp color={arrowColour} />
        ) : (
          <IoCaretDownSharp color={arrowColour} />
        ))}
      {`${prefix}${valueText}${suffix}`}
    </div>
  );
};

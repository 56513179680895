import { Divider } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { useUser } from "../../context/UserContext";
import { assertUnreachable } from "../../util/assertUnreachable";
import {
  ChangeSince,
  changeSinceToMonths,
  dateToChangeSince,
} from "../../util/changeSince";
import { CollatedData, offsetWaveDate } from "../../util/dataProcessing";
import { getBrandOrderForClient } from "../../util/genBrandCompareFn";
import { getStatementOrderFn } from "../../util/getStatementOrderForClient";
import { sortByOrderThenAlphabeticalFn } from "../../util/sort";
import { FilterComponentProps, MultiFilterBar } from "../MultiFilterBar";
import { BrandEdgeTriangle } from "./BrandEdgeTriangle";
import { GroupedPercentageStack } from "./GroupedPercentageStack";
import { NestedPercentageStack } from "./NestedPercentageStack";
import { PercentageStack } from "./PercentageStack";
import { FOOTER_HEIGHT, VisualisationFooter } from "./VisualisationFooter";
import { SnapshotComparisonQuery as SnapshotComparisonQueryType } from "./__generated__/SnapshotComparisonQuery.graphql";
import { VisualisationProps } from "./types";

const SnapshotComparisonQuery = graphql`
  query SnapshotComparisonQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    visualisationFilterOptions: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
      distinctSelect: ["BRAND"]
    ) {
      BRAND
    }
    statementGroups: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      STATEMENT
      STATEMENT_GROUP
    }
    waveDates: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
      distinctSelect: ["WAVE_DATE"]
    ) {
      WAVE_DATE
    }
  }
`;

const snapshotComparisonOptionsSchema = z.object({
  customBrandFilter: z
    .object({
      label: z.string(),
      order: z.array(z.string()),
    })
    .optional(),
  groupOrder: z.array(z.string()).optional(),
});

type ComparisonVariant =
  | "NestedPercentageStack"
  | "GroupedPercentageStack"
  | "PercentageStack"
  | "BrandEdgeTriangle";

type SnapshotComparisonProps = {
  variant: ComparisonVariant;
} & VisualisationProps;

export const SnapshotComparison: React.FC<SnapshotComparisonProps> = (
  props: SnapshotComparisonProps,
) => {
  const { dashBoardFilters, variant, metric, height, options } = props;
  const { selectedClient } = useUser();

  const { ref, width } = useElementSize();

  const parsedOptions = snapshotComparisonOptionsSchema.safeParse(options);
  const filterInputLabel = parsedOptions.success
    ? parsedOptions.data.customBrandFilter?.label
    : "Brand";
  const groupOrder = parsedOptions.success ? parsedOptions.data.groupOrder : [];

  const [dateRange, setDateRange] = useState<ChangeSince>(
    ChangeSince.LAST_MONTH,
  );
  const [selectedFilterLeft, setSelectedFilterLeft] = useState<string | null>(
    null,
  );
  const [selectedFilterRight, setSelectedFilterRight] = useState<string | null>(
    null,
  );
  const [allData, setAllData] = useState<Record<string, CollatedData>>({});
  const [groupDropdownState, setGroupDropdownState] = useState<
    Record<string, boolean>
  >({});

  const queryData = useLazyLoadQuery<SnapshotComparisonQueryType>(
    SnapshotComparisonQuery,
    {
      ...dashBoardFilters,
      metric: metric || dashBoardFilters.metric,
    },
  );

  const dateRangeOptions = useMemo(() => {
    if (!queryData.waveDates) {
      return [];
    }
    const allWaveDates = queryData.waveDates
      .filter((waveDate) => waveDate?.WAVE_DATE)
      .map((waveDate) => new Date(waveDate?.WAVE_DATE));
    const earliestDate = new Date(
      Math.min(...allWaveDates.map((date) => date.getTime())),
    );
    // filter to show only the options that work with the wave dates in the data
    const optionsInWindow = dateToChangeSince(earliestDate);
    return optionsInWindow.filter((rangeOption) => {
      if (changeSinceToMonths[rangeOption] === 0) {
        return true;
      }
      const latestWaveDate = allWaveDates.reduce((acc, date) => {
        return date.getTime() > acc.getTime() ? date : acc;
      }, new Date(0));
      const offsetWave = offsetWaveDate(
        latestWaveDate,
        changeSinceToMonths[rangeOption],
      );
      return allWaveDates.some((waveDate) => {
        return waveDate.getTime() === new Date(offsetWave).getTime();
      });
    });
  }, [queryData.waveDates]);

  const statementsByGroup: Record<string, string[]> = useMemo(() => {
    if (!queryData.statementGroups) {
      return {};
    }
    return queryData.statementGroups.reduce(
      (acc, statementAndGroup) => {
        if (!statementAndGroup || !statementAndGroup.STATEMENT) {
          return acc;
        }
        const statement = statementAndGroup.STATEMENT;
        const statementGroup = statementAndGroup.STATEMENT_GROUP || "Other";
        const existingStatements = acc[statementGroup] || [];
        if (existingStatements.includes(statement)) {
          return acc;
        }
        acc[statementGroup] = [...existingStatements, statement].sort(
          getStatementOrderFn({
            clientConfig: selectedClient?.config,
            metric: metric || dashBoardFilters.metric,
          }),
        );
        return acc;
      },
      {} as Record<string, string[]>,
    );
  }, [
    dashBoardFilters.metric,
    metric,
    queryData.statementGroups,
    selectedClient?.config,
  ]);

  const hasMultipleGroups = useMemo(() => {
    return Object.keys(statementsByGroup).length > 1;
  }, [statementsByGroup]);

  const colourOptions = useMemo(() => {
    return [
      "#FFAAD2",
      "#4BA0CD",
      "#929E97",
      "#BD5E8D",
      "#D34B28",
      "#D8AE96",
      "#E1E145",
      "#87A13D",
      "#CFDBB5",
      "#8280D4",
      "#C2962C",
      "#FFAAD2",
      "#4BA0CD",
      "#929E97",
      "#BD5E8D",
      "#D34B28",
      "#D8AE96",
      "#E1E145",
      "#87A13D",
      "#CFDBB5",
      "#8280D4",
      "#C2962C",
    ];
  }, []);

  const toggleGroupDropdownState = useCallback(
    (group: string) => {
      setGroupDropdownState((prevGroupDropdownState) => {
        const existingState =
          prevGroupDropdownState[group] === undefined
            ? true
            : prevGroupDropdownState[group];
        return { ...prevGroupDropdownState, [group]: !existingState };
      });
    },
    [setGroupDropdownState],
  );

  /**
   * Update the data for a side of the comparison
   *
   * format of dataByGroup:
   * `{
   *  left: leftData,
   *  right: rightData,
   * }`
   * The reason for the object mapping is so that the
   * group component can be updated independently
   * and the data will be overwritten for the correct side
   */
  const updateData = useCallback(
    (side: "left" | "right") => (data: CollatedData) => {
      setAllData((prevData) => {
        return {
          ...prevData,
          [side]: data,
        };
      });
    },
    [],
  );

  const selectOptions = useMemo((): string[] => {
    if (!queryData.visualisationFilterOptions) {
      return [];
    }
    const customBrandFilterOrder = parsedOptions.success
      ? parsedOptions.data.customBrandFilter?.order
      : [];
    const clientConfig = selectedClient?.config;
    const brandOrder = clientConfig ? getBrandOrderForClient(clientConfig) : [];
    // sort the options by the custom order, then the client brand order
    const optionsSortOrder = [...(customBrandFilterOrder || []), ...brandOrder];
    const sortFn = sortByOrderThenAlphabeticalFn(optionsSortOrder);
    return queryData.visualisationFilterOptions
      ?.map((option) => option?.BRAND)
      .filter((value): value is string => value !== null && value !== undefined)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(sortFn);
  }, [
    parsedOptions,
    queryData.visualisationFilterOptions,
    selectedClient?.config,
  ]);

  const leftFilters: FilterComponentProps[] = [
    {
      variant: "dropdown",
      inputLabel: filterInputLabel,
      options: selectOptions.filter((option) => option !== selectedFilterRight),
      value: selectedFilterLeft,
      onSelect: setSelectedFilterLeft,
    },
    ...(dateRangeOptions.length > 0
      ? [
          {
            variant: "dropdown",
            inputLabel: "Change",
            options: dateRangeOptions,
            value: dateRange,
            onSelect: (option) => {
              if (
                option &&
                Object.values(ChangeSince).includes(option as ChangeSince)
              ) {
                setDateRange(option as ChangeSince);
              }
            },
          } as FilterComponentProps,
        ]
      : []),
  ];

  const rightFilters: FilterComponentProps[] = [
    {
      variant: "dropdown",
      inputLabel: filterInputLabel,
      options: selectOptions.filter((option) => option !== selectedFilterLeft),
      value: selectedFilterRight,
      onSelect: setSelectedFilterRight,
      autoSelect: false,
      placeholder: "Select Comparison",
    },
  ];

  useEffect(() => {
    if (!selectedFilterLeft && selectOptions?.length > 0) {
      setSelectedFilterLeft(selectOptions[0]);
    }
    if (!queryData) {
      return;
    }
  }, [queryData, selectOptions, selectedFilterLeft]);

  const hideSecondaryView = useMemo(() => {
    return variant === "BrandEdgeTriangle" && width < 1250;
  }, [variant, width]);

  const genBrandFilterComponent = (options: {
    filters: FilterComponentProps[];
    isSecondary?: boolean;
  }) => {
    const { filters, isSecondary } = options;
    if (hideSecondaryView && isSecondary) {
      return null;
    }
    return (
      <div className="flex flex-row w-full h-[100px]">
        <MultiFilterBar filters={filters} />
      </div>
    );
  };

  const getComponentForVariant = (options: {
    brand: string | null;
    isSecondary?: boolean;
  }) => {
    const { brand, isSecondary } = options;
    if (!brand || (isSecondary && hideSecondaryView)) {
      return (
        <div className="w-full">
          <Divider />
        </div>
      );
    }
    const visualisationType: ComparisonVariant =
      variant === "PercentageStack" && hasMultipleGroups
        ? "GroupedPercentageStack"
        : variant;

    switch (visualisationType) {
      case "BrandEdgeTriangle":
        return (
          <div className="flex flex-col w-full">
            <BrandEdgeTriangle
              brand={brand}
              changeSince={dateRange}
              dashBoardFilters={dashBoardFilters}
              height={height - 100 - FOOTER_HEIGHT}
              updateData={updateData(isSecondary ? "right" : "left")}
            />
          </div>
        );
      case "PercentageStack":
        return (
          <div className="flex flex-col w-full">
            <PercentageStack
              brand={brand}
              changeSince={dateRange}
              colours={colourOptions}
              dashBoardFilters={dashBoardFilters}
              isSecondary={isSecondary}
              allStatements={Object.values(statementsByGroup).flat()}
              updateData={updateData(isSecondary ? "right" : "left")}
            />
          </div>
        );
      case "GroupedPercentageStack":
        return (
          <div className="flex flex-col w-full">
            <GroupedPercentageStack
              height={height - 100 - FOOTER_HEIGHT}
              statementsByGroup={statementsByGroup}
              groupOrder={groupOrder}
              groupDropdownState={groupDropdownState}
              toggleGroupDropdownState={toggleGroupDropdownState}
              brand={brand}
              changeSince={dateRange}
              colours={colourOptions}
              dashBoardFilters={dashBoardFilters}
              isSecondary={isSecondary}
              updateData={updateData(isSecondary ? "right" : "left")}
            />
          </div>
        );
      case "NestedPercentageStack":
        return (
          <div className="flex flex-col w-full">
            <NestedPercentageStack
              height={height - 100 - FOOTER_HEIGHT}
              statementsByGroup={statementsByGroup}
              groupOrder={groupOrder}
              groupDropdownState={groupDropdownState}
              toggleGroupDropdownState={toggleGroupDropdownState}
              brand={brand}
              changeSince={dateRange}
              colours={colourOptions}
              dashBoardFilters={dashBoardFilters}
              isSecondary={isSecondary}
              updateData={updateData(isSecondary ? "right" : "left")}
            />
          </div>
        );
      default:
        assertUnreachable(visualisationType);
    }
  };

  return (
    <div
      ref={ref}
      className="flex flex-col w-full px-5"
      style={{
        height,
      }}
    >
      <div className="flex flex-row w-full h-[100px]">
        {/* Left Side */}
        {selectedFilterLeft &&
          genBrandFilterComponent({
            filters: leftFilters,
          })}
        <Divider orientation={"vertical"} />
        {/* Right Side */}
        {genBrandFilterComponent({
          filters: rightFilters,
          isSecondary: true,
        })}
      </div>
      <div className="flex flex-col w-full h-full overflow-y-auto no-scrollbar">
        <div className="flex w-full h-full">
          {getComponentForVariant({
            brand: selectedFilterLeft,
          })}
          <Divider orientation={"vertical"} />
          {getComponentForVariant({
            brand: selectedFilterRight,
            isSecondary: true,
          })}
        </div>
      </div>
      <VisualisationFooter
        dashboardFilters={dashBoardFilters}
        data={Object.values(allData).flat()}
      />
    </div>
  );
};

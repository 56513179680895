import { Image } from "@mantine/core";

import funnelConversionArrow from "../assets/funnel_conversion_arrow.svg";
import { FontFamily } from "../util/fontFamily";

type FunnelConversionIndicatorProps = {
  readonly percentage: number;
  readonly offset?: number;
};

/**
 * An annotated arrow that indicates the conversion rate of a funnel
 * Designed for the multiple funnel visualisation
 *
 * @param props the percentage and offset of the arrow
 * @returns the funnel conversion indicator component
 */
export const FunnelConversionIndicator: React.FC<
  FunnelConversionIndicatorProps
> = (props: FunnelConversionIndicatorProps) => {
  const { percentage, offset } = props;
  return (
    <div
      className="relative"
      style={{
        marginLeft: offset,
      }}
    >
      <div
        className="absolute flex items-center justify-center w-full h-full top-4 left-40"
        style={{
          top: 16,
          left: 0,
        }}
      >
        <div
          className={
            "flex items-center justify-center h-5 rounded-full text-xs p-2 z-10"
          }
          style={{
            fontFamily: FontFamily.PPNeueMontrealMedium,
            background: "#F0F0F0",
            marginTop: "6px",
            marginLeft: "20px",
          }}
        >
          {`${percentage.toFixed(0)}%`}
        </div>
        <div className="absolute">
          <Image
            src={funnelConversionArrow}
            alt="Conversion Arrow"
            style={{
              marginTop: "30px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

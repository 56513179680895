import { PlatformError } from "@tra-nz/platform-common";
import { fetchAuthSession, signIn } from "aws-amplify/auth";
import { Result, ResultAsync, err, errAsync, ok, okAsync } from "neverthrow";
import { z } from "zod";

import { setApiClientToken } from "./apiClient";

const signInErrorSchema = z.object({
  name: z.string(),
});

type SignInErrorType = "NOT_AUTHORIZED" | "USER_NOT_FOUND" | "UNKNOWN_ERROR";

export const safeSignIn = ResultAsync.fromThrowable(
  signIn,
  (error: unknown): PlatformError<SignInErrorType> => {
    const signInError = signInErrorSchema.safeParse(error);

    if (signInError.success) {
      const { name } = signInError.data;
      switch (name) {
        case "NotAuthorizedException":
          return {
            type: "NOT_AUTHORIZED",
            message: "Invalid credentials",
          };
        case "UserNotFoundException":
          return {
            type: "USER_NOT_FOUND",
            message: "Invalid email",
          };
        default:
          return {
            type: "UNKNOWN_ERROR",
            message: "unexpected authentication error",
          };
      }
    }
    return {
      type: "UNKNOWN_ERROR",
      message: "something went wrong logging in",
    };
  },
);

export type GetAuthSessionErrorType =
  | "FETCH_AUTH_SESSION_ERROR"
  | "TOKEN_NOT_FOUND";

export const getAccessToken = (): ResultAsync<
  string,
  PlatformError<GetAuthSessionErrorType>
> => {
  const safeFetchAuthSession = ResultAsync.fromThrowable(
    fetchAuthSession,
    (error: unknown): PlatformError<GetAuthSessionErrorType> => {
      return {
        type: "FETCH_AUTH_SESSION_ERROR",
        message: (error as Error).message,
      };
    },
  );
  const safeGetAuthSessionResult = safeFetchAuthSession();
  return safeGetAuthSessionResult.andThen((authSession) => {
    const tokens = authSession?.tokens;
    if (!tokens) {
      const error: PlatformError<GetAuthSessionErrorType> = {
        type: "TOKEN_NOT_FOUND",
      };
      return errAsync(error);
    }
    return okAsync(tokens.accessToken.toString());
  });
};

export type LogInUserErrorType =
  | "NOT_AUTHORIZED"
  | "USER_NOT_FOUND"
  | "SYSTEM_ERROR";

export const logInUser = async (options: {
  email: string;
  password: string;
}): Promise<Result<undefined, PlatformError<LogInUserErrorType>>> => {
  const { email, password } = options;
  const cleanedUpEmail = email.trim().toLowerCase();

  const signInResult = await safeSignIn({
    username: cleanedUpEmail,
    password,
  });

  if (signInResult.isErr()) {
    switch (signInResult.error.type) {
      case "NOT_AUTHORIZED":
        return err({
          type: "NOT_AUTHORIZED",
          message: "Invalid credentials",
        });
      case "USER_NOT_FOUND":
        return err({
          type: "USER_NOT_FOUND",
          message: "Invalid email",
        });
      default:
        return err({
          type: "SYSTEM_ERROR",
          message: "unexpected authentication error",
        });
    }
  }

  const { isSignedIn } = signInResult.value;
  if (isSignedIn === false) {
    return err({
      type: "SYSTEM_ERROR",
      message: "unexpected token retrieval error",
    });
  }

  const fetchedAccessToken = await getAccessToken();
  if (fetchedAccessToken.isErr()) {
    return err({
      type: "SYSTEM_ERROR",
      message: "unexpected token retrieval error",
    });
  }

  setApiClientToken(fetchedAccessToken.value);
  return ok(undefined);
};

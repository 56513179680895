/**
 * @generated SignedSource<<9df0aa0bbe65dfb2bec4a2b74a041e95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StandardDashboardTabQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
};
export type StandardDashboardTabQuery$data = {
  readonly dashboardFilterOptions: ReadonlyArray<{
    readonly AUDIENCE1: string | null | undefined;
    readonly AUDIENCE2: string | null | undefined;
    readonly AUDIENCE_GROUP1: string | null | undefined;
    readonly AUDIENCE_GROUP2: string | null | undefined;
    readonly CATEGORY: string | null | undefined;
    readonly ROLL: number | null | undefined;
  } | null | undefined> | null | undefined;
  readonly downloadData: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type StandardDashboardTabQuery = {
  response: StandardDashboardTabQuery$data;
  variables: StandardDashboardTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v6 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v7 = {
  "kind": "Variable",
  "name": "BRAND_METRIC",
  "variableName": "metric"
},
v8 = [
  {
    "alias": "dashboardFilterOptions",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "AUDIENCE1",
          "AUDIENCE_GROUP1",
          "AUDIENCE2",
          "AUDIENCE_GROUP2",
          "CATEGORY",
          "ROLL"
        ]
      },
      {
        "fields": [
          (v7/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CATEGORY",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ROLL",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "downloadData",
    "args": [
      (v6/*: any*/),
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "firstAudience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondAudience"
          },
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "CATEGORY",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "roll"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StandardDashboardTabQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "StandardDashboardTabQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "b105159df95f80167dd40fe7f2cd455a",
    "id": null,
    "metadata": {},
    "name": "StandardDashboardTabQuery",
    "operationKind": "query",
    "text": "query StandardDashboardTabQuery(\n  $clientId: String!\n  $metric: String!\n  $firstAudience: String\n  $secondAudience: String\n  $category: String\n  $roll: Int\n) {\n  dashboardFilterOptions: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric}, distinctSelect: [\"AUDIENCE1\", \"AUDIENCE_GROUP1\", \"AUDIENCE2\", \"AUDIENCE_GROUP2\", \"CATEGORY\", \"ROLL\"]) {\n    AUDIENCE1\n    AUDIENCE_GROUP1\n    AUDIENCE2\n    AUDIENCE_GROUP2\n    CATEGORY\n    ROLL\n  }\n  downloadData: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}) {\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "4729e8d438702c3cc504469e46bcf152";

export default node;

import { Text } from "@mantine/core";

import { CampaignSummary } from "../../util/creativeEdge";
import { FontFamily } from "../../util/fontFamily";
import { SummaryScore } from "./SummaryScore";
import { TableRow } from "./TableRow";
import { VideoPreviewImageTile } from "./VideoPreviewImageTile";

type CampaignSummaryListItemProps = {
  campaign: CampaignSummary;
  onClick?: () => void;
};

/**
 * A row component for Populating the campaign table row
 *
 * @param options the options to generate the table row
 * @returns the campaign table row component
 */
export const CampaignSummaryListItem: React.FC<CampaignSummaryListItemProps> = (
  props: CampaignSummaryListItemProps,
) => {
  const { campaign, onClick } = props;
  const {
    trackerProjectId,
    campaignId,
    title,
    score,
    remarkable,
    rewarding,
    remembered,
  } = campaign;

  return (
    <TableRow onClick={onClick}>
      <div className="p-2 pl-0">
        <VideoPreviewImageTile
          key={campaignId}
          trackerProjectId={trackerProjectId}
          campaignId={campaignId}
        />
      </div>
      <Text
        key={2}
        lineClamp={1}
        ff={FontFamily.PPNeueMontrealBook}
        size={"32px"}
        pr={"xs"}
      >
        {title}
      </Text>
      <SummaryScore key={3} score={score} withCeTriangle />
      <SummaryScore key={4} score={remarkable} />
      <SummaryScore key={5} score={rewarding} />
      <SummaryScore key={6} score={remembered} />
    </TableRow>
  );
};

import { MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { useEffect, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";

import { createRelayEnvironment } from "./RelayEnvironment";
import { configureAmplify } from "./amplify-plugin";
import { UserContextProvider } from "./context/UserContext";
import { RootRouter } from "./route/RootRouter";
import { FontFamily } from "./util/fontFamily";

export const theme = createTheme({
  fontFamily: FontFamily.PPNeueMontrealRegular,
});

const App: React.FC = () => {
  configureAmplify();

  const [RelayEnvironment, setRelayEnvironment] = useState(
    createRelayEnvironment(),
  );

  const refreshRelayEnvironment = () => {
    setRelayEnvironment(createRelayEnvironment());
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
        <Notifications position="bottom-right" autoClose={10000} />
        <UserContextProvider onUserUpdate={refreshRelayEnvironment}>
          <RelayEnvironmentProvider environment={RelayEnvironment}>
            <RootRouter />
          </RelayEnvironmentProvider>
        </UserContextProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;

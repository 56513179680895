/* eslint-disable sonarjs/no-duplicate-string */
import { Divider, Flex, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { Suspense, useCallback, useEffect, useState } from "react";

import { AiSummary } from "../component/AiSummary";
import { DashboardTabHeader } from "../component/DashboardTabHeader";
import { Legend, LegendSeries } from "../component/Legend";
import { NAVBAR_HEIGHT, Navbar } from "../component/Navbar/Navbar";
import { SmallLoader } from "../component/SmallLoader";
import { KpiSummary } from "../component/Visualisation/KpiSummary";
import { useUser } from "../context/UserContext";
import { FontFamily } from "../util/fontFamily";

/**
 * The Home tab of the dashboard
 *
 * @returns the dashboard home component
 */
export const DashboardHome: React.FC = () => {
  const { selectedClient } = useUser();
  const { height, width } = useViewportSize();

  const kpiSummary = selectedClient?.config?.execSummary?.kpiSummary;

  const [showAiSummary, setShowAiSummary] = useState<boolean>(false);
  const [legendState, setLegendState] = useState<ReadonlyArray<LegendSeries>>(
    [],
  );

  useEffect(() => {
    if (kpiSummary) {
      setLegendState(
        kpiSummary.map((config) => ({
          name: config.label,
          enabled: true,
        })),
      );
    }
  }, [kpiSummary]);

  const onToggleSummary = useCallback(
    (label: string) => {
      const updatedLegendState = legendState.map((series) => {
        if (series.name === label) {
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      setLegendState(updatedLegendState);
    },
    [legendState],
  );

  return (
    <div className="flex flex-col w-screen h-screen overflow-hidden">
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: height - NAVBAR_HEIGHT,
        }}
      >
        <div
          className="flex flex-col w-full overflow-x-hidden overflow-y-auto"
          style={{
            height: height - NAVBAR_HEIGHT,
          }}
        >
          <DashboardTabHeader
            title={"Home"}
            showAiSummary={() => setShowAiSummary(true)}
          />
          <Divider />
          {kpiSummary && (
            <div className="p-5">
              <div className="flex flex-row gap-6">
                <Text
                  ff={FontFamily.PPNeueMontrealMedium}
                  size={"25px"}
                  pb={"24px"}
                >
                  KPIs
                </Text>
                {legendState.length > 1 && (
                  <Legend
                    series={legendState}
                    onToggleSeries={onToggleSummary}
                    justify={"flex-start"}
                  />
                )}
              </div>
              <Flex
                gap={"md"}
                justify={"flex-start"}
                align={"flex-start"}
                wrap={"wrap"}
              >
                {kpiSummary?.map((kpiConfig, index) => {
                  if (
                    legendState.find(
                      (series) => series.name === kpiConfig.label,
                    )?.enabled
                  ) {
                    return <KpiSummary key={index} config={kpiConfig} />;
                  }
                })}
              </Flex>
              <Divider />
            </div>
          )}
        </div>
        <div
          style={{
            minWidth: width / 3,
            maxWidth: width / 3,
            display: showAiSummary ? undefined : "none",
          }}
          className="flex flex-row h-full"
        >
          <Divider orientation={"vertical"} />
          <Suspense fallback={<SmallLoader />}>
            <AiSummary
              bodyText={selectedClient?.config?.execSummary?.aiSummaryText}
              dashBoardFilters={{
                clientId: selectedClient?.id || "none",
                category: "exec",
                firstAudience: "total",
                secondAudience: null,
                metric: "exec",
                roll: 3,
              }}
              onClose={() => setShowAiSummary(false)}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

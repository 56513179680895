import { ClientConfig } from "@tra-nz/platform-common";

import { NullableStringSortFn, sortByOrderThenAlphabeticalFn } from "./sort";

/**
 * Get the statement order for a metric for a particular client
 *
 * @param options the client config and metric to get the statement order for
 * @returns the statement order for the metric for the client
 */
export const getStatementsOrderForClient = (options: {
  clientConfig?: ClientConfig;
  metric: string;
}): string[] => {
  const { clientConfig, metric } = options;
  const statementOrderConfig = clientConfig?.statementOrder.find(
    (config) => config.metric === metric,
  );
  const statementOrder = statementOrderConfig?.order;
  if (!statementOrder) {
    return [];
  }
  return statementOrder;
};

/**
 * Get a function to sort statements for a metric for a particular client
 *
 * @param options the client config and metric to get the statement order for
 * @returns a function to sort statements for the metric for the client
 */
export const getStatementOrderFn = (options: {
  clientConfig?: ClientConfig;
  metric: string;
}): NullableStringSortFn => {
  const statementOrder = getStatementsOrderForClient(options);
  return sortByOrderThenAlphabeticalFn(statementOrder);
};

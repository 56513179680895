/**
 * @generated SignedSource<<0365ebfcfeb0cd21a0a8d6589a2a0c05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AiSummaryQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
};
export type AiSummaryQuery$data = {
  readonly aiSummary: ReadonlyArray<{
    readonly GPT_COMMENTARY: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type AiSummaryQuery = {
  response: AiSummaryQuery$data;
  variables: AiSummaryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "firstAudience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondAudience"
          },
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          },
          {
            "kind": "Variable",
            "name": "CATEGORY",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "roll"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "AiSummary",
    "kind": "LinkedField",
    "name": "aiSummary",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "GPT_COMMENTARY",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AiSummaryQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "AiSummaryQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "a357c6a058d4ee64df9a51d89015c95d",
    "id": null,
    "metadata": {},
    "name": "AiSummaryQuery",
    "operationKind": "query",
    "text": "query AiSummaryQuery(\n  $clientId: String!\n  $firstAudience: String\n  $secondAudience: String\n  $metric: String!\n  $category: String\n  $roll: Int\n) {\n  aiSummary(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}) {\n    GPT_COMMENTARY\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c36038c7fa00e74bcc1ae25bd43134a";

export default node;

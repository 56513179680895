/**
 * @generated SignedSource<<ec035e439d7385d32da04c731c6ec8b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CodedUnpromptedMessagesBreakdownQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
  sentimentMetric?: string | null | undefined;
  statementGroup?: string | null | undefined;
  subject?: string | null | undefined;
  topicMetric: string;
};
export type CodedUnpromptedMessagesBreakdownQuery$data = {
  readonly proportion: ReadonlyArray<{
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly sentiment: ReadonlyArray<{
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CodedUnpromptedMessagesBreakdownQuery = {
  response: CodedUnpromptedMessagesBreakdownQuery$data;
  variables: CodedUnpromptedMessagesBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sentimentMetric"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statementGroup"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicMetric"
},
v9 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v10 = {
  "kind": "Variable",
  "name": "AUDIENCE1",
  "variableName": "firstAudience"
},
v11 = {
  "kind": "Variable",
  "name": "AUDIENCE2",
  "variableName": "secondAudience"
},
v12 = {
  "kind": "Variable",
  "name": "BRAND",
  "variableName": "subject"
},
v13 = {
  "kind": "Variable",
  "name": "CATEGORY",
  "variableName": "category"
},
v14 = {
  "kind": "Variable",
  "name": "ROLL",
  "variableName": "roll"
},
v15 = {
  "kind": "Variable",
  "name": "STATEMENT_GROUP",
  "variableName": "statementGroup"
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "STATEMENT",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "IS_SCORE",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "PERCENTAGE",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "WAVE_DATE",
    "storageKey": null
  }
],
v17 = [
  {
    "alias": "proportion",
    "args": [
      (v9/*: any*/),
      {
        "fields": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "topicMetric"
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": (v16/*: any*/),
    "storageKey": null
  },
  {
    "alias": "sentiment",
    "args": [
      (v9/*: any*/),
      {
        "fields": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "sentimentMetric"
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": (v16/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CodedUnpromptedMessagesBreakdownQuery",
    "selections": (v17/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "CodedUnpromptedMessagesBreakdownQuery",
    "selections": (v17/*: any*/)
  },
  "params": {
    "cacheID": "af4bd003bcf415963e74d70a5a4a7c74",
    "id": null,
    "metadata": {},
    "name": "CodedUnpromptedMessagesBreakdownQuery",
    "operationKind": "query",
    "text": "query CodedUnpromptedMessagesBreakdownQuery(\n  $clientId: String!\n  $roll: Int\n  $topicMetric: String!\n  $sentimentMetric: String\n  $category: String\n  $firstAudience: String\n  $secondAudience: String\n  $subject: String\n  $statementGroup: String\n) {\n  proportion: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $topicMetric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $subject, STATEMENT_GROUP: $statementGroup}) {\n    STATEMENT\n    IS_SCORE\n    PERCENTAGE\n    WAVE_DATE\n  }\n  sentiment: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $sentimentMetric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $subject, STATEMENT_GROUP: $statementGroup}) {\n    STATEMENT\n    IS_SCORE\n    PERCENTAGE\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "f904368d03b129e49b3a3c520680f6b4";

export default node;

/**
 * @generated SignedSource<<05e5a4c76aa1f52e4a692057c0a4b30d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPillarBreakdownQuery$variables = {
  audience: string;
  campaignId: number;
  clientId: string;
  metric: string;
  pillar: string;
  secondaryAudience?: string | null | undefined;
};
export type CampaignPillarBreakdownQuery$data = {
  readonly campaign: ReadonlyArray<{
    readonly PERCENTAGE: number | null | undefined;
    readonly QUESTION_TEXT: string | null | undefined;
    readonly SORT_ORDER: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CampaignPillarBreakdownQuery = {
  response: CampaignPillarBreakdownQuery$data;
  variables: CampaignPillarBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audience"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pillar"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondaryAudience"
},
v6 = [
  {
    "alias": "campaign",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "audience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondaryAudience"
          },
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          },
          {
            "kind": "Literal",
            "name": "IS_SCORE",
            "value": 0
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "campaignId"
          },
          {
            "kind": "Variable",
            "name": "STATEMENT_GROUP",
            "variableName": "pillar"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "QUESTION_TEXT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SORT_ORDER",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignPillarBreakdownQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignPillarBreakdownQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "c6c323e6d2cc1bd94d3c2d4f9d1de031",
    "id": null,
    "metadata": {},
    "name": "CampaignPillarBreakdownQuery",
    "operationKind": "query",
    "text": "query CampaignPillarBreakdownQuery(\n  $clientId: String!\n  $campaignId: Int!\n  $pillar: String!\n  $metric: String!\n  $audience: String!\n  $secondaryAudience: String\n) {\n  campaign: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, ROLL: $campaignId, STATEMENT_GROUP: $pillar, AUDIENCE1: $audience, AUDIENCE2: $secondaryAudience, IS_SCORE: 0}) {\n    QUESTION_TEXT\n    STATEMENT\n    PERCENTAGE\n    SORT_ORDER\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5d4cf02eb94d3ceda1a1911e2a6df4b";

export default node;

import { Tabs } from "@mantine/core";
import { useHash, useViewportSize } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  CreativeEdgeTab,
  creativeEdgeTabSchema,
} from "@tra-nz/platform-common";
import graphql from "babel-plugin-relay/macro";
import { Suspense, useCallback, useMemo, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { CompareCampaignsSideBySide } from "../component/CreativeEdge/CompareCampaignsSideBySide";
import {
  CreativeEdgeFilterBar,
  CreativeEdgeFilters,
} from "../component/CreativeEdge/CreativeEdgeFilterBar";
import { CreativeEdgeSnapshot } from "../component/CreativeEdge/CreativeEdgeSnapshot";
import { CreativeEdgeUnpromptedMessages } from "../component/CreativeEdge/CreativeEdgeUnpromptedMessages";
import {
  DASHBOARD_TAB_HEADER_HEIGHT,
  DashboardTabHeader,
} from "../component/DashboardTabHeader";
import { LoadingSpinner } from "../component/LoadingSpinner";
import { NAVBAR_HEIGHT, Navbar } from "../component/Navbar/Navbar";
import { useUser } from "../context/UserContext";
import { assertUnreachable } from "../util/assertUnreachable";
import {
  CampaignSummary,
  CreativeEdgeMetrics,
  downloadAllCampaigns,
  getCampaignSummaries,
} from "../util/creativeEdge";
import {
  copyPngToClipboard,
  downloadPng,
  getHeightIncreaseToMaxScrolls,
} from "../util/download";
import { CampaignBreakdownTabQuery as CampaignBreakdownTabQueryType } from "./__generated__/CampaignBreakdownTabQuery.graphql";

enum CampaignBreakdownTabEnum {
  Snapshot = "Snapshot",
  Compare = "Compare",
  Message = "Message",
}

const campaignBreakdownTabParamsSchema = z.object({
  clientId: z.string(),
  tabTitle: z.string(),
  campaignId: z.string(),
});

const CampaignBreakdownTabQuery = graphql`
  query CampaignBreakdownTabQuery(
    $clientId: String!
    $metric: String!
    $audience: String!
    $secondaryAudience: String
  ) {
    campaigns: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
      }
    ) {
      PROJECT_ID
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
      ROLL
      QUESTION_TEXT
    }
    newFormat: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: "campaign - sentiment" }
      distinctSelect: ["BRAND_METRIC"]
    ) {
      BRAND_METRIC
    }
  }
`;

/**
 * The Campaign Breakdown of a specific campaign
 *
 * @returns the campaign breakdown tab component
 */
export const CampaignBreakdownTab: React.FC = () => {
  const { selectedClient } = useUser();
  const params = useParams();
  const ref = useRef(null);
  const [screenshotHeightPadding, setScreenshotHeightPadding] = useState(0);
  const { height } = useViewportSize();
  const [hash, setHash] = useHash();
  const [audienceFilters, setAudienceFilters] = useState<CreativeEdgeFilters>({
    audience: "Total",
    secondaryAudience: "Total",
  });

  const { clientId, tabTitle, campaignId } =
    campaignBreakdownTabParamsSchema.parse(params);

  const queryData = useLazyLoadQuery<CampaignBreakdownTabQueryType>(
    CampaignBreakdownTabQuery,
    {
      clientId,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...audienceFilters,
    },
  );

  const useLegacyCommentView = useMemo(() => {
    return queryData.newFormat?.length === 0;
  }, [queryData.newFormat]);

  const getCurrentSelectedTab = () => {
    const hashValue = hash.replace("#", "");
    return hashValue || CampaignBreakdownTabEnum.Snapshot;
  };

  const parsedCampaignId = parseInt(campaignId, 10);

  const panelHeight =
    height -
    NAVBAR_HEIGHT -
    DASHBOARD_TAB_HEADER_HEIGHT -
    40 +
    screenshotHeightPadding;

  const tabItemConfig: CreativeEdgeTab | undefined = useMemo(() => {
    const tabConfig = selectedClient?.config?.tabs?.find(
      (tab) => tab.title.toLowerCase() === tabTitle.toLowerCase(),
    );
    const foundTab = tabConfig?.items.find(
      (item) => item.type === "creativeEdge",
    );
    const parsedTab = creativeEdgeTabSchema.safeParse(foundTab);
    return parsedTab.success ? parsedTab.data : undefined;
  }, [selectedClient, tabTitle]);

  const campaigns = useMemo<ReadonlyArray<CampaignSummary>>(() => {
    const { campaigns: rawCampaignData } = queryData;
    return getCampaignSummaries(rawCampaignData);
  }, [queryData]);

  const _downloadImage = useCallback(
    async (saveTo: "download" | "clipboard") => {
      const component = ref.current;
      if (!component) {
        return;
      }
      try {
        const heightIncrease = getHeightIncreaseToMaxScrolls(component);
        setScreenshotHeightPadding(heightIncrease + 5);
        // wait for the height to update
        await new Promise((resolve) => setTimeout(resolve, 100));
        switch (saveTo) {
          case "download":
            await downloadPng(component, "chartTitle");
            break;
          case "clipboard":
            await copyPngToClipboard(component);
            break;
          default:
            assertUnreachable(saveTo);
        }
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to download image",
          color: "red",
        });
      } finally {
        setScreenshotHeightPadding(0);
      }
    },
    [],
  );

  return (
    <div className="flex flex-col w-full">
      <Navbar />
      <DashboardTabHeader
        title={"Creative Edge"}
        goBack
        filterComponent={
          getCurrentSelectedTab() !== CampaignBreakdownTabEnum.Message && (
            <CreativeEdgeFilterBar
              clientId={clientId}
              campaignId={parsedCampaignId}
              onFilterChange={setAudienceFilters}
            />
          )
        }
        downloadOptions={[
          {
            label: "Download CSV",
            onClick: () =>
              downloadAllCampaigns({
                format: "csv",
                campaigns,
                audienceFilters: audienceFilters,
              }),
          },
          {
            label: "Download XLSX",
            onClick: () =>
              downloadAllCampaigns({
                format: "xlsx",
                campaigns,
                audienceFilters: audienceFilters,
              }),
          },
          // {
          //   label: "Copy Chart to Clipboard",
          //   onClick: () => downloadImage("clipboard"),
          // },
          // {
          //   label: "Download Chart",
          //   onClick: () => downloadImage("download"),
          // },
        ]}
      />
      <Tabs
        key={`${campaignId}-${hash}`}
        h={`${height - NAVBAR_HEIGHT - DASHBOARD_TAB_HEADER_HEIGHT + screenshotHeightPadding}px`}
        w={"100%"}
        value={getCurrentSelectedTab()}
        onChange={(value) => value && setHash(value)}
        keepMounted={false}
        color="black"
      >
        <Tabs.List>
          <Tabs.Tab value={CampaignBreakdownTabEnum.Snapshot}>
            {CampaignBreakdownTabEnum.Snapshot}
          </Tabs.Tab>
          <Tabs.Tab value={CampaignBreakdownTabEnum.Compare}>
            {CampaignBreakdownTabEnum.Compare}
          </Tabs.Tab>
          <Tabs.Tab value={CampaignBreakdownTabEnum.Message}>
            {CampaignBreakdownTabEnum.Message}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          value={CampaignBreakdownTabEnum.Snapshot}
          ref={
            getCurrentSelectedTab() === CampaignBreakdownTabEnum.Snapshot
              ? ref
              : undefined
          }
        >
          <Suspense fallback={<LoadingSpinner />}>
            <CreativeEdgeSnapshot
              audienceFilters={audienceFilters}
              campaignId={parsedCampaignId}
              clientId={clientId}
              tabItemConfig={tabItemConfig}
              height={panelHeight}
            />
          </Suspense>
        </Tabs.Panel>
        <Tabs.Panel
          value={CampaignBreakdownTabEnum.Compare}
          ref={
            getCurrentSelectedTab() === CampaignBreakdownTabEnum.Compare
              ? ref
              : undefined
          }
        >
          <Suspense fallback={<LoadingSpinner />}>
            <CompareCampaignsSideBySide
              audienceFilters={audienceFilters}
              campaignId={parsedCampaignId}
              clientId={clientId}
              height={panelHeight}
            />
          </Suspense>
        </Tabs.Panel>
        <Tabs.Panel
          value={CampaignBreakdownTabEnum.Message}
          ref={
            getCurrentSelectedTab() === CampaignBreakdownTabEnum.Message
              ? ref
              : undefined
          }
        >
          <Suspense fallback={<LoadingSpinner />}>
            <CreativeEdgeUnpromptedMessages
              campaignId={parsedCampaignId}
              clientId={clientId}
              height={panelHeight}
              audienceFilters={audienceFilters}
              useLegacyCommentView={useLegacyCommentView}
            />
          </Suspense>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

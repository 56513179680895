/**
 * @generated SignedSource<<7885390bf8d2f9d32ea2c0f3f040c648>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StandardDashboardFilterBarQuery$variables = {
  clientId: string;
  metric: string;
};
export type StandardDashboardFilterBarQuery$data = {
  readonly dashboardFilterOptions: ReadonlyArray<{
    readonly AUDIENCE1: string | null | undefined;
    readonly AUDIENCE2: string | null | undefined;
    readonly AUDIENCE_GROUP1: string | null | undefined;
    readonly AUDIENCE_GROUP2: string | null | undefined;
    readonly CATEGORY: string | null | undefined;
    readonly ROLL: number | null | undefined;
  } | null | undefined> | null | undefined;
};
export type StandardDashboardFilterBarQuery = {
  response: StandardDashboardFilterBarQuery$data;
  variables: StandardDashboardFilterBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "metric"
  }
],
v1 = [
  {
    "alias": "dashboardFilterOptions",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "AUDIENCE1",
          "AUDIENCE_GROUP1",
          "AUDIENCE2",
          "AUDIENCE_GROUP2",
          "CATEGORY",
          "ROLL"
        ]
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CATEGORY",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ROLL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StandardDashboardFilterBarQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StandardDashboardFilterBarQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6f4281398f81baa8a4cc13449f088da8",
    "id": null,
    "metadata": {},
    "name": "StandardDashboardFilterBarQuery",
    "operationKind": "query",
    "text": "query StandardDashboardFilterBarQuery(\n  $clientId: String!\n  $metric: String!\n) {\n  dashboardFilterOptions: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric}, distinctSelect: [\"AUDIENCE1\", \"AUDIENCE_GROUP1\", \"AUDIENCE2\", \"AUDIENCE_GROUP2\", \"CATEGORY\", \"ROLL\"]) {\n    AUDIENCE1\n    AUDIENCE_GROUP1\n    AUDIENCE2\n    AUDIENCE_GROUP2\n    CATEGORY\n    ROLL\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8b23877b983fac48bf41dbd65123738";

export default node;

import { HoverCard, Text } from "@mantine/core";
import { ComputedDatum } from "@nivo/circle-packing";

import { FontFamily } from "../util/fontFamily";

type CirclePackCircleLabelProps = {
  label: string | number;
  node: ComputedDatum<unknown>;
  readonly zoomState?: {
    readonly zoomedId?: string;
    readonly setZoomedId: (id: string | undefined) => void;
  };
};

/**
 * A label component for the CirclePack Chart Component.
 * Displays the percentage and label of the circle.
 *
 * @param props The props for the component.
 * @returns The CirclePackCircleLabel component.
 */
export const CirclePackCircleLabel: React.FC<CirclePackCircleLabelProps> = (
  props: CirclePackCircleLabelProps,
) => {
  const { label, node, zoomState } = props;

  const xPos = node.x - node.radius;
  const yPos = node.y - node.radius;
  const circleDiameter = node.radius * 2;
  const percentageTextSize = `${node.radius / 2}px`;
  const labelLength = label.toString().length;
  const labelScaleLimit = labelLength > 40 ? 100 : 40;
  const labelRadiusScaleRatio = labelLength > 20 ? 7 : 4;
  const labelTextSize =
    node.radius > labelScaleLimit ? 18 : node.radius / labelRadiusScaleRatio;
  const labelTextFontSize = `${labelTextSize}px`;

  return (
    <foreignObject
      x={xPos}
      y={yPos}
      width={circleDiameter}
      height={circleDiameter}
      style={{}}
    >
      <HoverCard
        shadow={"md"}
        openDelay={100}
        disabled={labelTextSize > 16}
        withArrow
        arrowSize={20}
      >
        <HoverCard.Target>
          <div
            className="flex flex-col items-center justify-center w-full h-full rounded-full hover:cursor-pointer"
            style={{
              cursor: zoomState === undefined ? "default" : "pointer",
            }}
            onClick={() => {
              zoomState?.setZoomedId(
                zoomState?.zoomedId === label ? undefined : label.toString(),
              );
            }}
          >
            <Text ff={FontFamily.PPNeueMontrealBold} size={percentageTextSize}>
              {node.value}%
            </Text>
            <Text
              ff={FontFamily.PPNeueMontrealMedium}
              size={labelTextFontSize}
              pt={"xs"}
              ta={"center"}
            >
              {label}
            </Text>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2 pb-3">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: node.color }}
              />
              <Text
                ff={FontFamily.PPNeueMontrealBold}
                size={"18px"}
              >{`${node.value}%`}</Text>
            </div>
            <Text size={"18px"} maw={200}>
              {label}
            </Text>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </foreignObject>
  );
};

import { ActionIcon, Menu, MenuItem, Text, rem } from "@mantine/core";
import { PiShootingStarFill } from "react-icons/pi";
import { RiDownloadLine } from "react-icons/ri";
import { RxCaretLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import { FontFamily } from "../util/fontFamily";

export const DASHBOARD_TAB_HEADER_HEIGHT = 90;

type DownloadOption = {
  label: string;
  onClick: () => void;
};

type DashboardTabHeaderProps = {
  readonly title: string;
  readonly showAiSummary?: () => void;
  readonly filterComponent?: React.ReactNode;
  readonly downloadDisabled?: boolean;
  readonly downloadOptions?: DownloadOption[];
  readonly goBack?: boolean;
};

/**
 * The header for all the dashboard tabs
 * Configurable with the filters, buttons and labels required
 *
 * @param props the header properties
 * @returns the dashboard tab header component
 */
export const DashboardTabHeader: React.FC<DashboardTabHeaderProps> = (
  props: DashboardTabHeaderProps,
) => {
  const {
    title,
    showAiSummary,
    downloadOptions,
    filterComponent,
    downloadDisabled,
    goBack,
  } = props;

  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center justify-between w-full"
      style={{
        minHeight: DASHBOARD_TAB_HEADER_HEIGHT,
        maxHeight: DASHBOARD_TAB_HEADER_HEIGHT,
      }}
    >
      <div className="flex flex-row items-center">
        {goBack && (
          <ActionIcon
            className="ml-4"
            color={"#F0F0F0"}
            size={22}
            onClick={() =>
              navigate("../", {
                relative: "path",
              })
            }
            radius={"100%"}
          >
            <RxCaretLeft
              style={{
                color: "#6F6F6F",
                width: rem(22),
                height: rem(22),
              }}
            />
          </ActionIcon>
        )}
        <Text
          className="mx-4"
          ff={FontFamily.PPNeueMontrealMedium}
          size={"30px"}
        >
          {title}
        </Text>
      </div>
      <div className="flex flex-row items-end">
        {filterComponent}
        {downloadOptions && (
          <Menu offset={5}>
            <Menu.Target>
              <ActionIcon
                mb={"1rem"}
                mr={"0.75rem"}
                color={"#f0f0f0"}
                size={36}
                disabled={downloadDisabled}
                opacity={downloadDisabled ? 0.5 : 1}
              >
                <RiDownloadLine
                  style={{
                    color: "black",
                    width: rem(22),
                    height: rem(22),
                  }}
                />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {downloadOptions.map((option) => (
                <MenuItem key={option.label} onClick={option.onClick}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu.Dropdown>
          </Menu>
        )}
        {showAiSummary && (
          <ActionIcon
            mb={"1rem"}
            mr={"0.75rem"}
            color={"black"}
            size={36}
            onClick={showAiSummary}
          >
            <PiShootingStarFill style={{ width: rem(24), height: rem(24) }} />
          </ActionIcon>
        )}
      </div>
    </div>
  );
};

import { useElementSize } from "@mantine/hooks";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { SmallLoader } from "./SmallLoader";

type BaseChartProps = {
  type: ApexChart["type"];
  series: ApexAxisChartSeries;
  height: string;
  disabledSeries?: string[];
  dashboardFilters?: Record<string, string>;
  options?: ApexCharts.ApexOptions;
};

/**
 * BaseChart component that displays an ApexChart with the given series
 * @param props Options containing the series to be displayed and other chart config
 * @returns A chart component with the given data
 */
export const GraphChart: React.FC<BaseChartProps> = (props) => {
  const { type, series, height, disabledSeries, options = {} } = props;

  const { width: foundWidth, height: foundHeight, ref } = useElementSize();

  useEffect(() => {
    if (disabledSeries && options.chart?.id) {
      const chart = ApexCharts.getChartByID(options.chart.id);
      series.forEach((seriesData) => {
        const seriesName = seriesData.name || "";
        if (disabledSeries.includes(seriesName)) {
          chart?.hideSeries(seriesName);
        } else {
          chart?.showSeries(seriesName);
        }
      });
    }
  }, [disabledSeries, options.chart, series]);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height,
      }}
    >
      {series.length > 0 && foundHeight && foundWidth ? (
        <div style={{ position: "relative" }}>
          <ReactApexChart
            height={foundHeight}
            width={foundWidth}
            options={options}
            series={series}
            type={type}
          />
        </div>
      ) : (
        <SmallLoader />
      )}
    </div>
  );
};

import { Text } from "@mantine/core";

import { FontFamily } from "../../util/fontFamily";

type CampaignListTableHeaderLabelProps = {
  readonly label: string;
};

/**
 * Campaign List Table Header Label
 *
 * @param props the label to display
 * @returns the header label component
 */
export const CampaignListTableHeaderLabel: React.FC<
  CampaignListTableHeaderLabelProps
> = (props: CampaignListTableHeaderLabelProps) => {
  const { label } = props;

  return (
    <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"} pr={"xs"}>
      {label}
    </Text>
  );
};

import { FilterDropdownProps } from "../component/FilterDropdown";

type FilterCombination = Record<string, string | number | null | undefined>;

export const getValidFilterCombinationsForFilter = <
  T extends FilterCombination,
  K extends keyof T,
>(
  filterOptions: ReadonlyArray<T>,
  currentFilters: Partial<T>,
  filter: K,
): ReadonlyArray<T> => {
  return filterOptions.filter((combination) => {
    return Object.entries(currentFilters).every(([key, value]) => {
      // if the key is the filter we are looking for, return true
      if (key === filter) {
        return true;
      }
      // ignore if the current filter is undefined or null
      const currentFilterIsUndefinedOrNull =
        value === undefined || value === null;
      return currentFilterIsUndefinedOrNull || combination[key] === value;
    });
  });
};

/**
 *  Get valid filter options for a given filter based on the current filters
 *
 *  will only return unique values
 *  will only return values that are not null or undefined
 *
 * @param filterOptions All filter options
 * @param currentFilters Current filters excluding the filter we want to get options for
 * @param filter The filter we want to get options for
 * @returns
 */
export const getValidFilterValues = <
  T extends FilterCombination,
  K extends keyof T,
>(
  filterOptions: ReadonlyArray<T>,
  currentFilters: Partial<T>,
  filter: K,
): ReadonlyArray<NonNullable<T[K]>> => {
  const currentValidFilterCombinations = getValidFilterCombinationsForFilter(
    filterOptions,
    currentFilters,
    filter,
  );
  // get all possible options for the filter requested
  const allPossibleOptions = currentValidFilterCombinations.map(
    (combination) => {
      return combination[filter];
    },
  );
  // filter out all null or undefined options
  const allValidOptions = allPossibleOptions.filter(
    (option): option is NonNullable<T[K]> =>
      option !== null && option !== undefined,
  );
  // return only unique options
  return Array.from(new Set(allValidOptions));
};

/**
 * Get filter dropdown options for a given filter
 * Filters formatted for the FilterDropdown component
 *
 * @param options The options for the filter dropdown
 * @returns The filter dropdown options
 */
export const getFilterDropdownOptions = <
  T extends FilterCombination,
  K extends keyof T,
  G extends keyof T,
>(options: {
  filterOptions: ReadonlyArray<T>;
  currentFilters: Partial<T>;
  filter: K;
  filterSortFn?: (a: T[K], b: T[K]) => number;
  groupBy?: G;
  groupSortFn?: (a: T[G], b: T[G]) => number;
}): FilterDropdownProps["options"] => {
  const {
    filterOptions,
    currentFilters,
    filter,
    filterSortFn,
    groupBy,
    groupSortFn,
  } = options;

  if (!groupBy) {
    return [...getValidFilterValues(filterOptions, currentFilters, filter)]
      .sort(filterSortFn)
      .map((value) => ({ value: value.toString(), label: value.toString() }))
      .filter((option) => !!option.value);
  }
  const validGroupValuePairs = getValidFilterCombinationsForFilter(
    filterOptions,
    currentFilters,
    filter,
  ).map((combination) => ({
    group: combination[groupBy],
    value: combination[filter],
  }));
  const uniqueCombinations = validGroupValuePairs.filter(
    (combination, index, array) =>
      array.findIndex((c) => c.value === combination.value) === index,
  );
  const notEmptyCombinations = uniqueCombinations.filter(
    (combination) =>
      combination.value !== null && combination.value !== undefined,
  );
  const sortedCombinations = notEmptyCombinations.sort((a, b) => {
    // sort by group first
    if (groupSortFn) {
      const groupResult = groupSortFn(a.group, b.group);
      if (groupResult !== 0) {
        return groupResult;
      }
    }
    // then if the groups are the same, sort by value
    return filterSortFn ? filterSortFn(a.value, b.value) : 0;
  });
  /**
   * split into groups in the format:
   *  [
   *    { group: 'Frontend', items: ['React', 'Angular'] },
   *    { group: 'Backend', items: ['Express', 'Django'] },
   *  ]
   */
  const groupedCombinations = sortedCombinations.reduce<
    Record<string, Array<string>>
  >((acc, combination) => {
    const group = combination.group?.toString() || "";
    const value = combination.value?.toString() || "";
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(value);
    return acc;
  }, {});
  return Object.entries(groupedCombinations).map(([group, values]) => ({
    group,
    items: values,
  }));
};

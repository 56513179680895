export const colours = {
  baseBrand: {
    black: "#000000",
    white: "#FFFFFF",
  },
  primaryBrand: {
    blue: "#4ba0cd",
    red: "#d24b28",
    pink: "#ffaad2",
    yellow: "#e1e146",
    sand: "#d7af96",
  },
  secondaryBrand: {
    grey: "#929f96",
    plum: "#bc5e8b",
    green: "#86a03c",
    indigo: "#817fd3",
    ochre: "#c1972c",
  },
};

/**
 * Clamps a value between 0 and 255
 *
 * @param value the value to clamp
 * @returns the clamped value
 */
export const clampRgbValue = (value: number): number => {
  if (value > 255) {
    return 255;
  }
  if (value < 0) {
    return 0;
  }
  return value;
};

/**
 * shades a hex colour (makes it darker)
 *
 * @param hex the hex colour to tint
 * @param shade a decimal value between 0 and 1 to tint the colour by. 0 is no tint, 1 is full tint
 * @returns the tinted hex colour
 */
export const shadeHexCode = (hex: string, shade: number): string => {
  const R = parseInt(hex.substring(1, 3), 16);
  const G = parseInt(hex.substring(3, 5), 16);
  const B = parseInt(hex.substring(5, 7), 16);

  const shadedR = Math.round(R * shade);
  const shadedG = Math.round(G * shade);
  const shadedB = Math.round(B * shade);

  const clampedR = clampRgbValue(shadedR);
  const clampedG = clampRgbValue(shadedG);
  const clampedB = clampRgbValue(shadedB);

  const stringR =
    clampedR < 16 ? `0${clampedR.toString(16)}` : clampedR.toString(16);
  const stringG =
    clampedG < 16 ? `0${clampedG.toString(16)}` : clampedG.toString(16);
  const stringB =
    clampedB < 16 ? `0${clampedB.toString(16)}` : clampedB.toString(16);

  return `#${stringR}${stringG}${stringB}`;
};

/**
 * Tints a hex colour (makes it lighter)
 *
 * @param hex the hex colour to tint
 * @param tint a decimal value between 0 and 1 to tint the colour by. 0 is no tint, 1 is full tint
 * @returns
 */
export const tintHexCode = (hex: string, tint: number): string => {
  const R = parseInt(hex.substring(1, 3), 16);
  const G = parseInt(hex.substring(3, 5), 16);
  const B = parseInt(hex.substring(5, 7), 16);

  const tintedR = Math.round(R + (255 - R) * (1 - tint));
  const tintedG = Math.round(G + (255 - G) * (1 - tint));
  const tintedB = Math.round(B + (255 - B) * (1 - tint));

  const clampedR = clampRgbValue(tintedR);
  const clampedG = clampRgbValue(tintedG);
  const clampedB = clampRgbValue(tintedB);

  return `#${clampedR.toString(16)}${clampedG.toString(16)}${clampedB.toString(16)}`;
};

/**
 * Generates a colour palette by spreading tints of a base colour from 100% to 20%
 *
 * @param baseColour the base colour
 * @param numberOfColours the number of colours to generate
 * @returns the colour palette
 */
export const generateColourTintSpread = (
  baseColour: string,
  numberOfColours: number,
): string[] => {
  // if 2 colours are requested, return the base colour and a 50% tint
  // otherwise, spread the tints from 100% to 20%
  const tintIncrement = numberOfColours < 3 ? 0.5 : 0.8 / (numberOfColours - 1);
  // tint array should start at 1 and go down to 0.2 (or 0.5 if there are only 2 colours)
  const tints = Array.from(
    { length: numberOfColours },
    (_, i) => 1 - tintIncrement * i,
  );
  return tints.map((tint) => tintHexCode(baseColour, tint));
};

/**
 * Generates a default colour palette
 *
 * @param numberOfColours the number of colours to generate
 * @returns the colour palette
 */
export const generateDefaultColourPalette = (
  numberOfColours: number,
): string[] => {
  const brandColours = [
    ...Object.values(colours.primaryBrand),
    ...Object.values(colours.secondaryBrand),
  ];
  const numberOfTints = Math.ceil(numberOfColours / brandColours.length);
  const coloursWithTints = brandColours.map((colour) =>
    generateColourTintSpread(colour, numberOfTints),
  );
  // make and array of the first index, then add all the second index, then all the third index, etc.
  const generatedColours = Array.from({ length: numberOfTints }, (_, i) =>
    coloursWithTints.map((colours) => colours[i]),
  ).flat();

  return generatedColours.slice(0, numberOfColours);
};

/**
 * Get a brand colour based on the index
 * if the index is greater than the number of brand colours, it will loop back around
 *
 * @param index the index of the colour
 * @param tint the tint of the colour
 * @returns the brand colour
 */
export const getDefaultColourForIndex = (index: number, tint?: number) => {
  const coercedIndex = Math.round(Math.abs(index));
  const options = generateDefaultColourPalette(10);
  // select a colour based on the campaign id (mod 10)
  const colour = options[coercedIndex % 10];
  return tint ? tintHexCode(colour, tint) : colour;
};

import { Avatar, Menu, Select } from "@mantine/core";
import { NonSuperUserRole, SuperUserRole } from "@tra-nz/platform-common";
import { signOut } from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";
import { FiCoffee, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../context/UserContext";
import { setApiClientToken } from "../../util/apiClient";

export const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, selectedClient, setSelectedClient, initUser } =
    useUser();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setIsSuperAdmin(currentUser.role === SuperUserRole.SUPER_ADMIN);
      setIsAdmin(
        currentUser.clients.some((c) => c.role === NonSuperUserRole.ADMIN),
      );
    }
  }, [currentUser]);

  const handleUserLogout = useCallback(async () => {
    try {
      await signOut();
      await initUser();
    } finally {
      setApiClientToken();
      navigate("/login", { replace: true });
    }
  }, [initUser, navigate]);

  const userInitials = currentUser?.email
    .split("@")[0]
    .split(".")
    .map((n) => n.charAt(0).toUpperCase())
    .join("");

  return (
    <Menu offset={14}>
      <Menu.Target>
        <Avatar
          size="sm"
          radius="xl"
          className="m-4 active:opacity-30 hover:opacity-70"
          variant="filled"
        >
          {userInitials}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        {isSuperAdmin && (
          <Menu.Item
            leftSection={<FiCoffee />}
            onClick={() => navigate("/admin/clients")}
          >
            Client Management
          </Menu.Item>
        )}
        {(isSuperAdmin || isAdmin) && (
          <Menu.Item
            leftSection={<FiUser />}
            onClick={() => navigate("/admin/users")}
          >
            User Management
          </Menu.Item>
        )}

        {currentUser && currentUser.clients.length > 1 && (
          <Select
            className="p-2"
            description="Select Client"
            data={currentUser?.clients.map((uc) => ({
              value: uc.id,
              label: uc.name,
            }))}
            value={selectedClient?.id}
            onChange={(_value, option) => {
              if (option) {
                setSelectedClient(option.value);
                navigate("/", { replace: true });
              }
            }}
            placeholder="Select Client"
          ></Select>
        )}

        <Menu.Item onClick={handleUserLogout}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

/**
 * @generated SignedSource<<294d5ff7caebaab6d2f34d281ecd9bb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreativeEdgeFilterBarQuery$variables = {
  campaignId?: number | null | undefined;
  clientId: string;
  metric: string;
};
export type CreativeEdgeFilterBarQuery$data = {
  readonly filterOptions: ReadonlyArray<{
    readonly AUDIENCE1: string | null | undefined;
    readonly AUDIENCE2: string | null | undefined;
    readonly AUDIENCE_GROUP1: string | null | undefined;
    readonly AUDIENCE_GROUP2: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CreativeEdgeFilterBarQuery = {
  response: CreativeEdgeFilterBarQuery$data;
  variables: CreativeEdgeFilterBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v3 = [
  {
    "alias": "filterOptions",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "AUDIENCE1",
          "AUDIENCE_GROUP1",
          "AUDIENCE2",
          "AUDIENCE_GROUP2"
        ]
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "campaignId"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE_GROUP2",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeEdgeFilterBarQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeEdgeFilterBarQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6ff61b521cfea3de763f843c2fd14309",
    "id": null,
    "metadata": {},
    "name": "CreativeEdgeFilterBarQuery",
    "operationKind": "query",
    "text": "query CreativeEdgeFilterBarQuery(\n  $clientId: String!\n  $metric: String!\n  $campaignId: Int\n) {\n  filterOptions: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, ROLL: $campaignId}, distinctSelect: [\"AUDIENCE1\", \"AUDIENCE_GROUP1\", \"AUDIENCE2\", \"AUDIENCE_GROUP2\"]) {\n    AUDIENCE1\n    AUDIENCE_GROUP1\n    AUDIENCE2\n    AUDIENCE_GROUP2\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb1388368b9f65f75b39e3fc711d4a87";

export default node;

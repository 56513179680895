const getShortMonth = (date: Date): string =>
  date.toLocaleString("default", { month: "short" });

const getShortYear = (date: Date): string =>
  ` '${date.toLocaleString("default", { year: "2-digit" })}`;

/**
 * Get the month range for a given roll
 *
 * @param roll the number of months to roll back
 * @param endDate the end date
 * @returns the month range in the format "MMM - MMM"
 */
export const getMonthRangeForRoll = (
  roll: number,
  endWaveDate: string,
): string | null => {
  const end = new Date(endWaveDate);
  if (isNaN(end.getTime()) || roll < 1) {
    return null;
  }
  const start = new Date(end);
  start.setMonth(end.getMonth() - roll + 1);
  const includeYear = end.getFullYear() !== start.getFullYear();

  const startDate = `${getShortMonth(start)}${includeYear ? getShortYear(start) : ""}`;
  const endDate = `${getShortMonth(end)}${includeYear ? getShortYear(end) : ""}`;

  if (startDate === endDate) {
    return startDate;
  }

  return `${startDate} - ${endDate}`;
};

import { Client } from "@tra-nz/platform-common";
import { fetchAuthSession } from "aws-amplify/auth";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { User } from "../types/User";
import { apiClient, setApiClientToken } from "../util/apiClient";
import { getAllClients } from "../util/getAllClients";

type UserContext = {
  readonly currentUser?: User | null;
  readonly initUser: () => Promise<void>;
  readonly selectedClient?: Client;
  readonly setSelectedClient: (selectedClientId: string) => void;
};

const Context = createContext<UserContext>({
  initUser: () => Promise.resolve(),
  setSelectedClient: () => {},
});

type UserContextProviderProps = {
  readonly onUserUpdate?: () => void;
  readonly children: React.ReactNode;
};

/**
 * A context provider that provides the user details and methods to interact with the user
 * @param props the children components
 * @returns a user context provider
 */
export const UserContextProvider: React.FC<UserContextProviderProps> = (
  props,
) => {
  const { onUserUpdate, children } = props;
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [selectedClientConfig, setSelectedClientConfig] = useState<Client>();

  const setSelectedClient = useCallback(
    (selectedClientId: string): void => {
      const selectedClient = currentUser?.clients.find(
        (client) => client.id === selectedClientId,
      );
      if (selectedClient) {
        setSelectedClientConfig(selectedClient);
        localStorage.setItem("selectedClientId", selectedClientId);
      }
    },
    [currentUser],
  );

  const initUser = useCallback(async () => {
    const { accessToken: sessionJwt } = (await fetchAuthSession()).tokens ?? {};
    const authToken = sessionJwt?.toString();
    if (!authToken) {
      setApiClientToken();
      setCurrentUser(null);
      return;
    }
    setApiClientToken(authToken);

    /* fetching user data and accessible clients on page refresh and on logging in */
    const userInfoResponse = await apiClient.get("/users/me");
    if (userInfoResponse.status !== 200) {
      setCurrentUser(null);
      // There is no user logged in
      return;
    }
    const userInfo = userInfoResponse.data;
    const clients = await getAllClients(userInfo);

    setCurrentUser({
      ...userInfo,
      clients,
    });

    const storedSelectedClientId = localStorage.getItem("selectedClientId");
    const storedSelectedClient = clients.find(
      (client) => client.id === storedSelectedClientId,
    );
    const selectedClient = storedSelectedClient || clients[0];
    setSelectedClientConfig(selectedClient);

    onUserUpdate && onUserUpdate();
  }, [onUserUpdate]);

  useEffect(() => {
    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        currentUser,
        initUser,
        selectedClient: selectedClientConfig,
        setSelectedClient,
      }}
    >
      {children}
    </Context.Provider>
  );
};

// hook to use the user context
export const useUser = () => useContext(Context);

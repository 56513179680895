import { Image, rem } from "@mantine/core";
import { useMemo, useState } from "react";
import { LiaPhotoVideoSolid } from "react-icons/lia";

import { getDefaultColourForIndex } from "../../util/colours";
import { genCampaignImageURL } from "../../util/creativeEdge";

const DEFAULT_VIDEO_TILE_HEIGHT = 85;

type VideoPreviewImageTileProps = {
  readonly trackerProjectId?: number;
  readonly campaignId?: number;
  readonly height?: number;
  readonly width?: number;
};

/**
 * Video Preview Image Tile
 *
 * @param props the video preview image tile props
 * @returns the video preview image tile component
 */
export const VideoPreviewImageTile: React.FC<VideoPreviewImageTileProps> = (
  props: VideoPreviewImageTileProps,
) => {
  const { trackerProjectId, campaignId, height, width } = props;

  const [imageError, setImageError] = useState(false);

  const background = getDefaultColourForIndex(campaignId || 0, 0.33);

  const imageURL =
    trackerProjectId && campaignId
      ? genCampaignImageURL(trackerProjectId, campaignId)
      : "";

  const imageHeight = useMemo(() => {
    return height || DEFAULT_VIDEO_TILE_HEIGHT;
  }, [height]);

  const imageWidth = useMemo(() => {
    return width || 2 * imageHeight;
  }, [width, imageHeight]);

  return (
    <div
      className="flex items-center justify-center overflow-clip"
      style={{
        height: rem(imageHeight),
        width: rem(imageWidth),
        background,
      }}
    >
      {imageURL && !imageError ? (
        <Image
          src={imageURL}
          onError={() => {
            setImageError(true);
          }}
        />
      ) : (
        <LiaPhotoVideoSolid
          style={{
            width: rem(48),
            height: rem(48),
            color: "gray",
          }}
        />
      )}
    </div>
  );
};

/**
 * Converts a string to URL param case.
 * uses a regex to replace all spaces with hyphens and then converts the string to lowercase.
 * case example: "This is a string" -> "this-is-a-string"
 *
 * @param str the string to convert
 * @returns the string in URL param case
 */
export const toUrlParamCase = (str: string) => {
  return str.toLowerCase().replaceAll(" ", "-");
};

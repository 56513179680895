import {
  Collapse,
  ComboboxData,
  Divider,
  Image,
  Text,
  rem,
} from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useMemo, useState } from "react";
import { RxCaretRight } from "react-icons/rx";
import { useLazyLoadQuery } from "react-relay";

import creativeEdgeTriangle from "../../assets/creative_edge_triangle_small.svg";
import {
  CampaignSummary,
  CreativeEdgeMetrics,
  getCampaignSummaries,
} from "../../util/creativeEdge";
import { FontFamily } from "../../util/fontFamily";
import { FilterDropdown } from "../FilterDropdown";
import { CampaignPillarBreakdown } from "./CampaignPillarBreakdown";
import { CEPillar } from "./CompareCampaignsSideBySide";
import { CreativeEdgeFilters } from "./CreativeEdgeFilterBar";
import { VideoPreviewImageTile } from "./VideoPreviewImageTile";
import { CampaignSnapshotColumnQuery as CampaignSnapshotColumnQueryType } from "./__generated__/CampaignSnapshotColumnQuery.graphql";

export const COLUMN_WIDTH = 480;
const IMAGE_HEIGHT = COLUMN_WIDTH * 0.46;

type CampaignSnapshotColumnProps = {
  readonly clientId: string;
  readonly initialCampaignId?: number;
  readonly audienceFilters: CreativeEdgeFilters;
  readonly pillarDropdownStates: Record<CEPillar, boolean>;
  readonly togglePillarDropdown: (pillar: CEPillar) => void;
};

const CampaignSnapshotColumnQuery = graphql`
  query CampaignSnapshotColumnQuery(
    $clientId: String!
    $metric: String!
    $audience: String!
    $secondaryAudience: String
  ) {
    campaigns: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
      }
    ) {
      PROJECT_ID
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
      ROLL
      QUESTION_TEXT
    }
  }
`;

/**
 * An individual vertical campaign snapshot for comparing campaigns
 *
 * @param props - the component props
 * @returns the campaign snapshot column component
 */
export const CampaignSnapshotColumn: React.FC<CampaignSnapshotColumnProps> = (
  props: CampaignSnapshotColumnProps,
) => {
  const {
    clientId,
    initialCampaignId,
    audienceFilters,
    togglePillarDropdown,
    pillarDropdownStates,
  } = props;

  const [campaignId, setCampaignId] = useState<number | undefined>(
    initialCampaignId,
  );

  const queryData = useLazyLoadQuery<CampaignSnapshotColumnQueryType>(
    CampaignSnapshotColumnQuery,
    {
      clientId: clientId,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...audienceFilters,
    },
  );

  const trackerProjectId = useMemo(() => {
    if (
      queryData.campaigns &&
      queryData.campaigns.length > 0 &&
      queryData.campaigns[0]?.PROJECT_ID
    ) {
      return queryData.campaigns[0].PROJECT_ID;
    }
  }, [queryData]);

  const campaigns = useMemo<ReadonlyArray<CampaignSummary>>(() => {
    const { campaigns: rawCampaignData } = queryData;
    return getCampaignSummaries(rawCampaignData);
  }, [queryData]);

  const campaignOptions: ComboboxData = useMemo(() => {
    return campaigns.map((campaign) => ({
      label: campaign.title,
      value: campaign.campaignId.toString(),
    }));
  }, [campaigns]);

  const selectedCampaign = useMemo(() => {
    return campaigns.find((campaign) => campaign.campaignId === campaignId);
  }, [campaignId, campaigns]);

  const pillarScores: Record<CEPillar, number | undefined> = useMemo(() => {
    if (!selectedCampaign) {
      return {
        Remarkable: undefined,
        Rewarding: undefined,
        Remembered: undefined,
      };
    }
    return {
      Remarkable: selectedCampaign.remarkable,
      Rewarding: selectedCampaign.rewarding,
      Remembered: selectedCampaign.remembered,
    };
  }, [selectedCampaign]);

  return (
    <div
      className="flex flex-col h-full my-8 mr-8"
      style={{
        width: COLUMN_WIDTH,
      }}
    >
      {selectedCampaign ? (
        <VideoPreviewImageTile
          campaignId={campaignId}
          trackerProjectId={trackerProjectId}
          height={IMAGE_HEIGHT}
          width={COLUMN_WIDTH}
        />
      ) : (
        <div
          style={{
            height: IMAGE_HEIGHT,
            width: COLUMN_WIDTH,
            backgroundColor: "#f0f0f0",
          }}
        />
      )}
      <div className="flex flex-row items-center w-full py-3">
        <Text
          ff={FontFamily.PPNeueMontrealBold}
          size={"16px"}
          py={"md"}
          pr={"md"}
        >
          Execution
        </Text>
        <FilterDropdown
          variant="dropdown"
          placeholder="Select Campaign"
          options={campaignOptions}
          value={campaignId?.toString() || null}
          onSelect={(value) => value && setCampaignId(parseInt(value))}
          autoSelect={false}
          fullWidth
          searchable
        />
      </div>
      {selectedCampaign && (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <Divider />
            <div className="flex flex-row items-center justify-between w-full py-4">
              <Text
                key={2}
                lineClamp={1}
                ff={FontFamily.PPNeueMontrealBook}
                size={"16px"}
                pr={"xs"}
              >
                Creative Edge Score
              </Text>
              <div className="flex flex-row items-center">
                <Image
                  src={creativeEdgeTriangle}
                  className="m-2"
                  style={{ width: rem(20) }}
                />
                <div className="flex flex-col items-end w-14">
                  <Text
                    ff={FontFamily.PPNeueMontrealBold}
                    size={"34px"}
                    pr={"xs"}
                  >
                    {Math.round(selectedCampaign.score || 0)}
                  </Text>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-col w-full">
              {Object.entries(pillarScores).map(([metric, value], index) => {
                const isCEPillar = (metric: string): metric is CEPillar =>
                  metric === "Remarkable" ||
                  metric === "Rewarding" ||
                  metric === "Remembered";
                if (!isCEPillar(metric) || value === undefined) {
                  return null;
                }
                return (
                  <div key={index} className="flex flex-col w-full">
                    <div
                      className="flex flex-row items-center justify-between cursor-pointer"
                      onClick={() => togglePillarDropdown(metric)}
                    >
                      <div className="flex flex-row items-center">
                        <RxCaretRight
                          style={{
                            transform: pillarDropdownStates[metric]
                              ? "rotate(90deg)"
                              : "",
                            transition: "transform 0.3s",
                            width: rem(16),
                            height: rem(16),
                          }}
                        />
                        <Text
                          ff={FontFamily.PPNeueMontrealBook}
                          size={"16px"}
                          py={"lg"}
                          pl={"md"}
                        >
                          {metric}
                        </Text>
                      </div>
                      <Text
                        ff={FontFamily.PPNeueMontrealMedium}
                        size={"24px"}
                        p={"sm"}
                        pl={0}
                      >
                        {Math.round(value) || "-"}
                      </Text>
                    </div>
                    <Collapse in={pillarDropdownStates[metric]}>
                      {campaignId && (
                        <CampaignPillarBreakdown
                          clientId={clientId}
                          campaignId={campaignId}
                          pillar={metric}
                          audienceFilters={audienceFilters}
                          compact
                        />
                      )}
                    </Collapse>
                    <Divider />
                  </div>
                );
              })}
            </div>
          </div>
          <Divider orientation="vertical" />
          <div className="flex flex-col w-full">
            {Object.entries(selectedCampaign.diagnostics).map(
              ([statement, percentage], index) => {
                const formattedPercentage = Math.round(percentage * 100);
                return (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between w-full"
                  >
                    <Text
                      ff={FontFamily.PPNeueMontrealBook}
                      size={"18px"}
                      p={"lg"}
                      opacity={0.8}
                    >
                      {statement}
                    </Text>
                    <Text
                      ff={FontFamily.PPNeueMontrealMedium}
                      size={"20px"}
                      p={"md"}
                      pl={0}
                      opacity={0.8}
                    >
                      {formattedPercentage}%
                    </Text>
                  </div>
                );
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * @generated SignedSource<<7b540f655732102fa284b8649f857b9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreativeEdgeSnapshotQuery$variables = {
  audience: string;
  campaignId: number;
  clientId: string;
  metric: string;
  secondaryAudience?: string | null | undefined;
};
export type CreativeEdgeSnapshotQuery$data = {
  readonly diagnostics: ReadonlyArray<{
    readonly PERCENTAGE: number | null | undefined;
    readonly QUESTION_TEXT: string | null | undefined;
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly pillars: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly PROJECT_ID: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CreativeEdgeSnapshotQuery = {
  response: CreativeEdgeSnapshotQuery$data;
  variables: CreativeEdgeSnapshotQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audience"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondaryAudience"
},
v5 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v6 = {
  "kind": "Variable",
  "name": "AUDIENCE1",
  "variableName": "audience"
},
v7 = {
  "kind": "Variable",
  "name": "AUDIENCE2",
  "variableName": "secondaryAudience"
},
v8 = {
  "kind": "Variable",
  "name": "BRAND_METRIC",
  "variableName": "metric"
},
v9 = {
  "kind": "Variable",
  "name": "ROLL",
  "variableName": "campaignId"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PERCENTAGE",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "STATEMENT",
  "storageKey": null
},
v12 = [
  {
    "alias": "pillars",
    "args": [
      (v5/*: any*/),
      {
        "fields": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "Literal",
            "name": "IS_SCORE",
            "value": 1
          },
          (v9/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PROJECT_ID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "diagnostics",
    "args": [
      (v5/*: any*/),
      {
        "fields": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "Literal",
            "name": "IS_SCORE",
            "value": 0
          },
          (v9/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "QUESTION_TEXT",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeEdgeSnapshotQuery",
    "selections": (v12/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeEdgeSnapshotQuery",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "7251cee89d738c850eb7a658c42b4d8d",
    "id": null,
    "metadata": {},
    "name": "CreativeEdgeSnapshotQuery",
    "operationKind": "query",
    "text": "query CreativeEdgeSnapshotQuery(\n  $clientId: String!\n  $campaignId: Int!\n  $metric: String!\n  $audience: String!\n  $secondaryAudience: String\n) {\n  pillars: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, ROLL: $campaignId, IS_SCORE: 1, AUDIENCE1: $audience, AUDIENCE2: $secondaryAudience}) {\n    PROJECT_ID\n    BASE\n    BRAND\n    PERCENTAGE\n    STATEMENT\n  }\n  diagnostics: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, ROLL: $campaignId, IS_SCORE: 0, AUDIENCE1: $audience, AUDIENCE2: $secondaryAudience}) {\n    PERCENTAGE\n    STATEMENT\n    QUESTION_TEXT\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ecdce148f4ef94ee7e81d688ae683e1";

export default node;

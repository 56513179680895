import { Divider, Text } from "@mantine/core";
import { isString } from "lodash";
import { useEffect, useState } from "react";

import { CollatedData } from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { getBaseSizeFromCollatedData } from "../../util/getBaseSizeFromCollatedData";
import { getMonthRangeForRoll } from "../../util/getMonthRangeForRoll";
import { DashboardLevelQueryFilters } from "../../view/StandardDashboardTab";

export const FOOTER_HEIGHT = 40;

type VisualisationFooterProps = {
  readonly dashboardFilters: DashboardLevelQueryFilters;
  readonly data: CollatedData;
};

/**
 * The standard footer for visualisations
 *
 * @param props the current filters and data for the visualisation
 * @returns the visualisation footer
 */
export const VisualisationFooter: React.FC<VisualisationFooterProps> = (
  props: VisualisationFooterProps,
) => {
  const { dashboardFilters, data } = props;
  const { category, roll, firstAudience, secondAudience } = dashboardFilters;

  const [baseSize, setBaseSize] = useState<string | null>(null);
  const [rollMonthRange, setRollMonthRange] = useState<string | null>(null);

  useEffect(() => {
    const baseSize = getBaseSizeFromCollatedData(data);
    setBaseSize(baseSize);

    if (!data || data.length === 0) {
      return;
    }
    const nonNullWaveData = data
      .map((item) => item?.WAVE_DATE)
      .filter(isString);
    if (nonNullWaveData.length === 0) {
      return;
    }
    const firstWave = nonNullWaveData[0];
    const isAllOneMonth = nonNullWaveData.every((wave) => wave === firstWave);
    if (isAllOneMonth && firstWave && roll) {
      setRollMonthRange(getMonthRangeForRoll(roll, firstWave));
    }
  }, [data, roll]);

  return (
    <div className="flex items-center ml-5" style={{ height: FOOTER_HEIGHT }}>
      {baseSize && (
        <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
          {baseSize}
        </Text>
      )}
      {category && (
        <>
          <Divider orientation="vertical" m={"sm"} />
          <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
            {`Category: ${category}`}
          </Text>
        </>
      )}
      {roll && (
        <>
          <Divider orientation="vertical" m={"sm"} />
          <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
            {`Roll: ${roll} month${roll && roll > 1 ? "s" : ""}`}
          </Text>
        </>
      )}
      {rollMonthRange && (
        <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"} ml={"xs"}>
          {rollMonthRange}
        </Text>
      )}
      {firstAudience && (
        <>
          <Divider orientation="vertical" m={"sm"} />
          <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
            {`Audience: ${firstAudience}`}
          </Text>
        </>
      )}
      {secondAudience && firstAudience !== secondAudience && (
        <>
          <Divider orientation="vertical" m={"sm"} />
          <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
            {`Secondary Audience: ${secondAudience}`}
          </Text>
        </>
      )}
    </div>
  );
};

import { Text } from "@mantine/core";

import { FontFamily } from "../../util/fontFamily";
import { VideoPreviewImageTile } from "./VideoPreviewImageTile";

export const CAMPAIGN_HEADER_HEIGHT = 65;

type CampaignHeaderProps = {
  readonly trackerProjectId?: number;
  readonly campaignId: number;
  readonly campaignTitle?: string;
};

/**
 * Individual Campaign Header
 *
 * @param props the campaign header props
 * @returns the campaign header component
 */
export const CampaignHeader: React.FC<CampaignHeaderProps> = (
  props: CampaignHeaderProps,
) => {
  const { trackerProjectId, campaignId, campaignTitle } = props;

  return (
    <div
      className="flex flex-row items-center w-full"
      style={{
        height: CAMPAIGN_HEADER_HEIGHT,
        background: "#F7F7F7",
      }}
    >
      <VideoPreviewImageTile
        height={CAMPAIGN_HEADER_HEIGHT}
        trackerProjectId={trackerProjectId}
        campaignId={campaignId}
      />
      <Text ff={FontFamily.PPNeueMontrealMedium} size={"18px"} pl={"xl"}>
        {campaignTitle}
      </Text>
    </div>
  );
};

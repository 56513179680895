import React, { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { LoadingSpinner } from "../component/LoadingSpinner";
import { Navbar } from "../component/Navbar/Navbar";
import { useUser } from "../context/UserContext";

export const ProtectedRoute: React.FC = () => {
  const { currentUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate("/login", { replace: true });
    }
  }, [currentUser, navigate]);

  const fallback = (
    <div className="w-full h-full">
      <Navbar />
      <LoadingSpinner />
    </div>
  );

  return (
    <div className="w-full h-full">
      <Suspense fallback={fallback}>
        <Outlet />
      </Suspense>
    </div>
  );
};

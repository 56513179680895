import { ClientConfig } from "@tra-nz/platform-common";

import { NullableStringSortFn, sortByOrderThenAlphabeticalFn } from "./sort";

/**
 * Get the brand order for a particular client
 *
 * @param config the client configuration
 * @returns the brand order for the client
 */
export const getBrandOrderForClient = (config: ClientConfig): string[] => {
  const { clientBrands, competitorBrands } = config;
  return [
    ...clientBrands.map((brand) => brand.name),
    ...competitorBrands.map((brand) => brand.name),
  ];
};

/**
 * Generates a comparison function for sorting brand names
 *
 * @param config the client configuration
 * @returns a comparison function for sorting brand names
 */
export const genBrandCompareFn = (
  config: ClientConfig,
): NullableStringSortFn => {
  const brandOrder = getBrandOrderForClient(config);
  return sortByOrderThenAlphabeticalFn(brandOrder);
};

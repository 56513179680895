import { Divider, rem } from "@mantine/core";
import { Children } from "react";

type TableRowProps = {
  readonly height?: string;
  readonly onClick?: () => void;
  readonly children: React.ReactNode;
};

/**
 * Table Row for the creative edge summary list view
 *
 * @param props the row children
 * @returns the table row component
 */
export const TableRow: React.FC<TableRowProps> = (props: TableRowProps) => {
  const { height, onClick, children } = props;

  if (!children || Children.count(children) < 6) {
    return (
      <div
        style={{
          height: rem(10),
        }}
      >
        <Divider />
      </div>
    );
  }

  const genPillarContainer = (
    child: React.ReactNode,
    isMainPillar?: boolean,
  ) => {
    return (
      <div
        className="flex justify-end"
        style={{
          minWidth: rem(isMainPillar ? 210 : 170),
        }}
      >
        {child}
      </div>
    );
  };

  const allChildren = Children.toArray(children);
  return (
    <div
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <div
        className="flex flex-row items-center justify-between w-full"
        style={{ height }}
      >
        <div
          className="flex w-full h-full"
          style={{
            minWidth: rem(200),
            maxWidth: rem(200),
          }}
        >
          {allChildren[0]}
        </div>
        <div className="w-full min-w-52">{allChildren[1]}</div>
        {genPillarContainer(allChildren[2], true)}
        {genPillarContainer(allChildren[3])}
        {genPillarContainer(allChildren[4])}
        {genPillarContainer(allChildren[5])}
      </div>
      <Divider />
    </div>
  );
};

import { Button, Flex, Group, Image, NavLink, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FloatingLabelTextInput } from "../component/FloatingLabelTextInput";
import { apiClient } from "../util/apiClient";
import { isPasswordComplex } from "../util/isPasswordComplex";

/**
 * A page that allows users to set their password
 * @returns a set password page
 */
export const SetPasswordPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failedToSetPassword, setFailedToSetPassword] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useSearchParams;
  const form = useForm({
    mode: "controlled",
    initialValues: {
      password: "",
    },
    validate: {
      password: isPasswordComplex,
    },
  });

  const handleSetPassword = useCallback(
    async (credentials: {
      email: string;
      password: string;
      newPassword: string;
    }) => {
      try {
        const { nextStep } = await signIn({
          username: credentials.email,
          password: credentials.password,
        });
        if (
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
        ) {
          const confirmedSignIn = await confirmSignIn({
            challengeResponse: credentials.newPassword,
          });
          navigate("/home", { replace: true });
          return confirmedSignIn.isSignedIn;
        }
      } catch (error) {
        setFailedToSetPassword(true);
      }
      return false;
    },
    [navigate, setFailedToSetPassword],
  );

  const handleFormSubmit = async (values: { password: string }) => {
    setIsSubmitting(true);
    const { password } = values;
    try {
      await handleSetPassword({
        email: searchParams.get("email") || "",
        password: searchParams.get("password") || "",
        newPassword: password,
      });
    } catch (error) {
      form.setErrors({
        password: "Invalid credentials",
      });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        setIsFetchingUser(true);
        const usersData: { data: { enable: string; status: string } } =
          await apiClient.get(
            `/users/${searchParams.get("platform_id")}/status`,
          );
        if (usersData.data.status !== "FORCE_CHANGE_PASSWORD") {
          navigate("/login", { replace: true });
        }
      } finally {
        setIsFetchingUser(false);
      }
    };
    if (searchParams.get("platform_id")) {
      fetchUserStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-black">
      <div className="h-32">
        <Image
          src={process.env.PUBLIC_URL + "/TRA_logo_white.png"}
          w="150px"
          aria-label="logo"
        />
      </div>
      {failedToSetPassword && (
        <div>
          <Text className="text-center text-red-500">
            This link has expired or is invalid.
          </Text>
          <Text className="text-center text-red-500">
            Let us know so that we can get send you a new link.
          </Text>
        </div>
      )}
      {!failedToSetPassword && (
        <form onSubmit={form.onSubmit(handleFormSubmit)} className="min-w-80">
          <div className="mb-8 text-white">
            Set Password for {searchParams.get("email")}
          </div>
          <FloatingLabelTextInput
            label="Password"
            type="password"
            key={form.key("password")}
            {...form.getInputProps("password")}
            styles={{
              input: { backgroundColor: "black", color: "white" },
              label: { color: "white" },
              root: { marginBottom: "40px" },
            }}
          />
          <Group justify="space-around" mt="md">
            {searchParams.get("email") && searchParams.get("password") ? (
              <Button
                type="submit"
                variant="white"
                color="gray.9"
                loading={isFetchingUser || isSubmitting}
                disabled={isFetchingUser || isSubmitting}
              >
                Submit
              </Button>
            ) : (
              <p>Invalid URL</p>
            )}
          </Group>
          <Flex gap="md" justify="center" align="center">
            <NavLink
              href="/login"
              label="Login"
              className="text-white hover:text-gray-300 hover:bg-inherit w-fit"
            ></NavLink>
          </Flex>
        </form>
      )}
    </div>
  );
};

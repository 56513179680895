import _ from "lodash";

import { roundValueForDisplay } from "./dataProcessing";

type FormatterOptions = {
  seriesIndex: number;
  dataPointIndex: number;
  w: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type FormattingFunction = (
  value: string | number | null,
  options?: FormatterOptions,
) => string | string[];

// enum of available value formatters
export enum Formatter {
  DecimalToPercentage = "DecimalToPercentage",
  DecimalToPercentageWithIcon = "DecimalToPercentageWithIcon",
  PercentageRemoveDecimal = "PercentageRemoveDecimal",
  PercentageRemoveDecimalWithIcon = "PercentageRemoveDecimalWithIcon",
  PercentageSingleDecimal = "PercentageSingleDecimal",
  PercentageSingleDecimalWithIcon = "PercentageSingleDecimalWithIcon",
  ThreeLetterMonthYearDate = "ThreeLetterMonthYearDate",
  AllCapsThreeLetterMonthYearDate = "AllCapsThreeLetterMonthYearDate",
  AllCapsDateWithTick = "AllCapsDateWithTick",
  OtherAxisPercentage = "OtherAxisPercentage",
  OtherAxisPercentageWithIcon = "OtherAxisPercentageWithIcon",
  OtherAxisDecimalToPercentage = "OtherAxisDecimalToPercentage",
  OtherAxisDecimalToPercentageWithIcon = "OtherAxisDecimalToPercentageWithIcon",
  SeriesName = "SeriesName",
  PercentageAndSeriesName = "PercentageAndSeriesName",
  None = "None",
}

/**
 * Function to convert a number string to a number
 * @param value Number string or number
 * @returns Number
 */
const convertStringToNumber = (value: string | number | null): number => {
  if (value === null) {
    return 0;
  }
  return typeof value === "string" ? parseFloat(value) : value;
};

/**
 * Function to convert a number string to an integer percentage
 * @param value Number string or number
 * @returns Integer
 */
const convertDecimalStringToIntegerPercentage = (
  value: string | number | null,
): number => {
  if (value === null) {
    return 0;
  }
  const parsedNumber = typeof value === "string" ? parseFloat(value) : value;
  return roundValueForDisplay(parsedNumber * 100);
};

const oneDayMilliseconds = 86400000;

const parseKlipfolioDate = (value: string | number | null): Date => {
  if (value === null) {
    return new Date();
  }
  const date = new Date(value);
  // Add a day because the date is sometimes parsed as the day before
  return new Date(date.getTime() + oneDayMilliseconds);
};

/**
 * Get the nearest wave start date for a given date
 *
 * for example, if the date is 2022-01-12, the nearest wave start date is 2022-02-01
 * if the date is 2022-01-16, the nearest wave start date is 2022-02-01
 *
 * @param date
 * @returns
 */
const nearestWaveStartDate = (date: Date): Date => {
  const waveStartDate = new Date(date);
  if (date.getDate() < 26) {
    return waveStartDate;
  }
  waveStartDate.setMonth(waveStartDate.getMonth() + 1);
  return waveStartDate;
};

/**
 * Map of available value formatters
 */
export const valueFormatter: Record<Formatter, FormattingFunction> = {
  DecimalToPercentage: (value) => {
    return convertDecimalStringToIntegerPercentage(value).toString();
  },
  DecimalToPercentageWithIcon: (value) => {
    const valueToConvert = convertDecimalStringToIntegerPercentage(value);
    return `${valueToConvert}%`;
  },
  PercentageRemoveDecimal: (value) => {
    const valueToConvert = convertStringToNumber(value);
    return valueToConvert.toFixed(0);
  },
  PercentageRemoveDecimalWithIcon: (value) => {
    const valueToConvert = convertStringToNumber(value);
    return `${roundValueForDisplay(valueToConvert)}%`;
  },
  PercentageSingleDecimal: (value) => {
    const valueToConvert = convertStringToNumber(value);
    const roundedValue = Math.round(valueToConvert * 10) / 10;
    return roundedValue.toString();
  },
  PercentageSingleDecimalWithIcon: (value) => {
    const valueToConvert = convertStringToNumber(value);
    const roundedValue = Math.round(valueToConvert * 10) / 10;
    return `${roundedValue}%`;
  },
  ThreeLetterMonthYearDate: (value) => {
    const date = parseKlipfolioDate(value);
    return date.toLocaleString("en-NZ", {
      month: "short",
      year: "numeric",
    });
  },
  AllCapsThreeLetterMonthYearDate: (value) => {
    const date = parseKlipfolioDate(value);
    const nearestWaveStart = nearestWaveStartDate(date);
    const formattedMonth = nearestWaveStart
      .toLocaleString("en-NZ", {
        month: "short",
      })
      .slice(0, 3);
    const formattedYear = nearestWaveStart.toLocaleString("en-NZ", {
      year: "2-digit",
    });
    return `${formattedMonth.toUpperCase()} '${formattedYear}`;
  },
  AllCapsDateWithTick: (value) => {
    const date = parseKlipfolioDate(value);
    const formattedDate = date.toLocaleString("en-NZ", {
      month: "short",
      year: "2-digit",
    });
    const tickIndex = formattedDate.indexOf(" ");
    // insert a space after the first three characters
    const formattedDateWithTick = [
      formattedDate.slice(0, tickIndex),
      "'",
      formattedDate.slice(tickIndex),
    ].join("");
    return formattedDateWithTick.toUpperCase();
  },
  OtherAxisPercentage: (_value, options) => {
    if (!options) {
      return "";
    }
    const { seriesIndex, dataPointIndex, w } = options;
    const percentageValue =
      w.config.series[seriesIndex].data[dataPointIndex][0];
    const valueToConvert = convertStringToNumber(percentageValue);
    const roundedValue = valueToConvert.toFixed(0);
    return `${roundedValue}`;
  },
  OtherAxisPercentageWithIcon: (value, options) => {
    return `${valueFormatter.OtherAxisPercentage(value, options)}%`;
  },
  OtherAxisDecimalToPercentage: (_value, options) => {
    if (!options) {
      return "";
    }
    const { seriesIndex, dataPointIndex, w } = options;
    const percentageValue =
      w.config.series[seriesIndex].data[dataPointIndex][0];
    const valueToConvert =
      convertDecimalStringToIntegerPercentage(percentageValue);
    return `${valueToConvert}`;
  },
  OtherAxisDecimalToPercentageWithIcon: (value, options) => {
    return `${valueFormatter.OtherAxisDecimalToPercentage(value, options)}%`;
  },
  SeriesName: (_value, options) => {
    if (!options) {
      return "";
    }
    const { w, seriesIndex } = options;
    return w.config.series[seriesIndex].name;
  },
  PercentageAndSeriesName: (value, options) => {
    const seriesNames = valueFormatter.SeriesName(value, options);
    const seriesName = Array.isArray(seriesNames)
      ? seriesNames[0]
      : seriesNames;
    const valueToConvert = convertStringToNumber(value);
    const roundedValue = valueToConvert.toFixed(0);
    return [`${roundedValue}%`, ...seriesName.split(" ")];
  },
  None: (value) => {
    if (value === null) {
      return "";
    }
    return value.toString();
  },
};

/**
 *  Wrapper for formatting functions to catch errors
 * @param formatter Formatter enum
 * @returns Formatter function with error handling
 */
export const errorHandlingFormatter = (formatter: Formatter) => {
  return (
    value: string | number,
    options?: FormatterOptions,
  ): string | string[] | null => {
    try {
      return valueFormatter[formatter](value, options);
    } catch (e) {
      return null;
    }
  };
};

import { Suspense, useState } from "react";

import { CreativeEdgePillars } from "../../util/creativeEdge";
import { SmallLoader } from "../SmallLoader";
import { COLUMN_WIDTH, CampaignSnapshotColumn } from "./CampaignSnapshotColumn";
import { CreativeEdgeFilters } from "./CreativeEdgeFilterBar";

export type CEPillar =
  | CreativeEdgePillars.REMARKABLE
  | CreativeEdgePillars.REWARDING
  | CreativeEdgePillars.REMEMBERED;

type CompareCampaignsSideBySideProps = {
  readonly clientId: string;
  readonly campaignId: number;
  readonly height: number;
  readonly audienceFilters: CreativeEdgeFilters;
};

/**
 * A side-by-side comparison of two campaigns for the Creative Edge compare tab
 *
 * @param props - the component props
 * @returns the side-by-side comparison of two campaigns component
 */
export const CompareCampaignsSideBySide: React.FC<
  CompareCampaignsSideBySideProps
> = (props: CompareCampaignsSideBySideProps) => {
  const { clientId, campaignId, height, audienceFilters } = props;

  const [pillarDropdownStates, setPillarDropdownStates] = useState<
    Record<CEPillar, boolean>
  >({
    [CreativeEdgePillars.REMARKABLE]: false,
    [CreativeEdgePillars.REWARDING]: false,
    [CreativeEdgePillars.REMEMBERED]: false,
  });

  const togglePillarDropdown = (pillar: CEPillar) => {
    setPillarDropdownStates({
      ...pillarDropdownStates,
      [pillar]: !pillarDropdownStates[pillar],
    });
  };

  return (
    <div
      className="flex flex-row overflow-y-auto"
      style={{
        height,
      }}
    >
      <div className="ml-8">
        <Suspense
          fallback={
            <div className="h-full mr-8" style={{ width: COLUMN_WIDTH }}>
              <SmallLoader />
            </div>
          }
        >
          <CampaignSnapshotColumn
            clientId={clientId}
            initialCampaignId={campaignId}
            audienceFilters={audienceFilters}
            pillarDropdownStates={pillarDropdownStates}
            togglePillarDropdown={togglePillarDropdown}
          />
        </Suspense>
      </div>
      <div>
        <Suspense
          fallback={
            <div className="h-full mr-8" style={{ width: COLUMN_WIDTH }}>
              <SmallLoader />
            </div>
          }
        >
          <CampaignSnapshotColumn
            clientId={clientId}
            audienceFilters={audienceFilters}
            pillarDropdownStates={pillarDropdownStates}
            togglePillarDropdown={togglePillarDropdown}
          />
        </Suspense>
      </div>
    </div>
  );
};

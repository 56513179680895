export type NullableSortFn<T> = (
  a: T | null | undefined,
  b: T | null | undefined,
) => number;

export type NullableStringSortFn = NullableSortFn<string>;

/**
 * Sorts an array of strings alphabetically
 *
 * @param a the first string
 * @param b the second string
 * @returns a number indicating the order of the strings
 */
export const alphabeticalSort: NullableStringSortFn = (
  a: string | null | undefined,
  b: string | null | undefined,
): number => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return a.localeCompare(b);
};

/**
 * Sorts an array of strings in descending order
 *
 * @param a the first string
 * @param b the second string
 * @returns a number indicating the order of the strings
 */
export const reverseAlphabeticalSort: NullableStringSortFn = (
  a: string | null | undefined,
  b: string | null | undefined,
): number => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return b.localeCompare(a);
};

/**
 * Sorts an array of numbers in ascending order
 *
 * @param a the first number
 * @param b the second number
 * @returns a number indicating the order of the numbers
 */
export const ascendingNumberSort: NullableSortFn<number> = (
  a: number | null | undefined,
  b: number | null | undefined,
): number => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return a - b;
};

/**
 * Sorts an array of numbers in descending order
 *
 * @param a the first number
 * @param b the second number
 * @returns a number indicating the order of the numbers
 */
export const descendingNumberSort: NullableSortFn<number> = (
  a: number | null | undefined,
  b: number | null | undefined,
): number => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return b - a;
};

/**
 * Sorts an array of strings by the order of the reference array
 *
 * Sort is case insensitive
 *
 * @param order the reference array to sort by
 * @returns a function that sorts an array of strings by the order of the reference array
 */
export const sortByOrderThenAlphabeticalFn =
  (order: string[] = []): NullableStringSortFn =>
  (a: string | null | undefined, b: string | null | undefined): number => {
    const lowerCaseOrder = order.map((item) => item.toLowerCase());
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    const aIndex = lowerCaseOrder.indexOf(a.toLowerCase());
    const bIndex = lowerCaseOrder.indexOf(b.toLowerCase());
    if (aIndex === -1 && bIndex === -1) {
      return a.localeCompare(b);
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  };

/**
 * Sorts an array of strings by the order of the reference array
 * For sorting audience groups in dashboard filter dropdowns
 *
 * @param a the first string
 * @param b the second string
 * @returns a number indicating the order of the strings
 */
export const audienceGroupSortFn: NullableStringSortFn = (
  a: string | null | undefined,
  b: string | null | undefined,
): number => {
  const referenceOrder = ["total", "gender"];
  return sortByOrderThenAlphabeticalFn(referenceOrder)(a, b);
};

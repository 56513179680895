import { useElementSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import { isNumber } from "lodash";
import { Suspense, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { colours } from "../../util/colours";
import { offsetWaveDate } from "../../util/dataProcessing";
import {
  getSentimentFromPercentage,
  npsSentimentToHexCode,
} from "../../util/npsSentiment";
import { CirclePackChart } from "../CirclePackChart";
import { LoadingSpinner } from "../LoadingSpinner";
import { UnpromptedMessageSummaryBox } from "./UnpromptedMessageSummaryBox";
import { CodedUnpromptedMessagesBreakdownQuery as CodedUnpromptedMessagesBreakdownQueryType } from "./__generated__/CodedUnpromptedMessagesBreakdownQuery.graphql";
import { VisualisationProps } from "./types";

const CodedUnpromptedMessagesBreakdownQuery = graphql`
  query CodedUnpromptedMessagesBreakdownQuery(
    $clientId: String!
    $roll: Int
    $topicMetric: String!
    $sentimentMetric: String
    $category: String
    $firstAudience: String
    $secondAudience: String
    $subject: String
    $statementGroup: String
  ) {
    proportion: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $topicMetric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $subject
        STATEMENT_GROUP: $statementGroup
      }
    ) {
      STATEMENT
      IS_SCORE
      PERCENTAGE
      WAVE_DATE
    }
    sentiment: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $sentimentMetric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $subject
        STATEMENT_GROUP: $statementGroup
      }
    ) {
      STATEMENT
      IS_SCORE
      PERCENTAGE
      WAVE_DATE
    }
  }
`;

type BubbleConfig = {
  readonly name: string;
  readonly percentage: number;
  readonly colour: string;
};

const CodedUnpromptedMessagesBreakdownOptionsSchema = z.object({
  sentimentMetric: z.string().optional(),
  questionNum: z.string(),
  showSentiment: z.array(z.string()).optional(),
  statementGroup: z.string().nullable().optional(),
  waveDate: z.string().optional(),
  subject: z.string().optional(),
});

/**
 * A visualisation component that displays the percentage of unprompted messages that are attributed to a specific code.
 * As well as a summary of the comments and a list of the actual comments.
 *
 * @param props The props for the component.
 * @returns The CodedUnpromptedMessagesBreakdown component.
 */
export const CodedUnpromptedMessagesBreakdown: React.FC<VisualisationProps> = (
  props: VisualisationProps,
) => {
  const { height, dashBoardFilters, options, metric: topicMetric = "" } = props;

  const parsedOptions =
    CodedUnpromptedMessagesBreakdownOptionsSchema.parse(options);

  const {
    sentimentMetric,
    questionNum,
    showSentiment,
    statementGroup,
    waveDate,
    subject,
  } = parsedOptions;

  const { ref, width } = useElementSize();
  const [zoomedId, setZoomedId] = useState<string>();

  const queryData = useLazyLoadQuery<CodedUnpromptedMessagesBreakdownQueryType>(
    CodedUnpromptedMessagesBreakdownQuery,
    {
      ...dashBoardFilters,
      topicMetric,
      sentimentMetric,
      subject,
      statementGroup,
    },
  );

  const bubbleData: ReadonlyArray<BubbleConfig> | null = useMemo(() => {
    if (!queryData.proportion) {
      return null;
    }
    const dataForWaveDate = waveDate
      ? queryData.proportion.filter(
          (dataPoint) => dataPoint?.WAVE_DATE === waveDate,
        )
      : queryData.proportion;
    if (dataForWaveDate.length === 0) {
      return [];
    }

    return (
      dataForWaveDate
        .map((dataPoint) => {
          const sentimentDataPoint = queryData.sentiment?.find(
            (sentimentDataPoint) =>
              sentimentDataPoint?.STATEMENT === dataPoint?.STATEMENT &&
              sentimentDataPoint?.WAVE_DATE === waveDate,
          );
          const sentimentPercentage = sentimentDataPoint?.PERCENTAGE;
          const sentiment =
            showSentiment !== undefined && isNumber(sentimentPercentage)
              ? getSentimentFromPercentage(sentimentPercentage)
              : undefined;
          if (
            showSentiment &&
            sentiment &&
            showSentiment.includes(sentiment) === false
          ) {
            return null;
          }
          const bubbleColour = sentiment
            ? npsSentimentToHexCode(sentiment)
            : colours.primaryBrand.pink;
          return {
            name: dataPoint?.STATEMENT || "",
            percentage: Math.round((dataPoint?.PERCENTAGE || 0) * 100),
            colour: bubbleColour,
          };
        })
        .filter(
          (bubbleConfig): bubbleConfig is BubbleConfig => bubbleConfig !== null,
        ) || []
    );
  }, [queryData.proportion, queryData.sentiment, showSentiment, waveDate]);

  const selectedCodes = useMemo(() => {
    const selectedCodeDataPoint = bubbleData?.find(
      (dataPoint) => dataPoint.name === zoomedId,
    );
    if (selectedCodeDataPoint) {
      return [
        {
          code: selectedCodeDataPoint?.name,
          value: selectedCodeDataPoint?.percentage,
        },
      ];
    }
    return bubbleData?.map((dataPoint) => ({
      code: dataPoint.name,
      value: dataPoint.percentage,
    }));
  }, [bubbleData, zoomedId]);

  const fromWave = useMemo(() => {
    if (!waveDate) {
      return;
    }
    const offsetBy = (dashBoardFilters.roll || 0) - 1;
    return offsetWaveDate(waveDate, offsetBy);
  }, [dashBoardFilters.roll, waveDate]);

  return (
    <div
      ref={ref}
      className="relative w-full h-full"
      style={{
        height,
      }}
    >
      {bubbleData === null || bubbleData.length === 0 ? (
        <div className="flex items-center justify-center w-2/3 h-full">
          No comments for this selection
        </div>
      ) : (
        <CirclePackChart
          data={bubbleData}
          zoomState={{ zoomedId, setZoomedId }}
          margin={{ right: width / 3, top: 4, bottom: 4 }}
          height={height}
        />
      )}
      <div
        className="absolute top-0 right-0"
        style={{
          height,
          width: width / 3,
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <UnpromptedMessageSummaryBox
            clientId={dashBoardFilters.clientId}
            questionNum={questionNum}
            category={dashBoardFilters.category || undefined}
            subject={subject}
            fromWave={fromWave}
            toWave={waveDate}
            commentGroup={statementGroup}
            selectedCodes={selectedCodes}
          />
        </Suspense>
      </div>
    </div>
  );
};

import { TextInput, TextInputProps } from "@mantine/core";
import { isString } from "lodash";
import { useState } from "react";

import classes from "./FloatingLabelTextInput.module.css";

/**
 * A text input component with a dynamic floating label
 * @param props the text input properties
 * @returns a floating label text input component
 */
export const FloatingLabelTextInput: React.FC<TextInputProps> = (
  props: TextInputProps,
) => {
  const { value, onChange } = props;

  const [internalValue, setInternalValue] = useState(value);
  const [focused, setFocused] = useState(false);
  const floating =
    focused ||
    (isString(internalValue) && internalValue.length > 0) ||
    undefined;

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value);
    onChange && onChange(event);
  };

  const passedProps: TextInputProps = {
    ...props,
    value: internalValue,
    onChange: updateValue,
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
    labelProps: { "data-floating": floating },
    classNames: {
      root: classes.root,
      input: classes.input,
      label: classes.label,
    },
  };

  return <TextInput {...passedProps} />;
};

/* eslint-disable sonarjs/cognitive-complexity */
import { FontFamily } from "../../util/fontFamily";
import {
  HorizontalPercentageBar,
  HorizontalPercentageBarProps,
} from "./HorizontalPercentageBar";

type LabeledHorizontalPercentageBarProps = {
  readonly title?: string;
  readonly boldTitle?: boolean;
} & HorizontalPercentageBarProps;

export const LabeledHorizontalPercentageBar: React.FC<
  LabeledHorizontalPercentageBarProps
> = (props: LabeledHorizontalPercentageBarProps) => {
  const { title, boldTitle = false, alignment } = props;

  return (
    <div
      className="flex w-full h-full"
      style={{
        ...(alignment === "right" && {
          flexDirection: "row-reverse",
        }),
      }}
    >
      <div
        className="flex items-center mx-8 text-base text-nowrap"
        style={{
          fontFamily: boldTitle
            ? FontFamily.PPNeueMontrealBold
            : FontFamily.PPNeueMontrealRegular,
          display: alignment === "center" && !title ? "none" : undefined,
          width: alignment !== "center" ? "30%" : title ? "30%" : "0",
        }}
      >
        {title}
      </div>
      <div
        className="flex"
        style={{
          width: alignment !== "center" ? "70%" : title ? "70%" : "100%",
        }}
      >
        <HorizontalPercentageBar {...props} />
      </div>
    </div>
  );
};

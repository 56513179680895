import { notifications } from "@mantine/notifications";
import * as htmlToImage from "html-to-image";
import { read, writeFile } from "xlsx";

import { CollatedData, splitByField } from "./dataProcessing";
import { JsonObject } from "./json";

/**
 * Downloads the given data as a JSON file with the given filename
 *
 * @param data any JSON data
 * @param fileName the name of the file to download
 */
export const downloadJson = (data: JsonObject, fileName: string) => {
  try {
    // create file in browser
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTML element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (err) {
    notifications.show({
      title: "Failed",
      message: "Failed to download file",
      color: "red",
    });
  }
};

/**
 * Generates a formatted table from the given data
 *
 * @param data the data to be formatted into a table
 * @returns a formatted table
 */
export const genFormattedTable = (
  data: CollatedData,
): ReadonlyArray<ReadonlyArray<string>> => {
  if (!data || data.length === 0 || !data[0]) {
    return [];
  }

  const brands = Array.from(new Set(data.map((row) => row?.BRAND))).filter(
    (brand): brand is string => !!brand,
  );
  const waveDates = Array.from(new Set(data.map((row) => row?.WAVE_DATE)))
    .filter((date): date is string => !!date)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const dataByWaveDate = splitByField(data, "WAVE_DATE");

  const header = ["Date", ...brands, "Average Base Size"];

  const rows = waveDates.map((waveDate) => {
    const dataForWaveDate = dataByWaveDate[waveDate];
    if (!dataForWaveDate) {
      return [];
    }

    const rowData = brands.map((brand) => {
      const dataForBrand = dataForWaveDate.find((row) => row?.BRAND === brand);
      if (!dataForBrand || !dataForBrand.PERCENTAGE) {
        return "";
      }
      const valueAsPercentage = parseFloat(
        (dataForBrand.PERCENTAGE * 100).toPrecision(5),
      );
      const value = dataForBrand.IS_SCORE
        ? dataForBrand.PERCENTAGE
        : `${valueAsPercentage}%`;
      return value?.toString() || "";
    });

    const exactBaseSize =
      dataForWaveDate.reduce((acc, curr) => acc + (curr?.BASE || 0), 0) /
      dataForWaveDate.length;
    const baseSize = Math.round(exactBaseSize);

    const formattedWaveDate = new Date(waveDate).toLocaleString("en-GB", {
      month: "short",
      year: "numeric",
    });

    return [formattedWaveDate, ...rowData, baseSize.toString()];
  });

  return [header, ...rows];
};

/**
 * Downloads the given data as a CSV file with the given filename
 *
 * @param options the data, filename, and an optional footnote to be included in the CSV
 * @returns
 */
export const genAllTablesInCsvFormat = (options: {
  readonly data: CollatedData;
  readonly subtitle?: string;
}): string | null => {
  const { data, subtitle } = options;
  if (!data || data.length === 0 || !data[0]) {
    return null;
  }

  const dataByStatement = splitByField(data, "STATEMENT");

  const tables = Object.entries(dataByStatement).map(
    ([statement, statementData]) => {
      const formattedTable = genFormattedTable(statementData);
      const table = formattedTable.map((row) => row.join(",")).join("\n");
      const subtitleText = subtitle ? `${subtitle},\n` : "";
      return `${statement},\n${subtitleText}${table}\n`;
    },
  );

  return tables.join("\n");
};

/**
 * Downloads the given text as a CSV file with the given filename
 *
 * @param data the data to download
 * @param filename the name of the file to download
 * @returns
 */
export const downloadCsv = (data: string, filename: string): void => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};

/**
 * Downloads the given data as an XLSX file with the given filename
 *
 * @param data data in CSV string format to be downloaded
 * @param filename the name of the file to download
 */
export const downloadXlsx = (data: string, filename: string): void => {
  const workbook = read(data, { type: "string", raw: true });
  writeFile(workbook, `${filename}.xlsx`);
};

/**
 * Get the height increase needed to max out the scrolls of the given element so that all content is visible
 *
 * @param element the element to calculate the height increase for
 * @returns the height increase needed to max out the scrolls in pixels
 */
export const getHeightIncreaseToMaxScrolls = (element: HTMLElement): number => {
  const scrollableElements = element.querySelectorAll(".overflow-y-auto");
  const scrollableElementHeights = Array.from(scrollableElements).map(
    (scrollableElement) => {
      return scrollableElement.scrollHeight - scrollableElement.clientHeight;
    },
  );
  return Math.max(...scrollableElementHeights);
};

/**
 * Downloads the given component as a PNG file with the given filename
 *
 * @param component the component to download as a PNG
 * @param filename the name of the file to download
 */
export const downloadPng = async (
  component: HTMLElement,
  filename: string,
): Promise<void> => {
  const image = await htmlToImage.toPng(component as HTMLElement, {
    backgroundColor: "white",
  });
  const link = document.createElement("a");
  link.href = image;
  link.download = `${filename}.png`;
  link.click();
};

/**
 * Copies the given component as a PNG to the clipboard
 *
 * @param component the component to copy as a PNG
 */
export const copyPngToClipboard = async (
  component: HTMLElement,
): Promise<void> => {
  const image = await htmlToImage.toBlob(component as HTMLElement, {
    backgroundColor: "white",
  });
  if (!image) {
    return;
  }
  // copy image to clipboard
  navigator.clipboard.write([
    new ClipboardItem({
      ["image/png"]: image,
    }),
  ]);
  notifications.show({
    title: "Copied",
    message: "Image copied to clipboard",
    color: "teal",
  });
};

/**
 * Downloads the given component as a SVG file with the given filename
 *
 * @param component the component to download as a SVG
 * @param filename the name of the file to download
 */
export const downloadSvg = async (
  component: HTMLElement,
  filename: string,
): Promise<void> => {
  const image = await htmlToImage.toSvg(component as HTMLElement, {
    backgroundColor: "white",
  });
  const link = document.createElement("a");
  link.href = image;
  link.download = `${filename}.svg`;
  link.click();
};

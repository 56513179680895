import { Image, Text } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import leftTriangle from "../../assets/brandEdgeTriangle/leftTriangle.svg";
import rightTriangle from "../../assets/brandEdgeTriangle/rightTriangle.svg";
import topTriangle from "../../assets/brandEdgeTriangle/topTriangle.svg";
import { ChangeSince, changeSinceToMonths } from "../../util/changeSince";
import {
  CollatedData,
  getDataForWaveDate,
  getEarliestWaveDate,
  getLatestWaveDate,
  offsetWaveDate,
  roundValueForDisplay,
} from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { ChangeIndicatorPill } from "../ChangeIndicatorPill";
import { BrandEdgeTriangleQuery as BrandEdgeTriangleQueryType } from "./__generated__/BrandEdgeTriangleQuery.graphql";
import { VisualisationProps } from "./types";

const BrandEdgeTriangleQuery = graphql`
  query BrandEdgeTriangleQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $category: String
    $roll: Int
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: "edge"
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

type BrandEdgeSnapshot = {
  readonly totalScore: number;
  readonly fit: number;
  readonly purpose: number;
  readonly energy: number;
};

type BrandEdgeTriangleProps = {
  readonly brand: string;
  readonly changeSince: ChangeSince;
  readonly updateData: (data: CollatedData) => void;
} & Pick<VisualisationProps, "dashBoardFilters" | "height">;

export const BrandEdgeTriangle: React.FC<BrandEdgeTriangleProps> = (
  props: BrandEdgeTriangleProps,
) => {
  const { brand, changeSince, dashBoardFilters, height, updateData } = props;

  const [latestWaveStats, setLatestWaveStats] = useState<BrandEdgeSnapshot>();
  const [comparisonWaveStats, setComparisonWaveStats] =
    useState<BrandEdgeSnapshot>();

  const queryData = useLazyLoadQuery<BrandEdgeTriangleQueryType>(
    BrandEdgeTriangleQuery,
    {
      brand,
      ...dashBoardFilters,
    },
  );

  useEffect(() => {
    const latestWaveDate = getLatestWaveDate(queryData.chartData, true);
    if (!latestWaveDate) {
      return;
    }

    const getStatsFromData = (
      data: BrandEdgeTriangleQueryType["response"]["chartData"],
    ) => {
      if (!data) {
        return;
      }
      return {
        totalScore:
          data.find((data) => data?.STATEMENT === "Brand Edge")?.PERCENTAGE ||
          0,
        fit: data.find((data) => data?.STATEMENT === "Fit")?.PERCENTAGE || 0,
        purpose:
          data.find((data) => data?.STATEMENT === "Purpose")?.PERCENTAGE || 0,
        energy:
          data.find((data) => data?.STATEMENT === "Energy")?.PERCENTAGE || 0,
      };
    };

    const latestWaveData = getDataForWaveDate(
      queryData.chartData,
      latestWaveDate,
    );
    updateData(latestWaveData);
    const newLatestWaveStats = getStatsFromData(latestWaveData);
    if (newLatestWaveStats) {
      setLatestWaveStats(newLatestWaveStats);
    }

    const getWaveToCompare = (changeSince: ChangeSince) => {
      const offsetByMonths = changeSinceToMonths[changeSince];
      if (offsetByMonths === 0) {
        return getEarliestWaveDate(queryData.chartData);
      }
      return offsetWaveDate(latestWaveDate, offsetByMonths);
    };
    const waveToCompare = getWaveToCompare(changeSince);
    const comparisonWaveData = getDataForWaveDate(
      queryData.chartData,
      waveToCompare || latestWaveDate,
    );
    const newComparisonWaveStats = getStatsFromData(comparisonWaveData);
    setComparisonWaveStats(newComparisonWaveStats);
  }, [changeSince, queryData.chartData, updateData]);

  const getMetricComponent = (metric: keyof BrandEdgeSnapshot) => {
    if (!latestWaveStats || !comparisonWaveStats) {
      return null;
    }

    const latestMetric = latestWaveStats[metric];
    const comparisonMetric = comparisonWaveStats[metric];
    const change = roundValueForDisplay(latestMetric - comparisonMetric);

    return (
      <div className="flex flex-col items-start content-start">
        <Text ff={FontFamily.PPNeueMontrealRegular} size={"34px"} mb={4}>
          {roundValueForDisplay(latestMetric)}
        </Text>
        <Text ff={FontFamily.PPNeueMontrealRegular} size={"20px"} mb={4}>
          {metric}
        </Text>
        <ChangeIndicatorPill
          value={change}
          isSignificant={Math.abs(change) >= 1}
          isScore
        />
      </div>
    );
  };

  return (
    <div className="relative flex items-center" style={{ height }}>
      <div
        className="absolute w-full"
        style={{
          top: Math.max(0, (height - 480) / 2),
          height: 470,
        }}
      >
        {latestWaveStats?.totalScore && (
          <div
            className="absolute"
            style={{
              top: "260px",
              left: "calc(50% - 40px)",
            }}
          >
            <div className="flex flex-col items-start content-start">
              <Text ff={FontFamily.PPNeueMontrealBold} size={"88px"} mb={8}>
                {roundValueForDisplay(latestWaveStats.totalScore)}
              </Text>
              <ChangeIndicatorPill
                value={roundValueForDisplay(
                  latestWaveStats.totalScore -
                    (comparisonWaveStats?.totalScore || 0),
                )}
                isSignificant={
                  Math.abs(
                    latestWaveStats.totalScore -
                      (comparisonWaveStats?.totalScore || 0),
                  ) > 0.3
                }
                isScore
              />
            </div>
          </div>
        )}
        <div
          className="absolute"
          style={{ top: "0px", left: "calc(50% - 20px)" }}
        >
          {getMetricComponent("fit")}
        </div>
        <div
          className="absolute"
          style={{ top: "345px", left: "calc(50% - 260px)" }}
        >
          {getMetricComponent("purpose")}
        </div>
        <div
          className="absolute"
          style={{ top: "327px", left: "calc(50% + 230px)" }}
        >
          {getMetricComponent("energy")}
        </div>
        <Image
          className="absolute"
          style={{
            top: "100px",
            left: "calc(50% - 100px)",
          }}
          src={topTriangle}
          w="200px"
          aria-label="top triangle"
        />
        <Image
          className="absolute"
          style={{
            top: "260px",
            left: "calc(50% - 170px)",
          }}
          src={leftTriangle}
          w="200px"
          aria-label="left triangle"
        />
        <Image
          className="absolute"
          style={{
            top: "242px",
            left: "calc(50% + 4px)",
          }}
          src={rightTriangle}
          w="200px"
          aria-label="right triangle"
        />
      </div>
    </div>
  );
};

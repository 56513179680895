import { Divider, Text } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { CreativeEdgeMetrics } from "../../util/creativeEdge";
import { FontFamily } from "../../util/fontFamily";
import { CreativeEdgeFilters } from "./CreativeEdgeFilterBar";
import { CampaignPillarBreakdownQuery as CampaignPillarBreakdownQueryType } from "./__generated__/CampaignPillarBreakdownQuery.graphql";

type CampaignPillarBreakdownProps = {
  readonly clientId: string;
  readonly campaignId: number;
  readonly pillar: string;
  readonly audienceFilters: CreativeEdgeFilters;
  readonly compact?: boolean;
};

const CampaignPillarBreakdownQuery = graphql`
  query CampaignPillarBreakdownQuery(
    $clientId: String!
    $campaignId: Int!
    $pillar: String!
    $metric: String!
    $audience: String!
    $secondaryAudience: String
  ) {
    campaign: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        ROLL: $campaignId
        STATEMENT_GROUP: $pillar
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
        IS_SCORE: 0
      }
    ) {
      QUESTION_TEXT
      STATEMENT
      PERCENTAGE
      SORT_ORDER
    }
  }
`;

/**
 * Campaign Pillar Breakdown component
 * Contains the questions and percentage breakdown for each answer
 * for a specific pillar
 *
 * @param props the options to generate the pillar breakdown
 * @returns the pillar breakdown component
 */
export const CampaignPillarBreakdown: React.FC<CampaignPillarBreakdownProps> = (
  props: CampaignPillarBreakdownProps,
) => {
  const {
    clientId,
    campaignId,
    pillar,
    audienceFilters,
    compact = false,
  } = props;

  const queryData = useLazyLoadQuery<CampaignPillarBreakdownQueryType>(
    CampaignPillarBreakdownQuery,
    {
      clientId: clientId,
      campaignId: campaignId,
      pillar: pillar,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...audienceFilters,
    },
  );

  const questions = useMemo(() => {
    if (!queryData.campaign) {
      return {};
    }
    return queryData.campaign.reduce(
      (acc, data) => {
        if (
          !data ||
          !data.QUESTION_TEXT ||
          !data.STATEMENT ||
          !data.PERCENTAGE
        ) {
          return acc;
        }
        const questionText = data.QUESTION_TEXT;
        if (!acc[questionText]) {
          acc[data.QUESTION_TEXT] = [];
        }

        acc[data.QUESTION_TEXT].push({
          statement: data.STATEMENT,
          percentage: Math.round(data.PERCENTAGE * 100),
          index: data.SORT_ORDER || 99,
        });

        return acc;
      },
      {} as Record<
        string,
        {
          statement: string;
          percentage: number;
          index: number;
        }[]
      >,
    );
  }, [queryData.campaign]);

  return (
    <div className="w-full">
      {Object.entries(questions).map(([question, answers], questionIndex) => (
        <div key={questionIndex} className="pb-6">
          <Divider />
          <Text ff={FontFamily.PPNeueMontrealMedium} size={"12px"} py={"xs"}>
            {question}
          </Text>
          <Divider />
          {answers
            .sort((a, b) => a.index - b.index)
            .map((answer, answerIndex) => (
              <div key={answerIndex}>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    ff={FontFamily.PPNeueMontrealBook}
                    size={compact ? "14px" : "16px"}
                    py={"xs"}
                    pr={"lg"}
                    w={"100%"}
                  >
                    {answer.statement}
                  </Text>
                  <Text
                    ff={FontFamily.PPNeueMontrealMedium}
                    size={compact ? "22px" : "24px"}
                    py={"xs"}
                    w={compact ? 60 : 70}
                  >
                    {answer.percentage}%
                  </Text>
                </div>
                <Divider />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

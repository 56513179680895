import { CollatedData, roundValueForDisplay } from "./dataProcessing";

export const getBaseSizeFromCollatedData = (
  data: CollatedData,
): string | null => {
  const goodData = data?.filter((item) => !!item);
  if (!goodData || goodData.length === 0) {
    return null;
  }

  const firstBaseSize = goodData[0]?.BASE;
  if (firstBaseSize && goodData.every((item) => item?.BASE === firstBaseSize)) {
    return `Base size: ${firstBaseSize}`;
  } else {
    const validBaseSizes = goodData.filter((item) => item?.BASE);
    if (validBaseSizes.length === 0) {
      return null;
    }
    try {
      const averageBaseSize =
        validBaseSizes.reduce((acc, item) => {
          const thisBase = item?.BASE;
          if (!thisBase) {
            // shouldn't get here as we've already filtered out items without a base size
            throw new Error("Base size is missing");
          }
          return acc + item.BASE;
        }, 0) / goodData.length;
      const roundedAverageBaseSize = roundValueForDisplay(averageBaseSize);
      return `Average base size: ${roundedAverageBaseSize}`;
    } catch (e) {
      return null;
    }
  }
};

/**
 * @generated SignedSource<<009104e7a6bf7adbca1cd5829b9777d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PercentageStackQuery$variables = {
  brand?: string | null | undefined;
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
};
export type PercentageStackQuery$data = {
  readonly chartData: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type PercentageStackQuery = {
  response: PercentageStackQuery$data;
  variables: PercentageStackQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brand"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v7 = [
  {
    "alias": "chartData",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "firstAudience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondAudience"
          },
          {
            "kind": "Variable",
            "name": "BRAND",
            "variableName": "brand"
          },
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          },
          {
            "kind": "Variable",
            "name": "CATEGORY",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "ROLL",
            "variableName": "roll"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PercentageStackQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "PercentageStackQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "80ef269799f05e294e49957baffc5790",
    "id": null,
    "metadata": {},
    "name": "PercentageStackQuery",
    "operationKind": "query",
    "text": "query PercentageStackQuery(\n  $clientId: String!\n  $firstAudience: String\n  $secondAudience: String\n  $brand: String\n  $metric: String!\n  $category: String\n  $roll: Int\n) {\n  chartData: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $brand, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}) {\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d9a2072ed1f4f9c2ac46e01a472a9e0";

export default node;

import { HoverCard, Text } from "@mantine/core";

import { roundValueForDisplay } from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";

export type ScatterLineDataPoint = {
  readonly label: string;
  readonly value: number;
  readonly colour: string;
  readonly isScore: boolean;
  readonly shouldHighlight?: boolean;
  readonly enabled?: boolean;
};

export type ScatterLineData = ReadonlyArray<ScatterLineDataPoint>;

type SingleScatterLineProps = {
  readonly label: string;
  readonly points: ScatterLineData;
  readonly scaleMaxValue: number;
};

export const SingleScatterLine: React.FC<SingleScatterLineProps> = (
  props: SingleScatterLineProps,
) => {
  const { label, points, scaleMaxValue } = props;

  return (
    <div className="flex flex-row items-center w-full p-6 min-h-[100px]">
      <Text
        w={300}
        miw={300}
        maw={300}
        size={"16px"}
        ff={FontFamily.PPNeueMontrealMedium}
      >
        {label}
      </Text>
      <div className="flex flex-row w-[70%] relative">
        <div
          className="absolute h-[1px] w-full bg-black"
          style={{
            top: "50%",
          }}
        />
        {points.map((point) => {
          if (point.enabled === false) {
            return null;
          }
          const formattedData = point.isScore
            ? roundValueForDisplay(point.value)
            : `${roundValueForDisplay(point.value * 100)}%`;
          const scaledValue = point.value / scaleMaxValue;
          return (
            <div
              key={`scatter-line-${point.label}`}
              className="absolute flex items-center justify-center h-full"
              style={{
                zIndex: point.shouldHighlight ? 1 : 0,
                left: scaledValue * 100 + "%",
              }}
            >
              <div className="flex flex-col">
                <HoverCard position="top" withArrow>
                  <HoverCard.Target>
                    <div
                      className="relative flex items-center justify-center w-6 h-6 rounded-full"
                      style={{
                        backgroundColor: point.colour,
                        height: point.shouldHighlight ? "2rem" : "1.5rem",
                        width: point.shouldHighlight ? "2rem" : "1.5rem",
                      }}
                    >
                      {point.shouldHighlight && (
                        <Text
                          bg={"#ffffffCC"}
                          ff={FontFamily.PPNeueMontrealBold}
                          size={"18px"}
                          style={{
                            position: "absolute",
                            top: "-80%",
                          }}
                        >
                          {formattedData}
                        </Text>
                      )}
                    </div>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <div className="flex flex-row items-center justify-center">
                      <div
                        className="w-3 h-3 mr-2 rounded-full"
                        style={{ backgroundColor: point.colour }}
                      />
                      <Text>{`${point.label}: ${formattedData}`}</Text>
                    </div>
                  </HoverCard.Dropdown>
                </HoverCard>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * @generated SignedSource<<ba0bf387cf5fde6260da8f9e066003cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KpiSummaryQuery$variables = {
  category: string;
  clientId: string;
  roll?: number | null | undefined;
};
export type KpiSummaryQuery$data = {
  readonly availableWaveDates: ReadonlyArray<{
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly dashboardFilterOptions: ReadonlyArray<{
    readonly AUDIENCE1: string | null | undefined;
    readonly AUDIENCE2: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type KpiSummaryQuery = {
  response: KpiSummaryQuery$data;
  variables: KpiSummaryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v3 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "CATEGORY",
      "variableName": "category"
    },
    {
      "kind": "Variable",
      "name": "ROLL",
      "variableName": "roll"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v5 = [
  {
    "alias": "dashboardFilterOptions",
    "args": [
      (v3/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "AUDIENCE1",
          "AUDIENCE_GROUP1",
          "AUDIENCE2",
          "AUDIENCE_GROUP2",
          "ROLL"
        ]
      },
      (v4/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AUDIENCE2",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "availableWaveDates",
    "args": [
      (v3/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "WAVE_DATE"
        ]
      },
      (v4/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "KpiSummaryQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "KpiSummaryQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "554e34511899ad421dbb4d35779dc2f4",
    "id": null,
    "metadata": {},
    "name": "KpiSummaryQuery",
    "operationKind": "query",
    "text": "query KpiSummaryQuery(\n  $clientId: String!\n  $category: String!\n  $roll: Int\n) {\n  dashboardFilterOptions: collatedData(clientId: $clientId, filters: {CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"AUDIENCE1\", \"AUDIENCE_GROUP1\", \"AUDIENCE2\", \"AUDIENCE_GROUP2\", \"ROLL\"]) {\n    AUDIENCE1\n    AUDIENCE2\n  }\n  availableWaveDates: collatedData(clientId: $clientId, filters: {CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"WAVE_DATE\"]) {\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f503af5cabc161a7a7d0d92c12a946e";

export default node;

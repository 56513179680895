import { Divider, Text, UnstyledButton, rem } from "@mantine/core";
import { KpiSummaryConfig } from "@tra-nz/platform-common";
import graphql from "babel-plugin-relay/macro";
import { isString } from "lodash";
import { Suspense, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useLazyLoadQuery } from "react-relay";

import { useUser } from "../../context/UserContext";
import { FontFamily } from "../../util/fontFamily";
import { SingleKpi } from "./SingleKpi";
import { KpiSummaryQuery as KpiSummaryQueryType } from "./__generated__/KpiSummaryQuery.graphql";
import { SingleKpiQuery } from "./__generated__/SingleKpiQuery.graphql";

const KpiSummaryQuery = graphql`
  query KpiSummaryQuery($clientId: String!, $category: String!, $roll: Int) {
    dashboardFilterOptions: collatedData(
      clientId: $clientId
      filters: { CATEGORY: $category, ROLL: $roll }
      distinctSelect: [
        "AUDIENCE1"
        "AUDIENCE_GROUP1"
        "AUDIENCE2"
        "AUDIENCE_GROUP2"
        "ROLL"
      ]
    ) {
      AUDIENCE1
      AUDIENCE2
    }
    availableWaveDates: collatedData(
      clientId: $clientId
      filters: { CATEGORY: $category, ROLL: $roll }
      distinctSelect: ["WAVE_DATE"]
    ) {
      WAVE_DATE
    }
  }
`;

type KpiSummaryProps = {
  readonly config: KpiSummaryConfig;
};

export const KpiSummary: React.FC<KpiSummaryProps> = (
  props: KpiSummaryProps,
) => {
  const { config } = props;
  const { selectedClient } = useUser();
  const clientId = selectedClient?.id || "none";
  const {
    brand,
    category,
    kpiMetrics,
    label,
    metric,
    roll,
    statement,
    audience,
    secondaryAudience,
  } = config;
  const fontFamily = FontFamily.PPNeueMontrealMedium;
  const [inProgressWave, setInProgressWave] = useState<string>();
  const [completedWaves, setCompletedWaves] = useState<string[]>([]);
  const [defaultFilters, setDefaultFilters] =
    useState<SingleKpiQuery["variables"]>();
  const [subtitles, setSubtitles] = useState<string[]>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const queryData = useLazyLoadQuery<KpiSummaryQueryType>(KpiSummaryQuery, {
    clientId,
    category,
    roll,
  });

  useEffect(() => {
    if (!queryData.dashboardFilterOptions) {
      return;
    }
    const defaultFirstAudience = queryData.dashboardFilterOptions.find(
      (option) => option && option.AUDIENCE1?.toLowerCase() === "total",
    );
    const defaultSecondAudience = queryData.dashboardFilterOptions.find(
      (option) => option && option.AUDIENCE2?.toLowerCase() === "total",
    );
    setDefaultFilters({
      firstAudience: audience || defaultFirstAudience?.AUDIENCE1 || null,
      secondAudience:
        secondaryAudience || defaultSecondAudience?.AUDIENCE2 || null,
      metric,
      brand,
      category,
      clientId,
      roll,
      statement,
    });
  }, [
    audience,
    brand,
    category,
    clientId,
    metric,
    queryData.dashboardFilterOptions,
    roll,
    secondaryAudience,
    statement,
  ]);

  const dateToMonthLabel = (wave: string | Date) => {
    const date = new Date(wave);
    // if date is invalid, return an empty string
    if (isNaN(date.getTime())) {
      return "";
    }
    return date.toLocaleString("default", { month: "short" });
  };

  useEffect(() => {
    if (!queryData.availableWaveDates) {
      return;
    }
    const sortedWaveDates = queryData.availableWaveDates
      .map((waveDate) => waveDate?.WAVE_DATE)
      .filter(isString)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    const today = new Date();
    const inProgress = sortedWaveDates.find((waveDate) => {
      const waveAsDate = new Date(waveDate);
      return (
        waveAsDate.getMonth() === today.getMonth() &&
        waveAsDate.getFullYear() === today.getFullYear()
      );
    });
    if (inProgress) {
      setInProgressWave(inProgress);
    }
    const completedWaveDates = sortedWaveDates.filter(
      (waveDate) => new Date(waveDate).getMonth() !== today.getMonth(),
    );
    const lastThreeCompletedWaves = completedWaveDates.slice(0, 3);
    setCompletedWaves(lastThreeCompletedWaves);
  }, [queryData.availableWaveDates]);

  useEffect(() => {
    if (defaultFilters) {
      const rollText = `Roll: ${defaultFilters.roll} months`;
      const audienceText = `Audience: ${defaultFilters.firstAudience}${
        defaultFilters.secondAudience &&
        defaultFilters.firstAudience !== defaultFilters.secondAudience
          ? ` - ${defaultFilters.secondAudience}`
          : ""
      }`;
      const changeText = `Change: Last month`;
      setSubtitles([rollText, audienceText, changeText]);
    }
  }, [defaultFilters]);

  return (
    <div
      className="flex flex-col p-3 rounded-lg"
      style={{
        background: "rgba(247, 247, 247, 0.50)",
      }}
    >
      <div className="flex flex-row justify-between h-full pb-4 justify-items-start">
        <div className="flex flex-col h-full">
          <Text ff={fontFamily} size={"18px"} pb={"6px"}>
            {label}
          </Text>
          <div className="flex">
            {subtitles?.map((subtitle, index) => (
              <div key={index} className="flex">
                <Text
                  key={index}
                  ff={fontFamily}
                  size={"12px"}
                  opacity={0.4}
                  pr={8}
                >
                  {subtitle}
                </Text>
                {index !== subtitles.length - 1 && (
                  <Divider orientation={"vertical"} className="pr-[8px]" />
                )}
              </div>
            ))}
          </div>
        </div>
        {expanded ? (
          <UnstyledButton onClick={() => setExpanded(false)}>
            <div className="flex h-full gap-1">
              <Text ff={fontFamily} size={"14px"} c={"#828282"}>
                Hide last 3 months
              </Text>
              <div className="flex h-[20px] p-1 rotate-45 rounded-full bg-[#F2F2F2]">
                <FaPlus style={{ width: rem(12), height: rem(12) }} />
              </div>
            </div>
          </UnstyledButton>
        ) : (
          <UnstyledButton onClick={() => setExpanded(true)}>
            <div className="flex h-full gap-1">
              <Text ff={fontFamily} size={"14px"} c={"#828282"}>
                See last 3 months
              </Text>
              <div className="flex h-[20px] p-1 rounded-full bg-[#F2F2F2]">
                <FaPlus style={{ width: rem(12), height: rem(12) }} />
              </div>
            </div>
          </UnstyledButton>
        )}
      </div>
      <div className="flex flex-row items-center pb-2">
        <div className="min-w-[300px]" />
        {expanded && (
          <div className="w-full px-2">
            <Text ff={fontFamily} size={"14px"} c={"#828282"} w={"80px"}>
              {dateToMonthLabel(completedWaves[2])}
            </Text>
          </div>
        )}
        {expanded && (
          <div className="w-full px-2">
            <Text ff={fontFamily} size={"14px"} c={"#828282"} w={"80px"}>
              {dateToMonthLabel(completedWaves[1])}
            </Text>
          </div>
        )}
        <div className="w-full px-2">
          <Text ff={fontFamily} size={"14px"} c={"#828282"} w={"80px"}>
            {dateToMonthLabel(completedWaves[0])}
          </Text>
        </div>
        <div className="flex items-center min-w-[90px] max-w-[90px]">
          <Text ff={fontFamily} size={"14px"} c={"#828282"}>
            Change
          </Text>
        </div>
        {expanded && inProgressWave && (
          <div className="w-full px-2">
            <Text ff={fontFamily} size={"14px"} c={"#828282"} w={"80px"}>
              {dateToMonthLabel(inProgressWave)} to date
            </Text>
          </div>
        )}
        <div className="flex items-center min-w-[80px] max-w-[80px]">
          <Text ff={fontFamily} size={"14px"} c={"#828282"}>
            Trend
          </Text>
        </div>
      </div>
      <Divider />
      {defaultFilters &&
        kpiMetrics.map((kpi) => (
          <div key={kpi.label}>
            {kpi.label && (
              <Suspense fallback={<div className="h-[50px]" />}>
                <SingleKpi
                  label={kpi.label}
                  expanded={expanded}
                  filters={{
                    ...defaultFilters,
                    ...kpi,
                  }}
                  completedWaves={completedWaves}
                  inProgressWave={inProgressWave}
                />
              </Suspense>
            )}
            <Divider />
          </div>
        ))}
    </div>
  );
};

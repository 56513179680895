import Lottie from "lottie-react";
import React from "react";

import traLogoBlack from "../assets/TraSpinner_Black.json";
import traLogoWhite from "../assets/TraSpinner_White.json";
import { ErrorBoundary } from "./ErrorBoundary";

type LoadingSpinnerProps = {
  readonly width?: string;
  readonly fullScreen?: boolean;
  readonly dark?: boolean;
};

/**
 * A loading spinner component that displays a spinning TRA logo
 * @param props the width of the spinner
 * @returns a loading spinner component
 */
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (
  props: LoadingSpinnerProps,
) => {
  const { width = "80%", fullScreen, dark } = props;

  const traLogo = dark ? traLogoWhite : traLogoBlack;
  const colourClass = dark ? "bg-black" : "bg-white";
  const dimensionClass = fullScreen ? "w-screen h-screen" : "w-full h-full";

  const fallback = (
    <div className={`flex items-center justify-center ${dimensionClass}`}>
      <p>Loading...</p>
    </div>
  );

  return (
    <ErrorBoundary fallback={fallback}>
      <div
        className={`flex items-center justify-center ${colourClass} ${dimensionClass}`}
      >
        <Lottie
          style={{
            width,
          }}
          loop={true}
          animationData={traLogo}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

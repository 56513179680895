import { Button, Flex, Group, Image, NavLink } from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { signOut } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FloatingLabelTextInput } from "../component/FloatingLabelTextInput";
import { useUser } from "../context/UserContext";
import { logInUser } from "../util/authentication";
import { isPasswordComplex } from "../util/isPasswordComplex";

const invalidCredentialsMessage = "The password you entered is incorrect.";
const invalidEmailMessage = "No account found with that email address.";
const unexpectedErrorMessage =
  "An unexpected error occurred. Please try again later.";

/**
 * A page that allows users to log in
 * @returns a login page
 */
export const LoginPage = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  const { initUser, currentUser, selectedClient } = useUser();

  const form = useForm({
    mode: "controlled",
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: isEmail(invalidEmailMessage),
      password: isPasswordComplex,
    },
  });

  const handleLoginSubmit = async (values: {
    email: string;
    password: string;
  }) => {
    setIsLoggingIn(true);
    const { email, password } = values;
    // sign out just in case the issue is with the current session
    try {
      await signOut();
    } catch (error) {
      // Ignore error if user is not signed in
    }

    const loginResult = await logInUser({ email, password });
    if (loginResult.isErr()) {
      switch (loginResult.error.type) {
        case "NOT_AUTHORIZED":
          form.setErrors({
            email: invalidCredentialsMessage,
            password: invalidCredentialsMessage,
          });
          break;
        case "USER_NOT_FOUND":
          form.setErrors({
            email: invalidEmailMessage,
          });
          break;
        default:
          form.setErrors({
            email: unexpectedErrorMessage,
            password: unexpectedErrorMessage,
          });
      }
      setIsLoggingIn(false);
      return;
    }

    await initUser();
    navigate(`/dashboard/${selectedClient?.id}/home`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (currentUser) {
      navigate(`/dashboard/${selectedClient?.id}/home`, {
        replace: true,
      });
    }
  }, [currentUser, navigate, selectedClient]);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-black">
      <div className="h-32">
        <Image
          src={process.env.PUBLIC_URL + "/TRA_logo_white.png"}
          w="150px"
          aria-label="logo"
        />
      </div>
      <form onSubmit={form.onSubmit(handleLoginSubmit)} className="min-w-80">
        <FloatingLabelTextInput
          label="Email"
          key={form.key("email")}
          {...form.getInputProps("email")}
          styles={{
            input: { backgroundColor: "black", color: "white" },
            label: { color: "white" },
            root: { marginBottom: "20px" },
            error: {},
          }}
        />
        <FloatingLabelTextInput
          label="Password"
          type="password"
          key={form.key("password")}
          {...form.getInputProps("password")}
          styles={{
            input: { backgroundColor: "black", color: "white" },
            label: { color: "white" },
            root: { marginBottom: "40px" },
          }}
        />
        <Group justify="space-around" mt="md">
          <Button
            type="submit"
            variant="white"
            color="gray.9"
            disabled={isLoggingIn}
            className="w-full"
          >
            Submit
          </Button>
        </Group>
        <Flex gap="md" justify="center" align="center">
          <NavLink
            href="/forgot-password"
            label="Forgot password?"
            className="mt-12 text-white hover:text-gray-500 hover:bg-inherit w-fit"
          ></NavLink>
        </Flex>
      </form>
    </div>
  );
};

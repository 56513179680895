import { roundValueForDisplay } from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { ChangeIndicatorPill } from "../ChangeIndicatorPill";

export type HorizontalPercentageBarProps = {
  readonly value: number;
  readonly boldValue?: boolean;
  readonly changeValue?: number;
  readonly isSignificantChange?: boolean;
  readonly colour: string;
  readonly alignment: "left" | "center" | "right";
  readonly compact?: boolean;
  readonly scaleBar?: number;
};

export const HorizontalPercentageBar: React.FC<HorizontalPercentageBarProps> = (
  props: HorizontalPercentageBarProps,
) => {
  const {
    value,
    boldValue = true,
    colour,
    changeValue,
    isSignificantChange,
    alignment,
    compact,
    scaleBar,
  } = props;

  const showChange =
    changeValue !== undefined && roundValueForDisplay(changeValue) !== 0;

  const scaledValue = scaleBar ? value * scaleBar : value;

  const changeComponent = (
    <div
      className="flex items-center w-full h-full"
      style={{
        width: `${100 - scaledValue}%`,
        justifyContent: alignment === "right" ? "flex-start" : "flex-end",
      }}
    >
      {showChange && (
        <ChangeIndicatorPill
          value={changeValue}
          isSignificant={isSignificantChange || false}
        />
      )}
    </div>
  );

  const background =
    alignment === "center"
      ? `linear-gradient(90deg, #00000000 ${50 - scaledValue / 2}%, ${colour} ${50 - scaledValue / 2}%, ${colour} ${50 + scaledValue / 2}%, #00000000 ${50 + scaledValue / 2}%)`
      : `linear-gradient(to ${alignment === "left" ? "left" : "right"}, ${colour} ${scaledValue}%, #00000000 ${scaledValue}%)`;

  return (
    <div
      className="flex flex-row w-full"
      style={{
        height: compact ? "37px" : "90px",
        background: background,
      }}
    >
      {alignment === "left" && changeComponent}
      <div
        className={"h-full flex items-center"}
        style={{
          width: `${value}%`,
          minWidth: compact ? "50px" : "130px",
          fontFamily: boldValue
            ? FontFamily.PPNeueMontrealBold
            : FontFamily.PPNeueMontrealRegular,
          fontSize: compact ? "20px" : "60px",
          ...(alignment === "left" && {
            marginLeft: "28px",
          }),
          ...(alignment === "center" && {
            justifyContent: "center",
            width: "100%",
          }),
          ...(alignment === "right" && {
            minWidth: compact ? "50px" : "130px",
            marginRight: "28px",
            flexDirection: "row-reverse",
          }),
        }}
      >
        {`${roundValueForDisplay(value)}%`}
      </div>
      {alignment === "right" && changeComponent}
    </div>
  );
};

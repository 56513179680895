import { isArray, isNumber } from "lodash";

import {
  TimelineChartData,
  TimelineCollatedDataValue,
} from "../component/Visualisation/Timeline";

type SeriesDataPoint = ApexAxisChartSeries[0]["data"][0];

type FormattedTimeSeriesCollatedData = {
  x: Date;
  y: number | null;
} | null;

/**
 * Formats the data from the collated data query into a format that can be used by the chart
 * @param data the data from the collated data query
 * @returns the formatted data
 */
const formatTimeSeriesCollatedData = (
  data: TimelineCollatedDataValue,
): FormattedTimeSeriesCollatedData | null => {
  const waveDate = data?.WAVE_DATE;
  const percentage = data?.PERCENTAGE;
  if (!waveDate || percentage === undefined || percentage === null) {
    return null;
  }
  const x = new Date(waveDate);
  const y = data.IS_SCORE ? percentage : percentage * 100;
  return { x, y };
};

/**
 * Splits the data into series based on the value of the splitSeriesBy key
 * @param data the data to be split
 * @param splitSeriesBy the key to split the data by
 * @returns the data split into series
 */
const splitDataIntoSeries = (
  data: TimelineChartData,
  splitSeriesBy: keyof TimelineCollatedDataValue,
): Record<string, FormattedTimeSeriesCollatedData[]> => {
  if (!data) {
    return {};
  }
  // Get all the unique wave dates so we can fill in missing dates with null values
  const allWaveDates = data
    .map((item) => item?.WAVE_DATE)
    .filter(
      (waveDate): waveDate is string =>
        waveDate && typeof waveDate === "string",
    );
  const uniqueWaveDates: ReadonlyArray<string> = Array.from(
    new Set(allWaveDates),
  );
  const seriesKeys = data
    .map((item): string => {
      if (!item) {
        return "";
      }
      const seriesKey = item[splitSeriesBy];
      if (!seriesKey) {
        return "";
      }
      return seriesKey?.toString();
    })
    .filter(Boolean);

  return seriesKeys.reduce((acc, key): Record<string, SeriesDataPoint[]> => {
    // Filter to the current key's data
    const currentKeyData = data
      .filter((item) => item && item[splitSeriesBy] === key)
      .filter((item): item is TimelineCollatedDataValue => !!item);
    // Find the missing wave dates for this key
    const missingWaveDates = uniqueWaveDates.filter(
      (waveDate) =>
        !currentKeyData.some((dataItem) => dataItem?.WAVE_DATE === waveDate),
    );
    // Format the data we have for this key
    const thisKeysData: FormattedTimeSeriesCollatedData[] = currentKeyData.map(
      formatTimeSeriesCollatedData,
    );
    // Create missing data for the missing wave dates
    const missingData: FormattedTimeSeriesCollatedData[] = missingWaveDates.map(
      (waveDate) => ({
        x: new Date(waveDate),
        y: null,
      }),
    );
    // Sort the data by date
    const dateSortedData = [...thisKeysData, ...missingData].sort((a, b) => {
      if (!a || !b || !a.x || !b.x) {
        return 0;
      }
      return a.x.getTime() - b.x.getTime();
    });
    // Add the data to the accumulator
    return {
      ...acc,
      [key]: dateSortedData,
    };
  }, {});
};

/**
 * Gets the chart series from the collated snowflake data
 * @param options the data and the key to split the data by
 * @returns the chart series
 */
export const getChartSeriesFromCollatedData = (options: {
  queryData: TimelineChartData;
  splitSeriesBy: keyof TimelineCollatedDataValue;
  waveLabels: ReadonlyArray<string>;
}): ApexAxisChartSeries => {
  const { queryData, splitSeriesBy, waveLabels } = options;
  if (!queryData) {
    return [];
  }

  const splitSeries = splitDataIntoSeries(queryData, splitSeriesBy);

  const chartData: ApexAxisChartSeries = Object.entries(splitSeries).map(
    ([key, data]) => ({
      name: key,
      data: waveLabels.map((wave) => {
        const point = data.find((dataPoint) => {
          if (!dataPoint) {
            return false;
          }
          const waveAsDate = new Date(wave);
          const diffTime = Math.abs(
            dataPoint.x.getTime() - waveAsDate.getTime(),
          );
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          return diffDays < 2;
        });
        if (
          !point ||
          isArray(point) ||
          isNumber(point) ||
          point.y === undefined
        ) {
          return null;
        }
        return point.y;
      }),
    }),
  );

  return chartData.sort((a, b) => {
    if (!a.name || !b.name) {
      return 0;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

import { fetchAuthSession } from "aws-amplify/auth";
import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

const HTTP_ENDPOINT = `${process.env.REACT_APP_API_URL}/graphql`;

/**
 * A wrapper function to fetch data from the GraphQL server
 * @param request the GraphQL request
 * @param variables the variables to pass to the request
 * @returns the response from the server
 */
const fetchFunction: FetchFunction = async (request, variables) => {
  const fetchedAccessToken = (
    await fetchAuthSession()
  )?.tokens?.accessToken.toString();
  const resp = await fetch(HTTP_ENDPOINT, {
    method: "POST",
    headers: {
      Accept:
        "application/graphql-response+json; charset=utf-8, application/json; charset=utf-8",
      "Content-Type": "application/json",
      ...(fetchedAccessToken && {
        Authorization: `Bearer ${fetchedAccessToken}`,
      }),
      // <-- Additional headers go here
    },
    body: JSON.stringify({
      query: request.text, // <-- The GraphQL document composed by Relay
      variables,
    }),
  });

  try {
    return await resp.json();
  } catch (e) {
    return null;
  }
};

/**
 * Creates a new Relay environment context
 * @returns a new Relay environment
 */
export const createRelayEnvironment = () => {
  const network = Network.create(fetchFunction);
  const store = new Store(new RecordSource());
  return new Environment({
    store,
    network,
    options: { fetchPolicy: "store-and-network" },
  });
};

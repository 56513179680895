import { ActionIcon, Group, Menu, Table, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { Client } from "@tra-nz/platform-common";
import { useEffect, useState } from "react";
import { FiDelete } from "react-icons/fi";
import { TbDotsVertical, TbPencil } from "react-icons/tb";

import { ConfigureClientModal } from "../component/ConfigureClientModal";
import { Navbar } from "../component/Navbar/Navbar";
import { useUser } from "../context/UserContext";
import { FontFamily } from "../util/fontFamily";

export const ClientManagementPage: React.FC = () => {
  const { currentUser } = useUser();

  const [clients, setClients] = useState<Client[]>();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const sortedClients = [...currentUser.clients].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    setClients(sortedClients);
  }, [currentUser]);

  const openConfigureClientModal =
    (options: { id: string; name: string }) => () => {
      const { id, name } = options;
      modals.open({
        title: (
          <Text ff={FontFamily.PPNeueMontrealBold} size={"24px"}>
            Configure Client: {name}
          </Text>
        ),
        children: <ConfigureClientModal id={id} />,
        centered: true,
        size: "90%",
      });
    };

  const openDeleteClientModal =
    (options: { id: string; name: string }) => () => {
      const { name } = options;
      modals.openConfirmModal({
        title: (
          <Text ff={FontFamily.PPNeueMontrealBold} size={"24px"}>
            Delete Client: {name}
          </Text>
        ),
        children: (
          <div>
            <Text>
              Are you sure you want to delete {name}? This action cannot be
              undone.
            </Text>
          </div>
        ),
        labels: { confirm: "Delete Forever", cancel: "No" },
        confirmProps: { color: "red" },
        onCancel: () => {},
        onConfirm: () => {
          notifications.show({
            title: "Client Deletion Not Implemented",
            message: `${name} has NOT been deleted.`,
            color: "red",
          });
        },
        centered: true,
      });
    };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col w-screen h-full p-5">
        <Text ff={FontFamily.PPNeueMontrealBold} size={"32px"} pb={30}>
          Manage Clients
        </Text>
        <Table.ScrollContainer minWidth={1}>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th w={150}>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {clients?.map((client) => (
                <Table.Tr key={client.id} className="w-full p-3">
                  <Table.Td className="p-4 text-lg">{client.name}</Table.Td>
                  <Table.Td w={150}>
                    <Group>
                      <ActionIcon
                        variant="subtle"
                        color="gray"
                        onClick={openConfigureClientModal({ ...client })}
                      >
                        <TbPencil className="w-4 h-4" />
                      </ActionIcon>
                      <Menu
                        transitionProps={{ transition: "pop" }}
                        withArrow
                        position="bottom-end"
                        withinPortal
                      >
                        <Menu.Target>
                          <ActionIcon variant="subtle" color="gray">
                            <TbDotsVertical className="w-4 h-4" />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            leftSection={<FiDelete className="w-4 h-4" />}
                            color="red"
                            onClick={openDeleteClientModal({ ...client })}
                          >
                            Delete Client
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Group>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </div>
    </div>
  );
};

/**
 * @generated SignedSource<<a0ceed8e26e5f55fccf59e4aa6d31f5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignSnapshotColumnQuery$variables = {
  audience: string;
  clientId: string;
  metric: string;
  secondaryAudience?: string | null | undefined;
};
export type CampaignSnapshotColumnQuery$data = {
  readonly campaigns: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly PROJECT_ID: number | null | undefined;
    readonly QUESTION_TEXT: string | null | undefined;
    readonly ROLL: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CampaignSnapshotColumnQuery = {
  response: CampaignSnapshotColumnQuery$data;
  variables: CampaignSnapshotColumnQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audience"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondaryAudience"
},
v4 = [
  {
    "alias": "campaigns",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "audience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondaryAudience"
          },
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PROJECT_ID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ROLL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "QUESTION_TEXT",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignSnapshotColumnQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignSnapshotColumnQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f32dcf0fd7c7c373ec458b701c512013",
    "id": null,
    "metadata": {},
    "name": "CampaignSnapshotColumnQuery",
    "operationKind": "query",
    "text": "query CampaignSnapshotColumnQuery(\n  $clientId: String!\n  $metric: String!\n  $audience: String!\n  $secondaryAudience: String\n) {\n  campaigns: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, AUDIENCE1: $audience, AUDIENCE2: $secondaryAudience}) {\n    PROJECT_ID\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n    ROLL\n    QUESTION_TEXT\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1eef5d5278625addc34ca6ca58885ae";

export default node;

import { Text } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { useUser } from "../../context/UserContext";
import { ChangeSince } from "../../util/changeSince";
import {
  getDataForWaveDate,
  getEarliestWaveDate,
  getLatestWaveDate,
  getMaximumValue,
  offsetWaveDate,
  roundValueForDisplay,
} from "../../util/dataProcessing";
import { getDateRangeOptionsFromApiData } from "../../util/dateRange";
import { FontFamily } from "../../util/fontFamily";
import { genBrandCompareFn } from "../../util/genBrandCompareFn";
import { genBrandConfigs } from "../../util/genBrandConfigs";
import { ChangeIndicatorPill } from "../ChangeIndicatorPill";
import { Legend, LegendSeries } from "../Legend";
import { FilterComponentProps, MultiFilterBar } from "../MultiFilterBar";
import { SmallLoader } from "../SmallLoader";
import { FOOTER_HEIGHT, VisualisationFooter } from "./VisualisationFooter";
import { VerticalBarGraphQuery as VerticalBarGraphQueryType } from "./__generated__/VerticalBarGraphQuery.graphql";
import { VisualisationComponent, VisualisationProps } from "./types";

const VerticalBarGraphQuery = graphql`
  query VerticalBarGraphQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $metric: String!
    $category: String
    $roll: Int
    $statement: String
  ) {
    brands: collatedData(
      clientId: $clientId
      distinctSelect: ["BRAND"]
      filters: {
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
      }
    ) {
      BRAND
    }
    statements: collatedData(
      clientId: $clientId
      distinctSelect: ["STATEMENT"]
      filters: {
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
      }
    ) {
      STATEMENT
    }
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        STATEMENT: $statement
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
    waveDates: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        STATEMENT: $statement
      }
      distinctSelect: ["WAVE_DATE"]
    ) {
      WAVE_DATE
    }
  }
`;

const verticalBarGraphOptionsSchema = z.object({
  filterTo: z
    .object({
      brand: z.string().optional(),
      statement: z.string().optional(),
    })
    .optional(),
  filterBy: z
    .object({
      brand: z.boolean().optional(),
      statement: z.boolean().optional(),
    })
    .optional(),
});

export const VerticalBarGraph: VisualisationComponent = (
  props: VisualisationProps,
) => {
  const { dashBoardFilters, options, height } = props;
  const { filterTo, filterBy } = verticalBarGraphOptionsSchema.parse(options);

  const { selectedClient } = useUser();

  const [selectedChangeSince, setSelectedChangeSince] = useState<string>();
  const [legendSeries, setLegendSeries] = useState<ReadonlyArray<LegendSeries>>(
    [],
  );
  const [brand, setBrand] = useState<string | null>(filterTo?.brand || null);
  const [statement, setStatement] = useState<string | null>(
    filterTo?.statement || null,
  );

  const [colourOptions] = useState<string[]>([
    "#FFAAD2",
    "#4BA0CD",
    "#929E97",
    "#BD5E8D",
    "#D34B28",
    "#D8AE96",
    "#E1E145",
    "#87A13D",
    "#CFDBB5",
    "#8280D4",
    "#C2962C",
  ]);

  const queryData = useLazyLoadQuery<VerticalBarGraphQueryType>(
    VerticalBarGraphQuery,
    {
      ...dashBoardFilters,
      brand,
      statement,
    },
  );

  const latestWaveDate = useMemo(() => {
    const latestCompleteWaveDate = getLatestWaveDate(queryData.chartData, true);
    const latestIncompleteWaveDate = getLatestWaveDate(queryData.chartData);
    return latestCompleteWaveDate || latestIncompleteWaveDate;
  }, [queryData.chartData]);

  const data = useMemo(() => {
    if (!latestWaveDate) {
      return;
    }
    const dataToShow = getDataForWaveDate(queryData.chartData, latestWaveDate);
    if (!dataToShow) {
      return;
    }
    return [...dataToShow].sort((a, b) => {
      if (!a?.PERCENTAGE || !b?.PERCENTAGE) {
        return 0;
      }
      return a.PERCENTAGE > b.PERCENTAGE ? -1 : 1;
    });
  }, [latestWaveDate, queryData.chartData]);

  const comparisonWaveData = useMemo(() => {
    if (!latestWaveDate || !selectedChangeSince) {
      return;
    }
    const earliestWaveDate = getEarliestWaveDate(queryData.chartData);
    const comparisonWaveDate =
      selectedChangeSince === ChangeSince.ALL_TIME
        ? earliestWaveDate
        : offsetWaveDate(
            latestWaveDate,
            selectedChangeSince ? parseInt(selectedChangeSince) : 0,
          );
    if (!comparisonWaveDate) {
      return;
    }
    return getDataForWaveDate(queryData.chartData, comparisonWaveDate);
  }, [latestWaveDate, queryData.chartData, selectedChangeSince]);

  const getComparisonValue = useCallback(
    (
      currentDataPoint: NonNullable<
        VerticalBarGraphQueryType["response"]["chartData"]
      >[0],
    ) => {
      if (!comparisonWaveData) {
        return;
      }
      const comparisonDataPoint = comparisonWaveData.find((comparePoint) => {
        if (filterTo?.brand) {
          return comparePoint?.STATEMENT === currentDataPoint?.STATEMENT;
        }
        return comparePoint?.BRAND === currentDataPoint?.BRAND;
      });
      if (!comparisonDataPoint?.PERCENTAGE || !currentDataPoint?.PERCENTAGE) {
        return null;
      }
      return (
        (currentDataPoint.PERCENTAGE - comparisonDataPoint.PERCENTAGE) *
        (currentDataPoint.IS_SCORE ? 1 : 100)
      );
    },
    [comparisonWaveData, filterTo?.brand],
  );

  const maxValue = useMemo(() => {
    if (!data) {
      return 0;
    }
    return getMaximumValue(data);
  }, [data]);

  const brandOptions = useMemo(() => {
    if (!selectedClient?.config || !queryData.brands) {
      return [];
    }
    const sortFn = genBrandCompareFn(selectedClient.config);
    const sortedBrands = queryData.brands
      ?.map((option) => option?.BRAND)
      ?.filter((option): option is string => {
        return Boolean(option);
      })
      .sort(sortFn);
    const allBrandConfigs = genBrandConfigs(
      selectedClient.config,
      sortedBrands,
    );
    return allBrandConfigs.filter((brandConfig) =>
      sortedBrands.includes(brandConfig.name),
    );
  }, [queryData.brands, selectedClient?.config]);

  const statementOptions = useMemo(() => {
    const allStatements = queryData.statements
      ?.map((statement) => statement?.STATEMENT || "")
      .filter(Boolean);
    if (!allStatements || allStatements.length === 0) {
      return [];
    }
    return allStatements;
  }, [queryData.statements]);

  const selectOptions = useMemo(() => {
    const options = getDateRangeOptionsFromApiData(queryData.waveDates);
    setSelectedChangeSince(options.defaultDateRange);
    return options;
  }, [queryData.waveDates]);

  const filters: FilterComponentProps[] = useMemo(() => {
    return [
      ...(filterBy?.brand
        ? ([
            {
              variant: "dropdown",
              inputLabel: "Brand",
              options: brandOptions.map((brand) => brand.name),
              value: brand,
              onSelect: (value) => value && setBrand(value),
            },
          ] as FilterComponentProps[])
        : []),
      ...(filterBy?.statement
        ? ([
            {
              variant: "dropdown",
              inputLabel: "Statement",
              options: statementOptions,
              value: statement,
              onSelect: (value) => value && setStatement(value),
            },
          ] as FilterComponentProps[])
        : []),
      {
        variant: "dropdown",
        inputLabel: "Change since",
        options: selectOptions.validDateRanges,
        value: selectedChangeSince || selectOptions.defaultDateRange,
        onSelect: (value) => value && setSelectedChangeSince(value),
      },
    ];
  }, [
    brand,
    brandOptions,
    filterBy?.brand,
    filterBy?.statement,
    selectOptions.defaultDateRange,
    selectOptions.validDateRanges,
    selectedChangeSince,
    statement,
    statementOptions,
  ]);

  useEffect(() => {
    const legendSeriesData: ReadonlyArray<LegendSeries> =
      data?.map((dataPoint, index) => {
        const barLabel = filterTo?.brand
          ? dataPoint?.STATEMENT
          : dataPoint?.BRAND;
        const currentValue = legendSeries.find(
          (legendSeriesToCheck) => legendSeriesToCheck.name === barLabel,
        );
        const enabled = currentValue ? currentValue.enabled : true;
        const colour =
          brandOptions?.find((brand) => brand.name === dataPoint?.BRAND)
            ?.colour || colourOptions[index % colourOptions.length];
        return {
          name: barLabel || "",
          colour,
          enabled,
        };
      }) || [];
    setLegendSeries(legendSeriesData);
  }, [
    brandOptions,
    colourOptions,
    data,
    filterTo?.brand,
    latestWaveDate,
    legendSeries,
    queryData.chartData,
  ]);

  // Toggle the series on the chart
  const onToggleSeries = useCallback(
    (name: string) => {
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name === name) {
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      setLegendSeries(updatedLegendSeries);
    },
    [legendSeries],
  );

  return (
    <div
      className="flex flex-col"
      style={{
        height,
      }}
    >
      <div className="flex flex-row h-[100px]">
        <MultiFilterBar filters={filters} />
        <div className="flex mr-4 w-full h-[100px]">
          {data && data.length > 1 && (
            <Legend
              marker={"circle"}
              series={legendSeries}
              onToggleSeries={onToggleSeries}
            />
          )}
        </div>
      </div>
      <div
        className="flex flex-row w-full gap-3 px-3 overflow-x-auto overscroll-y-none"
        style={{
          height: height - 100 - FOOTER_HEIGHT,
        }}
      >
        {data && data.length > 0 ? (
          data.map((dataPoint, index) => {
            const barLabel = filterTo?.brand
              ? dataPoint?.STATEMENT
              : dataPoint?.BRAND;
            const barColour = legendSeries.find(
              (series) => series.name === barLabel,
            )?.colour;
            const percentage = dataPoint?.PERCENTAGE || 0;
            const value = dataPoint?.IS_SCORE ? percentage : percentage * 100;
            const topOfScale = dataPoint?.IS_SCORE ? maxValue : maxValue * 100;
            const scaledValue = (value / topOfScale) * 90;
            const suffix = dataPoint?.IS_SCORE ? "" : "%";
            const comparisonValue = getComparisonValue(dataPoint);

            if (
              legendSeries.find((series) => series.name === barLabel)
                ?.enabled === false
            ) {
              return null;
            }

            return (
              <div key={index} className="flex flex-col w-full h-full max-w-32">
                <div
                  className="flex flex-col-reverse w-full h-full"
                  style={{
                    background: `linear-gradient(to top, ${barColour} ${scaledValue}%, #00000000 ${scaledValue}%)`,
                  }}
                >
                  <Text
                    ff={FontFamily.PPNeueMontrealBold}
                    size={"40px"}
                    h={`${scaledValue}%`}
                    pt={"4px"}
                    px={"12px"}
                    mih={"60px"}
                    ta={"center"}
                  >
                    {roundValueForDisplay(value)}
                    {suffix}
                  </Text>
                  {!!comparisonValue && (
                    <div className="flex flex-row items-center justify-center py-2">
                      <ChangeIndicatorPill
                        value={comparisonValue || null}
                        isSignificant={false}
                        isScore={dataPoint?.IS_SCORE ? true : false}
                      />
                    </div>
                  )}
                </div>
                <Text
                  lineClamp={1}
                  ff={
                    barLabel?.toLowerCase() ===
                    selectedClient?.name.toLowerCase()
                      ? FontFamily.PPNeueMontrealBold
                      : FontFamily.PPNeueMontrealMedium
                  }
                  ta={"center"}
                  py={"sm"}
                  mah={"40px"}
                >
                  {barLabel}
                </Text>
              </div>
            );
          })
        ) : (
          <SmallLoader />
        )}
      </div>
      <VisualisationFooter data={data} dashboardFilters={dashBoardFilters} />
    </div>
  );
};

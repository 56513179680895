import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { LoadingSpinner } from "../component/LoadingSpinner";
import { Navbar } from "../component/Navbar/Navbar";
import { useUser } from "../context/UserContext";
import { assertUnreachable } from "../util/assertUnreachable";
import { toUrlParamCase } from "../util/toUrlParamCase";
import { CampaignListTab } from "./CampaignListTab";
import { StandardDashboardTab } from "./StandardDashboardTab";

const dashboardTabParamsSchema = z.object({
  clientId: z.string(),
  tabTitle: z.string(),
  visualisationTitle: z.string(),
});

/**
 * The generic dashboard tab component
 *
 * @returns the dashboard tab component
 */
export const DashboardTab: React.FC = () => {
  const params = useParams();
  const user = useUser();
  const { clientId, tabTitle, visualisationTitle } =
    dashboardTabParamsSchema.parse(params);

  const clientConfig = user.currentUser?.clients.find(
    (client) => client.id === clientId,
  )?.config;

  const tabConfig =
    clientConfig?.tabs &&
    clientConfig.tabs.find((tab) => toUrlParamCase(tab.title) === tabTitle);

  const tabItemConfig = tabConfig?.items.find(
    (item) => toUrlParamCase(item.title) === visualisationTitle,
  );

  const genTab = useCallback(() => {
    if (!tabItemConfig) {
      return <LoadingSpinner />;
    }

    const tabType = tabItemConfig.type;
    switch (tabType) {
      case "standard":
        return (
          <StandardDashboardTab
            chartTitle={tabItemConfig?.title}
            metric={tabItemConfig?.metric}
            clientId={clientId}
            visualisations={tabItemConfig?.visualisations}
            aiSummary={tabItemConfig.aiSummaryText}
          />
        );
      case "creativeEdge":
        return (
          <CampaignListTab
            clientId={clientId}
            aiSummary={tabItemConfig.aiSummaryText}
          />
        );
      default:
        assertUnreachable(tabType);
    }
  }, [clientId, tabItemConfig]);

  return (
    <div className="flex flex-col w-screen h-full">
      <Navbar />
      {genTab()}
    </div>
  );
};

import { Divider } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { BrandConfig } from "@tra-nz/platform-common";
import graphql from "babel-plugin-relay/macro";
import { chunk, isString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useUser } from "../../context/UserContext";
import {
  CollatedData,
  getDataForWaveDate,
  getLatestWaveDate,
  splitByField,
} from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { genBrandCompareFn } from "../../util/genBrandCompareFn";
import { genBrandConfigs } from "../../util/genBrandConfigs";
import { getStatementOrderFn } from "../../util/getStatementOrderForClient";
import { ConversionFunnel } from "../ConversionFunnel";
import { VisualisationFooter } from "./VisualisationFooter";
import { FunnelComparisonQuery as FunnelComparisonQueryType } from "./__generated__/FunnelComparisonQuery.graphql";
import { VisualisationComponent, VisualisationProps } from "./types";

type BrandDataMap = Record<string, CollatedData>;

const FunnelComparisonQuery = graphql`
  query FunnelComparisonQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

/**
 * A visualisation component that displays a comparison of conversion funnels for multiple brands
 *
 * @param props the visualisation props
 * @returns the funnel comparison visualisation component
 */
export const FunnelComparison: VisualisationComponent = (
  props: VisualisationProps,
) => {
  const { dashBoardFilters, metric, height } = props;
  const { selectedClient } = useUser();

  const { ref, width } = useElementSize();

  const [statementOrder, setStatementOrder] = useState<string[]>([]);

  const [latestGroupedData, setLatestGroupedData] = useState<BrandDataMap>();
  const [dataAtLastUpdate, setDataAtLastUpdate] =
    useState<FunnelComparisonQueryType["response"]["chartData"]>();
  const [brandOrder, setBrandOrder] = useState<ReadonlyArray<BrandConfig>>();
  const [chunkedBrandOrder, setChunkedBrandOrder] =
    useState<ReadonlyArray<ReadonlyArray<BrandConfig | null>>>();

  const queryData = useLazyLoadQuery<FunnelComparisonQueryType>(
    FunnelComparisonQuery,
    {
      ...dashBoardFilters,
      metric: "funnel",
    },
  );

  // update the statement order, brand order, and latest wave data when the query data changes
  useEffect(() => {
    if (dataAtLastUpdate === queryData.chartData) {
      return;
    }
    const latestWaveDate = getLatestWaveDate(queryData.chartData, true);
    if (!latestWaveDate || selectedClient?.config === undefined) {
      return;
    }
    const latestWaveData = getDataForWaveDate(
      queryData.chartData,
      latestWaveDate,
    );

    if (!latestWaveData) {
      return;
    }

    const allStatementsInData = latestWaveData
      .map((data) => data?.STATEMENT)
      .filter(isString);
    const uniqueStatements = Array.from(new Set(allStatementsInData));
    const orderedStatements = uniqueStatements.sort(
      getStatementOrderFn({
        clientConfig: selectedClient.config,
        metric: metric || dashBoardFilters.metric,
      }),
    );
    if (orderedStatements !== statementOrder) {
      setStatementOrder(orderedStatements);
    }

    const dataSplitByBrand = splitByField(latestWaveData, "BRAND");

    const sortFn = selectedClient.config
      ? genBrandCompareFn(selectedClient.config)
      : undefined;
    const sortedBrands = Object.keys(dataSplitByBrand).sort(sortFn);
    const allBrandConfigs = genBrandConfigs(
      selectedClient.config,
      sortedBrands,
    );
    const brandConfigsInData = allBrandConfigs.filter((brandConfig) => {
      return sortedBrands.includes(brandConfig.name);
    });

    setBrandOrder(brandConfigsInData);
    setLatestGroupedData(dataSplitByBrand);
    setDataAtLastUpdate(queryData.chartData);
  }, [
    dashBoardFilters.metric,
    dataAtLastUpdate,
    metric,
    queryData.chartData,
    selectedClient,
    statementOrder,
  ]);

  // work out how many funnels to display per row
  useEffect(() => {
    if (!width) {
      return;
    }
    const requiredFunnelsPerRow = Math.floor((width - 200) / 400);
    const clampedFunnelsPerRow = Math.max(1, requiredFunnelsPerRow);
    if (!brandOrder) {
      return;
    }

    const chunkedBrands = chunk(brandOrder, clampedFunnelsPerRow).map(
      (chunkBrands) => {
        return Array.from({
          length: clampedFunnelsPerRow,
        }).map((_, index): BrandConfig | null => {
          const brand = chunkBrands[index];
          if (!brand) {
            return null;
          }
          return brand;
        });
      },
    );
    setChunkedBrandOrder(chunkedBrands);
  }, [brandOrder, width]);

  // generate the funnel for a single brand
  const genSingleBrandFunnel = useCallback(
    (brand: BrandConfig | null, index: number) => {
      if (!latestGroupedData) {
        return null;
      }
      const thisBrandsData = brand ? latestGroupedData[brand.name] : null;
      if (!brand || !thisBrandsData || thisBrandsData.length === 0) {
        return <div key={index} className="w-full" />;
      }
      const isClientBrand = selectedClient?.config?.clientBrands.some(
        (clientBrand) => clientBrand.name === brand?.name,
      );
      const conversionFunnelData = statementOrder.map(
        (statement, statementIndex) => {
          const statementData = thisBrandsData.find(
            (row) => row?.STATEMENT === statement,
          );
          if (!statementData) {
            return null;
          }
          return {
            percentage: statementData?.PERCENTAGE || 0,
            showConversion: statementIndex === 1 || statementIndex === 2,
          };
        },
      );
      return (
        <ConversionFunnel
          key={index}
          brand={brand?.name}
          data={conversionFunnelData}
          colour={brand?.colour || "black"}
          boldTitle={isClientBrand}
        />
      );
    },
    [latestGroupedData, selectedClient?.config?.clientBrands, statementOrder],
  );

  return (
    <div
      className="flex flex-col w-full"
      style={{
        height,
      }}
      ref={ref}
    >
      <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto">
        {chunkedBrandOrder &&
          chunkedBrandOrder.map((chunkBrands, index) => {
            return (
              <div key={index} className="flex flex-row mb-5">
                <div className="flex flex-col h-ful">
                  <div className="flex w-full h-full max-h-16 min-h-16" />
                  <Divider />
                  {/* Generate the statement labels for the funnel on the left */}
                  {statementOrder.map((statement, statementIndex) => {
                    return (
                      <div
                        key={statementIndex}
                        className="flex flex-col w-full h-full"
                      >
                        <div
                          className="flex items-center mx-8 text-base text-nowrap"
                          style={{
                            fontFamily: FontFamily.PPNeueMontrealRegular,
                            height: 37,
                          }}
                        >
                          {statement}
                        </div>
                        <Divider />
                      </div>
                    );
                  })}
                </div>
                {chunkBrands.map(genSingleBrandFunnel)}
              </div>
            );
          })}
      </div>
      {latestGroupedData && (
        <VisualisationFooter
          dashboardFilters={dashBoardFilters}
          data={Object.values(latestGroupedData).flat()}
        />
      )}
    </div>
  );
};

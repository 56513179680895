export enum ChangeSince {
  LAST_MONTH = "Last Month",
  THREE_MONTHS = "Last 3 Months",
  SIX_MONTHS = "Last 6 Months",
  TWELVE_MONTHS = "Last 12 Months",
  TWO_YEARS = "Last 24 Months",
  ALL_TIME = "All Time",
}

export const changeSinceToMonths: Record<ChangeSince, number> = {
  [ChangeSince.LAST_MONTH]: 1,
  [ChangeSince.THREE_MONTHS]: 3,
  [ChangeSince.SIX_MONTHS]: 6,
  [ChangeSince.TWELVE_MONTHS]: 12,
  [ChangeSince.TWO_YEARS]: 24,
  [ChangeSince.ALL_TIME]: 0,
};

/**
 * Converts the given date to a date range
 *
 * @param months the number of months to convert
 * @returns the date range for the given number of months
 */
export const dateToChangeSince = (fromMonth: Date): ChangeSince[] => {
  const now = new Date();
  const numberOfMonths =
    (now.getFullYear() - fromMonth.getFullYear()) * 12 +
    now.getMonth() -
    fromMonth.getMonth();
  return [
    ...(numberOfMonths > 1 ? [ChangeSince.LAST_MONTH] : []),
    ...(numberOfMonths > 3 ? [ChangeSince.THREE_MONTHS] : []),
    ...(numberOfMonths > 6 ? [ChangeSince.SIX_MONTHS] : []),
    ...(numberOfMonths > 12 ? [ChangeSince.TWELVE_MONTHS] : []),
    ...(numberOfMonths > 24 ? [ChangeSince.TWO_YEARS] : []),
    ChangeSince.ALL_TIME,
  ];
};

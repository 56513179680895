/**
 * @generated SignedSource<<a6b99b6c91f2ac91c5253badbd0d12f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignBreakdownTabQuery$variables = {
  audience: string;
  clientId: string;
  metric: string;
  secondaryAudience?: string | null | undefined;
};
export type CampaignBreakdownTabQuery$data = {
  readonly campaigns: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly PROJECT_ID: number | null | undefined;
    readonly QUESTION_TEXT: string | null | undefined;
    readonly ROLL: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly newFormat: ReadonlyArray<{
    readonly BRAND_METRIC: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type CampaignBreakdownTabQuery = {
  response: CampaignBreakdownTabQuery$data;
  variables: CampaignBreakdownTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "audience"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondaryAudience"
},
v4 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v5 = [
  {
    "alias": "campaigns",
    "args": [
      (v4/*: any*/),
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "AUDIENCE1",
            "variableName": "audience"
          },
          {
            "kind": "Variable",
            "name": "AUDIENCE2",
            "variableName": "secondaryAudience"
          },
          {
            "kind": "Variable",
            "name": "BRAND_METRIC",
            "variableName": "metric"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PROJECT_ID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ROLL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "QUESTION_TEXT",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "newFormat",
    "args": [
      (v4/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "BRAND_METRIC"
        ]
      },
      {
        "kind": "Literal",
        "name": "filters",
        "value": {
          "BRAND_METRIC": "campaign - sentiment"
        }
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND_METRIC",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignBreakdownTabQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignBreakdownTabQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "19b9bd78115a18263eaf58f88a41287a",
    "id": null,
    "metadata": {},
    "name": "CampaignBreakdownTabQuery",
    "operationKind": "query",
    "text": "query CampaignBreakdownTabQuery(\n  $clientId: String!\n  $metric: String!\n  $audience: String!\n  $secondaryAudience: String\n) {\n  campaigns: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, AUDIENCE1: $audience, AUDIENCE2: $secondaryAudience}) {\n    PROJECT_ID\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n    ROLL\n    QUESTION_TEXT\n  }\n  newFormat: collatedData(clientId: $clientId, filters: {BRAND_METRIC: \"campaign - sentiment\"}, distinctSelect: [\"BRAND_METRIC\"]) {\n    BRAND_METRIC\n  }\n}\n"
  }
};
})();

(node as any).hash = "b069af8bc8d643016faa64fa1b5a71ec";

export default node;

import { Text } from "@mantine/core";
import { useMemo } from "react";

import { colours } from "../../util/colours";
import { FontFamily } from "../../util/fontFamily";
import { CirclePackChart } from "../CirclePackChart";
import { VisualisationProps } from "./types";

/**
 * A visualisation component that displays the percentage of prompted messages that are attributed to a specific code.
 *
 * @param props The props for the component.
 * @returns The tab view component.
 */
export const PromptedMessagesBreakdown: React.FC<VisualisationProps> = (
  props: VisualisationProps,
) => {
  const { height } = props;

  // function to generate a random number between 0 and 100
  const random = () => Math.floor(Math.random() * 100);

  const mockBubbleData = useMemo(
    () => [
      {
        name: "When you feel you have a voice today, it changes how you see tomorrow",
        percentage: random(),
        colour: colours.primaryBrand.blue,
      },
      {
        name: "Technology gives you the opportunity to purse what matters to you",
        percentage: random(),
        colour: colours.primaryBrand.blue,
      },
      {
        name: "Something was insightful and made me think",
        percentage: random(),
        colour: colours.primaryBrand.blue,
      },
    ],
    [],
  );

  return (
    <div className="flex flex-row w-full" style={{ height }}>
      <div className="flex flex-col w-1/3 p-6 pr-0">
        <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"} pb={"xl"}>
          Prompted message outtake
        </Text>
        <Text ff={FontFamily.PPNeueMontrealMedium} size={"58px"} pb={"sm"}>
          <Text span ff={FontFamily.PPNeueMontrealBold}>
            {85}%
          </Text>{" "}
          got at least one of the intended messages
        </Text>
        <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"}>
          85/100
        </Text>
      </div>
      <div
        className="flex flex-col w-2/3"
        style={{
          height: height,
        }}
      >
        <CirclePackChart height={height} data={mockBubbleData} />
      </div>
    </div>
  );
};

import { SegmentedControl, SegmentedControlProps } from "@mantine/core";

import { FontFamily } from "../util/fontFamily";
import { FilterDropdown, FilterDropdownProps } from "./FilterDropdown";

export type BaseFilterComponentProps = {
  readonly value: string | null;
  readonly onSelect: (option: string | null) => void;
  readonly inputLabel?: string;
};

type SegmentedControlComponentProps = BaseFilterComponentProps & {
  readonly variant: "segmentedControl";
  readonly options: SegmentedControlProps["data"];
};

export type FilterComponentProps =
  | FilterDropdownProps
  | SegmentedControlComponentProps;

type MultiFilterBarProps = {
  readonly filters: ReadonlyArray<FilterComponentProps>;
};

/**
 * A  filter bar component that allows users to filter data of multiple variables
 * @param props the filters to be displayed
 * @returns a filter bar component
 */
export const MultiFilterBar: React.FC<MultiFilterBarProps> = (
  props: MultiFilterBarProps,
) => {
  const { filters } = props;
  return (
    <div className="flex flex-row gap-2 p-3 pb-4">
      {filters.map((filter, index) => {
        const filterComponent =
          filter.variant === "segmentedControl" ? (
            <SegmentedControl
              key={index}
              data={filter.options}
              onChange={(value) => filter.onSelect(value)}
              color="black"
              bg={"#f0f0f0"}
              mt={"24px"}
              mah={"40px"}
              miw={"180px"}
              ff={FontFamily.PPNeueMontrealRegular}
            />
          ) : (
            <FilterDropdown key={index} {...filter} />
          );

        if (filter.inputLabel) {
          return filterComponent;
        }
        return (
          <div key={index} className="pt-6">
            {filterComponent}
          </div>
        );
      })}
    </div>
  );
};

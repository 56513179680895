/**
 * @generated SignedSource<<657ba4fa9ede2f81d9060c788b5cae38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UnpromptedMessageSummaryBoxQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  commentGroup?: string | null | undefined;
  questionNum: string;
  subject?: string | null | undefined;
  topic?: string | null | undefined;
};
export type UnpromptedMessageSummaryBoxQuery$data = {
  readonly comment: ReadonlyArray<{
    readonly COMMENT: string | null | undefined;
    readonly RESPONDENT_ID: string | null | undefined;
    readonly TOPIC: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type UnpromptedMessageSummaryBoxQuery = {
  response: UnpromptedMessageSummaryBoxQuery$data;
  variables: UnpromptedMessageSummaryBoxQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentGroup"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "questionNum"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topic"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "CATEGORY",
            "variableName": "category"
          },
          {
            "kind": "Variable",
            "name": "COMMENT_GROUP",
            "variableName": "commentGroup"
          },
          {
            "kind": "Variable",
            "name": "QUESTION_NUM",
            "variableName": "questionNum"
          },
          {
            "kind": "Variable",
            "name": "SUBJECT",
            "variableName": "subject"
          },
          {
            "kind": "Variable",
            "name": "TOPIC",
            "variableName": "topic"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CodedComment",
    "kind": "LinkedField",
    "name": "comment",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "RESPONDENT_ID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "TOPIC",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnpromptedMessageSummaryBoxQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnpromptedMessageSummaryBoxQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "955414d7d8c874aaaf6d79dcae28ab97",
    "id": null,
    "metadata": {},
    "name": "UnpromptedMessageSummaryBoxQuery",
    "operationKind": "query",
    "text": "query UnpromptedMessageSummaryBoxQuery(\n  $clientId: String!\n  $questionNum: String!\n  $category: String\n  $subject: String\n  $topic: String\n  $commentGroup: String\n) {\n  comment(clientId: $clientId, filters: {CATEGORY: $category, SUBJECT: $subject, TOPIC: $topic, COMMENT_GROUP: $commentGroup, QUESTION_NUM: $questionNum}) {\n    RESPONDENT_ID\n    WAVE_DATE\n    TOPIC\n    COMMENT\n  }\n}\n"
  }
};
})();

(node as any).hash = "d99ba4c16e59055aa6c98fda7f0392a7";

export default node;

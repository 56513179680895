import { Button, Flex, Group, Image, NavLink } from "@mantine/core";
import { useForm } from "@mantine/form";
import { confirmResetPassword } from "aws-amplify/auth";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FloatingLabelTextInput } from "../component/FloatingLabelTextInput";
import { useUser } from "../context/UserContext";
import { logInUser } from "../util/authentication";
import { isPasswordComplex } from "../util/isPasswordComplex";

const unexpectedErrorMessage = "Unexpected error. Please try again later.";

/**
 * A page that allows users to reset their password
 * @returns a reset password page
 */
export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { initUser } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      password: "",
    },
    validate: {
      password: isPasswordComplex,
    },
  });

  const handleFormSubmit = async (values: { password: string }) => {
    setIsSubmitting(true);
    const { password } = values;
    const username = searchParams.get("email");
    const confirmationCode = searchParams.get("code");
    if (!username || !confirmationCode) {
      form.setErrors({
        password: "Invalid reset password URL",
      });
      setIsSubmitting(false);
      return;
    }
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword: password,
      });
    } catch (error) {
      form.setErrors({
        password:
          error instanceof Error ? error.message : "Something went wrong",
      });
    }
    const loginResult = await logInUser({ email: username, password });
    if (loginResult.isErr()) {
      form.setErrors({
        password: unexpectedErrorMessage,
      });
      setIsSubmitting(false);
    }
    await initUser();
    navigate("/home", { replace: true });
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-black">
      <div className="h-32">
        <Image
          src={process.env.PUBLIC_URL + "/TRA_logo_white.png"}
          w="150px"
          aria-label="logo"
        />
      </div>
      <form onSubmit={form.onSubmit(handleFormSubmit)} className="min-w-80">
        <div className="mb-8 text-white">
          Reset Password for {searchParams.get("email")}
        </div>
        <FloatingLabelTextInput
          label="Password"
          type="password"
          key={form.key("password")}
          {...form.getInputProps("password")}
          styles={{
            input: { backgroundColor: "black", color: "white" },
            label: { color: "white" },
            root: { marginBottom: "40px" },
          }}
        />
        <Group justify="space-around" mt="md">
          {searchParams.get("email") && searchParams.get("code") ? (
            <Button
              type="submit"
              variant="white"
              color="gray.9"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          ) : (
            <p>Invalid URL</p>
          )}
        </Group>
        <Flex gap="md" justify="center" align="center">
          <NavLink
            href="/login"
            label="Login"
            className="text-white hover:text-gray-300 hover:bg-inherit w-fit"
          ></NavLink>
        </Flex>
      </form>
    </div>
  );
};

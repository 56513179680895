import { Button, Flex, Group, Image, NavLink } from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { resetPassword } from "aws-amplify/auth";
import { useCallback, useState } from "react";

import { FloatingLabelTextInput } from "../component/FloatingLabelTextInput";

/**
 * A page that allows users to reset their password
 * @returns a forgot password page
 */
export const ForgotPasswordPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
    },
    validate: {
      email: isEmail("Invalid email"),
    },
  });

  /**
   * Send a reset password link to the given email
   */
  const handleForgotPassword = useCallback(async (email: string) => {
    try {
      const resetPasswordResponse = await resetPassword({
        username: email,
      });
      if (resetPasswordResponse.nextStep.codeDeliveryDetails) {
        return {
          errMessage: "",
          successMessage: `Reset password link has been sent to ${email}`,
        };
      }
    } catch (error) {
      const message =
        error instanceof Error
          ? error.message
          : "Something went wrong with reset password";
      if (message.includes("not found")) {
        return { errMessage: "User not found", successMessage: "" };
      }
      return { errMessage: message, successMessage: "" };
    }
    return {
      errMessage: "Something went wrong with reset password",
      successMessage: "",
    };
  }, []);

  const handleFormSubmit = async (values: { email: string }) => {
    setIsSubmitting(true);
    const { email } = values;

    const { errMessage, successMessage } = await handleForgotPassword(email);
    if (errMessage) {
      form.setErrors({
        email: errMessage,
      });
    }
    if (successMessage) {
      setMessage(successMessage);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-black">
      <div className="h-32">
        <Image
          src={process.env.PUBLIC_URL + "/TRA_logo_white.png"}
          w="150px"
          aria-label="logo"
        />
      </div>
      <form onSubmit={form.onSubmit(handleFormSubmit)} className="min-w-80">
        <FloatingLabelTextInput
          label="Email"
          key={form.key("email")}
          {...form.getInputProps("email")}
          styles={{
            input: { backgroundColor: "black", color: "white" },
            label: { color: "white" },
            root: { marginBottom: "20px" },
            error: {},
          }}
        />
        {message && <p className="text-green-500">{message}</p>}
        <Group justify="space-around" mt="md">
          <Button
            type="submit"
            variant="white"
            color="gray.9"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Group>
        <Flex gap="md" justify="center" align="center">
          <NavLink
            href="/login"
            label="Login"
            className="text-white hover:text-gray-300 hover:bg-inherit w-fit"
          ></NavLink>
        </Flex>
      </form>
    </div>
  );
};

import { Text } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import {
  getLatestWaveDate,
  offsetWaveDate,
  roundValueForDisplay,
} from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { ChangeIndicatorPill } from "../ChangeIndicatorPill";
import { GraphChart } from "../GraphChart";
import { SingleKpiQuery as SingleKpiQueryType } from "./__generated__/SingleKpiQuery.graphql";

const SingleKpiQuery = graphql`
  query SingleKpiQuery(
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $category: String
    $clientId: String!
    $metric: String!
    $roll: Int
    $statement: String
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        STATEMENT: $statement
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

type SingleDataPoint = Exclude<
  Exclude<SingleKpiQueryType["response"]["chartData"], null | undefined>[0],
  null | undefined
>;

type SingleKpiProps = {
  readonly filters: SingleKpiQueryType["variables"];
  // completed waves ordered from most recent to least recent
  readonly completedWaves: string[];
  readonly inProgressWave?: string;
  readonly expanded?: boolean;
  readonly label: string;
};

export const SingleKpi: React.FC<SingleKpiProps> = (props: SingleKpiProps) => {
  const { filters, completedWaves, inProgressWave, label, expanded } = props;
  const [lastThreeCompletedDataPoints, setLastThreeCompletedDataPoints] =
    useState<SingleKpiQueryType["response"]["chartData"]>();
  const [inProgressDataPoint, setInProgressDataPoint] =
    useState<SingleDataPoint>();
  const [lastYearData, setLastYearData] =
    useState<SingleKpiQueryType["response"]["chartData"]>();
  const [changeValue, setChangeValue] = useState<number>();
  const [isScore, setIsScore] = useState<boolean>();

  const queryData = useLazyLoadQuery<SingleKpiQueryType>(
    SingleKpiQuery,
    filters,
  );

  useEffect(() => {
    if (!queryData.chartData) {
      return;
    }
    const latestWave = getLatestWaveDate(queryData.chartData);
    if (latestWave === null) {
      return;
    }
    const oneYearAgo = offsetWaveDate(latestWave, 12);
    const allData = queryData.chartData
      .filter((data) => {
        if (!data?.WAVE_DATE) {
          return false;
        }
        return new Date(data.WAVE_DATE) >= new Date(oneYearAgo);
      })
      .sort((a, b) => {
        if (!a?.WAVE_DATE || !b?.WAVE_DATE) {
          return 0;
        }
        return (
          new Date(a.WAVE_DATE).getTime() - new Date(b.WAVE_DATE).getTime()
        );
      });
    setLastYearData(allData);
    const currentMonthDataPoint = allData.find((data) => {
      return inProgressWave && data?.WAVE_DATE === inProgressWave;
    });
    if (currentMonthDataPoint) {
      setInProgressDataPoint(currentMonthDataPoint);
    }
    const latestCompleteData = allData.filter((data) => {
      return data !== currentMonthDataPoint;
    });
    const lastThreeCompletedData = completedWaves
      .map((wave) => {
        return latestCompleteData.find((data) => data?.WAVE_DATE === wave);
      })
      .reverse();
    // pad with nulls if there are less than 3 completed data points
    while (lastThreeCompletedData.length < 3) {
      lastThreeCompletedData.unshift(null);
    }
    // get the last three completed data points
    setLastThreeCompletedDataPoints(lastThreeCompletedData);
    if (lastThreeCompletedData && lastThreeCompletedData?.length > 2) {
      const latestCompleteMonth = lastThreeCompletedData[2];
      const monthBeforeLastComplete = lastThreeCompletedData[1];
      const latestCompleteValue = latestCompleteMonth?.PERCENTAGE;
      const monthBeforeValue = monthBeforeLastComplete?.PERCENTAGE;
      const valueIsScore = latestCompleteMonth?.IS_SCORE ? true : false;
      if (latestCompleteValue && monthBeforeValue) {
        const rawChange = latestCompleteValue - monthBeforeValue;
        const change = valueIsScore ? rawChange : rawChange * 100;
        setChangeValue(change);
        setIsScore(valueIsScore);
      }
    }
  }, [completedWaves, expanded, inProgressWave, queryData.chartData]);

  const getDataPointValue = (dataPoint: SingleDataPoint | null | undefined) => {
    if (!dataPoint || !dataPoint.PERCENTAGE) {
      return "-";
    }
    const valueNumber = dataPoint.IS_SCORE
      ? dataPoint.PERCENTAGE
      : dataPoint.PERCENTAGE * 100;

    return roundValueForDisplay(valueNumber);
  };

  const genKpiValueComponent = (
    dataPoint: SingleDataPoint | null | undefined,
    isBold: boolean,
    index: number,
  ) => {
    if (!expanded && !isBold) {
      return null;
    }
    const value = getDataPointValue(dataPoint);
    const suffix = !dataPoint || dataPoint?.IS_SCORE ? "" : "%";
    return (
      <div
        key={index}
        className="flex items-center w-full h-full px-2"
        style={{
          background: isBold ? "rgba(212, 212, 212, 0.20)" : undefined,
        }}
      >
        <Text
          ff={
            isBold
              ? FontFamily.PPNeueMontrealBold
              : FontFamily.PPNeueMontrealMedium
          }
          size={"28px"}
          ta={"left"}
          maw={"100%"}
        >
          {`${value}${suffix}`}
        </Text>
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center justify-between h-[50px]">
      <div className="min-w-[300px] max-w-[300px]">
        <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"}>
          {label}
        </Text>
      </div>
      {lastThreeCompletedDataPoints?.map((dataPoint, index) => {
        const isBold = index === lastThreeCompletedDataPoints.length - 1;
        return genKpiValueComponent(dataPoint, isBold, index);
      })}
      <div className="flex items-center justify-center h-full min-w-[90px] max-w-[90px]">
        <ChangeIndicatorPill
          value={changeValue || null}
          isSignificant={false}
          isScore={isScore}
        />
      </div>
      {inProgressDataPoint &&
        genKpiValueComponent(inProgressDataPoint, false, 10)}
      <div className="flex items-center justify-center h-full min-w-[80px] max-w-[80px]">
        <GraphChart
          series={[
            {
              data:
                lastYearData?.map((dataPoint) => {
                  if (!dataPoint || !dataPoint.PERCENTAGE) {
                    return 0;
                  }
                  const value = dataPoint.IS_SCORE
                    ? dataPoint.PERCENTAGE
                    : dataPoint.PERCENTAGE * 100;
                  return roundValueForDisplay(value);
                }) || [],
            },
          ]}
          height={"30px"}
          type={"line"}
          options={{
            chart: {
              type: "line",
              sparkline: {
                enabled: true,
              },

              animations: {
                enabled: false,
              },
            },
            colors: ["#000000"],
            stroke: {
              curve: "smooth",
              width: 1.5,
            },
            tooltip: {
              fixed: {
                enabled: false,
              },
              x: {
                show: false,
              },
              y: {
                title: {
                  formatter: () => {
                    return "";
                  },
                },
              },
              marker: {
                show: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

import { Collapse, Divider, Text, rem } from "@mantine/core";
import { useCallback } from "react";
import { RxCaretRight } from "react-icons/rx";

import { ChangeSince } from "../../util/changeSince";
import { CollatedData } from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { DashboardLevelQueryFilters } from "../../view/StandardDashboardTab";
import { PercentageStack } from "./PercentageStack";
import { VisualisationProps } from "./types";

type GroupedPercentageStackProps = {
  readonly height: number;
  readonly dashBoardFilters: DashboardLevelQueryFilters;
  readonly brand: string;
  readonly statementsByGroup: Record<string, string[]>;
  readonly groupOrder?: string[];
  readonly groupDropdownState: Record<string, boolean>;
  readonly toggleGroupDropdownState: (group: string) => void;
  readonly changeSince: ChangeSince;
  readonly colours: string[];
  readonly isSecondary?: boolean;
  readonly updateData: (data: CollatedData) => void;
} & Pick<VisualisationProps, "dashBoardFilters">;

export const GroupedPercentageStack: React.FC<GroupedPercentageStackProps> = (
  props: GroupedPercentageStackProps,
) => {
  const {
    height,
    colours,
    brand,
    changeSince: dateRange,
    statementsByGroup,
    dashBoardFilters,
    groupOrder,
    groupDropdownState,
    toggleGroupDropdownState,
    isSecondary,
    updateData,
  } = props;

  const getGroupDropdownState = useCallback(
    (group: string) => {
      if (groupDropdownState[group] === undefined) {
        return true;
      }
      return groupDropdownState[group];
    },
    [groupDropdownState],
  );

  if (!statementsByGroup) {
    return null;
  }

  return (
    <div
      className="flex flex-col w-full"
      style={{
        height: height,
      }}
    >
      <Divider />
      {Object.keys(statementsByGroup)
        .sort((a, b) =>
          groupOrder
            ? groupOrder.indexOf(a) - groupOrder.indexOf(b)
            : a.localeCompare(b),
        )
        ?.map((group) => {
          if (!group) {
            return null;
          }
          return (
            <div key={group} className="w-full">
              <div
                className="flex flex-row items-center w-full cursor-pointer h-[37px]"
                onClick={() => toggleGroupDropdownState(group)}
              >
                {!isSecondary && (
                  <div className="flex flex-row items-center w-full">
                    <RxCaretRight
                      style={{
                        transform: getGroupDropdownState(group)
                          ? "rotate(90deg)"
                          : "",
                        transition: "transform 0.3s",
                        width: rem(20),
                        height: rem(20),
                      }}
                    />
                    <Text
                      ff={FontFamily.PPNeueMontrealMedium}
                      size={"14px"}
                      p={12}
                    >
                      {group}
                    </Text>
                  </div>
                )}
              </div>
              {getGroupDropdownState(group) === false && <Divider />}
              <Collapse in={getGroupDropdownState(group)}>
                <div className="flex flex-col w-full">
                  <PercentageStack
                    brand={brand}
                    changeSince={dateRange}
                    colours={colours}
                    dashBoardFilters={dashBoardFilters}
                    isSecondary={isSecondary}
                    allStatements={statementsByGroup[group]}
                    updateData={updateData}
                    forceCompact={true}
                  />
                </div>
              </Collapse>
            </div>
          );
        })}
    </div>
  );
};

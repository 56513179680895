/**
 * @generated SignedSource<<46d44ac3052d31c9d96887e15a4553ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SnapshotComparisonQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
};
export type SnapshotComparisonQuery$data = {
  readonly statementGroups: ReadonlyArray<{
    readonly STATEMENT: string | null | undefined;
    readonly STATEMENT_GROUP: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly visualisationFilterOptions: ReadonlyArray<{
    readonly BRAND: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly waveDates: ReadonlyArray<{
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type SnapshotComparisonQuery = {
  response: SnapshotComparisonQuery$data;
  variables: SnapshotComparisonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v6 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v7 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "AUDIENCE1",
      "variableName": "firstAudience"
    },
    {
      "kind": "Variable",
      "name": "AUDIENCE2",
      "variableName": "secondAudience"
    },
    {
      "kind": "Variable",
      "name": "BRAND_METRIC",
      "variableName": "metric"
    },
    {
      "kind": "Variable",
      "name": "CATEGORY",
      "variableName": "category"
    },
    {
      "kind": "Variable",
      "name": "ROLL",
      "variableName": "roll"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v8 = [
  {
    "alias": "visualisationFilterOptions",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "BRAND"
        ]
      },
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "statementGroups",
    "args": [
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT_GROUP",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "waveDates",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "WAVE_DATE"
        ]
      },
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SnapshotComparisonQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "SnapshotComparisonQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "b42b3dfe1e7a6cc46ebe20499c346aee",
    "id": null,
    "metadata": {},
    "name": "SnapshotComparisonQuery",
    "operationKind": "query",
    "text": "query SnapshotComparisonQuery(\n  $clientId: String!\n  $firstAudience: String\n  $secondAudience: String\n  $metric: String!\n  $category: String\n  $roll: Int\n) {\n  visualisationFilterOptions: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"BRAND\"]) {\n    BRAND\n  }\n  statementGroups: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}) {\n    STATEMENT\n    STATEMENT_GROUP\n  }\n  waveDates: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"WAVE_DATE\"]) {\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "65c5e80b0624f700b128a21fa810443b";

export default node;

const passwordRequirements = [
  { requirement: /.{8,}/, error: "8 characters" },
  { requirement: /[0-9]/, error: "1 number" },
  { requirement: /[a-z]/, error: "1 lowercase letter" },
  { requirement: /[A-Z]/, error: "1 uppercase letter" },
  { requirement: /[$&+,:;=?@#|'<>.^*()%!-]/, error: "1 special character" },
];

/**
 * Check if password is complex enough
 * @param value
 * @returns null if password is complex enough, error message otherwise
 */
export const isPasswordComplex = (value: string): string | null => {
  // Get an array of errors that the password has
  const errors = passwordRequirements
    .filter((req) => !req.requirement.test(value))
    .map((req) => req.error);

  if (!errors.length) {
    return null;
  }

  return `Password must include at least ${errors.join(", ")}`;
};

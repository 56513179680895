import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import {
  dateRangeToMonths,
  isDateRange,
  numberOfMonthsToDateRange,
} from "../util/dateRange";
import {
  getFilterDropdownOptions,
  getValidFilterValues,
} from "../util/getValidFilterOptions";
import {
  alphabeticalSort,
  ascendingNumberSort,
  audienceGroupSortFn,
} from "../util/sort";
import { FilterComponentProps, MultiFilterBar } from "./MultiFilterBar";
import { StandardDashboardFilterBarQuery as StandardDashboardTabQueryType } from "./__generated__/StandardDashboardFilterBarQuery.graphql";

export type DashboardLevelFilters = {
  readonly roll: number | null;
  readonly category: string | null;
  readonly firstAudience: string | null;
  readonly secondAudience: string | null;
};

const StandardDashboardFilterBarQuery = graphql`
  query StandardDashboardFilterBarQuery($clientId: String!, $metric: String!) {
    dashboardFilterOptions: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: $metric }
      distinctSelect: [
        "AUDIENCE1"
        "AUDIENCE_GROUP1"
        "AUDIENCE2"
        "AUDIENCE_GROUP2"
        "CATEGORY"
        "ROLL"
      ]
    ) {
      AUDIENCE1
      AUDIENCE_GROUP1
      AUDIENCE2
      AUDIENCE_GROUP2
      CATEGORY
      ROLL
    }
  }
`;

type StandardDashboardFilterBarProps = {
  readonly clientId: string;
  readonly metric: string;
  readonly dashboardFilters: DashboardLevelFilters;
  readonly setDashboardFilters: (filters: DashboardLevelFilters) => void;
};

/**
 * A filter bar for the standard dashboard tabs that allows the user to filter by category, roll, and audience
 *
 * @param props the client id, metric, and onFilterChange function
 * @returns a filter bar for the standard dashboard tabs
 */
export const StandardDashboardFilterBar: React.FC<
  StandardDashboardFilterBarProps
> = (props: StandardDashboardFilterBarProps) => {
  const { clientId, metric, dashboardFilters, setDashboardFilters } = props;

  const [hasDefaultedFilters, setHasDefaultedFilters] = useState(false);

  const queryData = useLazyLoadQuery<StandardDashboardTabQueryType>(
    StandardDashboardFilterBarQuery,
    { clientId, metric },
  );

  const validFilterCombinations = useMemo(() => {
    if (!queryData.dashboardFilterOptions) {
      return [];
    }
    return queryData.dashboardFilterOptions.filter(
      (item): item is NonNullable<typeof item> => !!item,
    );
  }, [queryData.dashboardFilterOptions]);

  // set the default filters to the first option if they haven't been set yet
  useEffect(() => {
    if (
      !hasDefaultedFilters &&
      queryData.dashboardFilterOptions &&
      validFilterCombinations.length > 0
    ) {
      const firstOption = validFilterCombinations[0];
      const bestDefaultOption = validFilterCombinations.find((option) => {
        return (
          option.AUDIENCE1?.toLowerCase() === "total" &&
          (option.AUDIENCE2?.toLowerCase() === "total" || !option.AUDIENCE2) &&
          option.ROLL === 3
        );
      });
      const defaultOption = bestDefaultOption || firstOption;
      setDashboardFilters({
        roll: defaultOption.ROLL || null,
        category: defaultOption.CATEGORY || null,
        firstAudience: defaultOption.AUDIENCE1 || null,
        secondAudience: defaultOption.AUDIENCE2 || null,
      });
      setHasDefaultedFilters(true);
    }
  }, [
    hasDefaultedFilters,
    queryData.dashboardFilterOptions,
    setDashboardFilters,
    validFilterCombinations,
  ]);

  const convertFiltersToApiFormat = useCallback(
    (filters: DashboardLevelFilters) => {
      return {
        AUDIENCE1: filters.firstAudience,
        AUDIENCE2: filters.secondAudience,
        CATEGORY: filters.category,
        ROLL: filters.roll,
      };
    },
    [],
  );

  const setSingleFilter = useCallback(
    (filter: keyof DashboardLevelFilters) =>
      (value: string | number | null) => {
        setDashboardFilters({
          ...dashboardFilters,
          [filter]: value,
        });
      },
    [dashboardFilters, setDashboardFilters],
  );

  // update the dashboard filters when the filter options change
  // accounts for the current filter values in figuring out what the options should be
  const dashboardFilterProps = useMemo(() => {
    if (!validFilterCombinations || validFilterCombinations.length === 0) {
      return;
    }
    const updatedDashboardFilters: FilterComponentProps[] = [
      {
        variant: "dropdown",
        options: getFilterDropdownOptions({
          filterOptions: validFilterCombinations,
          currentFilters: convertFiltersToApiFormat(dashboardFilters),
          filter: "CATEGORY",
          filterSortFn: alphabeticalSort,
        }),
        value: dashboardFilters.category,
        onSelect: setSingleFilter("category"),
        inputLabel: "Category",
        autoSelect: false,
      },
      {
        variant: "dropdown",
        options: [
          ...getValidFilterValues(
            validFilterCombinations,
            convertFiltersToApiFormat(dashboardFilters),
            "ROLL",
          ),
        ]
          .filter((value): value is number => !!value)
          // sort the roll options by the number of months
          .sort(ascendingNumberSort)
          .map(numberOfMonthsToDateRange),
        value: dashboardFilters.roll
          ? numberOfMonthsToDateRange(dashboardFilters.roll)
          : null,
        onSelect: (value) => {
          if (value && isDateRange(value)) {
            setSingleFilter("roll")(dateRangeToMonths[value]);
          }
        },
        inputLabel: "Roll",
        autoSelect: false,
      },
      {
        variant: "dropdown",
        options: getFilterDropdownOptions({
          filterOptions: validFilterCombinations,
          currentFilters: convertFiltersToApiFormat(dashboardFilters),
          filter: "AUDIENCE1",
          filterSortFn: alphabeticalSort,
          groupBy: "AUDIENCE_GROUP1",
          groupSortFn: audienceGroupSortFn,
        }),
        value: dashboardFilters.firstAudience,
        onSelect: setSingleFilter("firstAudience"),
        inputLabel: "Audience",
        autoSelect: false,
      },
      {
        variant: "dropdown",
        options: getFilterDropdownOptions({
          filterOptions: validFilterCombinations,
          currentFilters: convertFiltersToApiFormat(dashboardFilters),
          filter: "AUDIENCE2",
          filterSortFn: alphabeticalSort,
          groupBy: "AUDIENCE_GROUP2",
          groupSortFn: audienceGroupSortFn,
        }),
        value: dashboardFilters.secondAudience,
        onSelect: setSingleFilter("secondAudience"),
        inputLabel: "Secondary Audience",
        autoSelect: false,
      },
    ];
    return updatedDashboardFilters.filter((filter) => {
      return filter.options && filter.options.length > 0;
    });
  }, [
    convertFiltersToApiFormat,
    dashboardFilters,
    setSingleFilter,
    validFilterCombinations,
  ]);

  return dashboardFilterProps ? (
    <MultiFilterBar filters={dashboardFilterProps} />
  ) : null;
};

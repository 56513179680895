import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { Margin } from "@nivo/core";
import { useCallback, useMemo } from "react";

import { colours } from "../util/colours";
import { CirclePackCircleLabel } from "./CirclePackCircleLabel";

export type CirclePackChartProps = {
  readonly height: number;
  readonly data: ReadonlyArray<{
    readonly name: string;
    readonly percentage: number;
    readonly colour?: string;
  }>;
  readonly zoomState?: {
    readonly zoomedId?: string;
    readonly setZoomedId: (id: string | undefined) => void;
  };
  readonly margin?: Partial<Margin>;
};

/**
 * An interactive circle pack chart.
 * Use for showing scores or percentages with a breakdown of categories.
 *
 * @param props The props for the component.
 * @returns The CirclePackChart component.
 */
export const CirclePackChart: React.FC<CirclePackChartProps> = (
  props: CirclePackChartProps,
) => {
  const { height, data, zoomState, margin } = props;

  // match the node id to the colour in the data
  const getNodeColour = useCallback(
    (key: string): string => {
      const colour = data.find((d) => d.name === key)?.colour;

      return colour || colours.primaryBrand.pink;
    },
    [data],
  );

  // transform the data into the format required by the CirclePacking component
  const bubbleData = useMemo(() => {
    return {
      children: data,
    };
  }, [data]);

  return (
    <div className="w-full" style={{ height }}>
      <ResponsiveCirclePacking
        data={bubbleData}
        margin={margin}
        id="name"
        value="percentage"
        animate={true}
        motionConfig={{
          clamp: true,
          mass: 3,
          tension: 500,
          friction: 5,
        }}
        isInteractive={true}
        leavesOnly
        zoomedId={zoomState?.zoomedId}
        onClick={(value) => {
          zoomState?.setZoomedId(
            zoomState?.zoomedId === value.id ? undefined : value.id,
          );
        }}
        enableLabels
        colors={(node) => getNodeColour(node.id)}
        labelComponent={(value) => (
          <CirclePackCircleLabel
            label={value.label}
            node={value.node}
            zoomState={zoomState}
          />
        )}
        labelsSkipRadius={10}
      />
    </div>
  );
};

import { Collapse, Divider, Text, rem } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo } from "react";
import { RxCaretRight } from "react-icons/rx";
import { useLazyLoadQuery } from "react-relay";

import { ChangeSince, changeSinceToMonths } from "../../util/changeSince";
import { tintHexCode } from "../../util/colours";
import {
  CollatedData,
  getDataForWaveDate,
  getEarliestWaveDate,
  getLatestWaveDate,
  offsetWaveDate,
} from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { DashboardLevelQueryFilters } from "../../view/StandardDashboardTab";
import { HorizontalPercentageBar } from "./HorizontalPercentageBar";
import { NestedPercentageStackQuery as NestedPercentageStackQueryType } from "./__generated__/NestedPercentageStackQuery.graphql";
import { VisualisationProps } from "./types";

const LABEL_WIDTH = 200;

const NestedPercentageStackQuery = graphql`
  query NestedPercentageStackQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT_GROUP
      STATEMENT
      WAVE_DATE
      QUESTION_TEXT
    }
  }
`;

type NestedPercentageStackProps = {
  readonly height: number;
  readonly dashBoardFilters: DashboardLevelQueryFilters;
  readonly brand: string;
  readonly statementsByGroup: Record<string, string[]>;
  readonly groupOrder?: string[];
  readonly groupDropdownState: Record<string, boolean>;
  readonly toggleGroupDropdownState: (group: string) => void;
  readonly changeSince: ChangeSince;
  readonly colours: string[];
  readonly isSecondary?: boolean;
  readonly updateData: (data: CollatedData) => void;
} & Pick<VisualisationProps, "dashBoardFilters">;

export const NestedPercentageStack: React.FC<NestedPercentageStackProps> = (
  props: NestedPercentageStackProps,
) => {
  const {
    height,
    brand,
    changeSince,
    statementsByGroup,
    dashBoardFilters,
    groupOrder,
    groupDropdownState,
    toggleGroupDropdownState,
    isSecondary,
    updateData,
  } = props;

  const queryData = useLazyLoadQuery<NestedPercentageStackQueryType>(
    NestedPercentageStackQuery,
    {
      brand,
      ...dashBoardFilters,
    },
  );

  const { height: viewPortHeight } = useViewportSize();

  const colourOptions = useMemo(() => {
    return [
      "#87A13D",
      "#E1E145",
      "#4BA0CD",
      "#FFAAD2",
      "#929E97",
      "#BD5E8D",
      "#D34B28",
      "#D8AE96",
      "#CFDBB5",
      "#8280D4",
      "#C2962C",
    ];
  }, []);

  const compactView = useMemo(() => {
    return viewPortHeight < 1200;
  }, [viewPortHeight]);

  const latestWaveDate = useMemo(() => {
    const latestCompleteWaveDate = getLatestWaveDate(queryData.chartData, true);
    const latestIncompleteWaveDate = getLatestWaveDate(queryData.chartData);
    return latestCompleteWaveDate || latestIncompleteWaveDate;
  }, [queryData.chartData]);

  const latestWaveData: NestedPercentageStackQueryType["response"]["chartData"] =
    useMemo(() => {
      if (!latestWaveDate) {
        return;
      }
      return (
        queryData.chartData?.filter(
          (data) => data?.WAVE_DATE === latestWaveDate,
        ) ?? []
      );
    }, [latestWaveDate, queryData.chartData]);

  useEffect(() => {
    updateData(latestWaveData);
  }, [latestWaveData, updateData]);

  const comparisonWaveData = useMemo(() => {
    if (!latestWaveDate) {
      return;
    }
    const getWaveToCompare = (changeSince: ChangeSince) => {
      const offsetByMonths = changeSinceToMonths[changeSince];
      if (offsetByMonths === 0) {
        return getEarliestWaveDate(queryData.chartData);
      }
      return offsetWaveDate(latestWaveDate, offsetByMonths);
    };
    const waveToCompare = getWaveToCompare(changeSince);
    if (!waveToCompare) {
      return;
    }
    return getDataForWaveDate(queryData.chartData, waveToCompare);
  }, [changeSince, latestWaveDate, queryData.chartData]);

  const getGroupDropdownState = useCallback(
    (group: string) => {
      if (groupDropdownState[group] === undefined) {
        return true;
      }
      return groupDropdownState[group];
    },
    [groupDropdownState],
  );

  const groupSortFn = useCallback(
    (a: string, b: string) => {
      if (statementsByGroup) {
        // Sort groups with only one statement to the top
        const aStatements = statementsByGroup[a];
        const bStatements = statementsByGroup[b];
        if (aStatements?.length === 1 && bStatements?.length !== 1) {
          return -1;
        }
        if (aStatements?.length !== 1 && bStatements?.length === 1) {
          return 1;
        }
      }
      if (groupOrder) {
        return groupOrder.indexOf(a) - groupOrder.indexOf(b);
      }
      return a.localeCompare(b);
    },
    [groupOrder, statementsByGroup],
  );

  const topLevelGroups = useMemo(() => {
    if (!latestWaveData) {
      return;
    }
    const groups = new Set<string>();
    latestWaveData.forEach((data) => {
      const group = data?.STATEMENT_GROUP;
      if (group) {
        groups.add(group);
      }
    });
    return Array.from(groups).sort(groupSortFn);
  }, [groupSortFn, latestWaveData]);

  const allScores: ReadonlyArray<{
    readonly group: string;
    readonly statement: string;
    readonly question: string | undefined;
    readonly score: number;
    readonly change: number | undefined;
  }> = useMemo(() => {
    if (!latestWaveData) {
      return [];
    }
    const questionScoreData = latestWaveData.filter(
      (data) =>
        data && data.STATEMENT_GROUP && data.STATEMENT && data.PERCENTAGE,
    );
    return questionScoreData
      .map((data) => {
        const comparisonPoint = comparisonWaveData?.find(
          (comparisonData) =>
            comparisonData?.STATEMENT === data?.STATEMENT &&
            comparisonData?.QUESTION_TEXT === data?.QUESTION_TEXT,
        );
        const comparisonPercentage = comparisonPoint?.PERCENTAGE;
        const percentage = data?.PERCENTAGE;
        const group = data?.STATEMENT_GROUP;
        const statement = data?.STATEMENT;
        const question = data?.QUESTION_TEXT || undefined;
        if (!data || !percentage || !statement || !group) {
          return null;
        }
        const change =
          comparisonPercentage !== null || comparisonPercentage !== undefined
            ? percentage - comparisonPercentage!
            : undefined;
        return {
          group,
          statement,
          question,
          score: percentage * 100,
          change: change ? change * 100 : undefined,
        };
      })
      .filter((data): data is NonNullable<typeof data> => data !== null);
  }, [comparisonWaveData, latestWaveData]);

  if (!statementsByGroup) {
    return null;
  }

  return (
    <div
      className="flex flex-col w-full"
      style={{
        height: height,
      }}
    >
      <Divider />
      {topLevelGroups?.map((group) => {
        const groupDataPoints = allScores.filter(
          (data) => data.group === group,
        );
        // If there are no questions in the group, render each statement as a bar bolded
        if (groupDataPoints.every((data) => data.question === undefined)) {
          return groupDataPoints.map((point) => (
            <div key={point.statement}>
              <div
                className="flex flex-row items-center w-full"
                style={{
                  paddingRight: isSecondary ? rem(LABEL_WIDTH) : undefined,
                }}
              >
                {!isSecondary && (
                  <Text
                    ff={FontFamily.PPNeueMontrealBold}
                    miw={rem(LABEL_WIDTH)}
                    maw={rem(LABEL_WIDTH)}
                    pl={rem(28)}
                    size={"16px"}
                  >
                    {point.statement}
                  </Text>
                )}
                <HorizontalPercentageBar
                  colour={tintHexCode(
                    colourOptions[topLevelGroups.indexOf(group)],
                    isSecondary ? 0.5 : 1,
                  )}
                  value={point.score}
                  key={point.statement}
                  changeValue={point.change}
                  isSignificantChange={false}
                  alignment={isSecondary ? "right" : "left"}
                  compact={compactView}
                />
              </div>
              <Divider />
            </div>
          ));
        }
        const allGroupStatements = groupDataPoints.map(
          (point) => point.statement,
        );
        const groupStatements = Array.from(new Set<string>(allGroupStatements));
        return (
          <div key={group} className="w-full">
            {/* Render the group title */}
            <div className="flex flex-row items-center w-full h-[37px]">
              {!isSecondary && (
                <div className="flex flex-row items-center w-full">
                  <Text
                    ff={FontFamily.PPNeueMontrealMedium}
                    size={"14px"}
                    pl={rem(28)}
                  >
                    {group}
                  </Text>
                </div>
              )}
            </div>
            <Divider />
            {/* Render the statements */}
            {groupStatements.map((statement) => {
              const statementDataPoint = groupDataPoints.find(
                (data) => data.statement === statement && !data.question,
              );
              return (
                <div key={statement} className="w-full">
                  <div
                    className="flex flex-row items-center w-full cursor-pointer"
                    onClick={() => toggleGroupDropdownState(statement)}
                    style={{
                      paddingRight: isSecondary ? rem(LABEL_WIDTH) : undefined,
                    }}
                  >
                    <div className="flex justify-between w-full">
                      {!isSecondary && (
                        <div
                          className="flex flex-row items-center"
                          style={{
                            minWidth: rem(LABEL_WIDTH),
                            maxWidth: rem(LABEL_WIDTH),
                            paddingLeft: rem(28),
                          }}
                        >
                          <RxCaretRight
                            style={{
                              transform: getGroupDropdownState(statement)
                                ? "rotate(90deg)"
                                : "",
                              transition: "transform 0.3s",
                              width: rem(20),
                              height: rem(20),
                            }}
                          />
                          <Text
                            ff={FontFamily.PPNeueMontrealMedium}
                            size={"14px"}
                            pl={12}
                          >
                            {statement}
                          </Text>
                        </div>
                      )}
                      {statementDataPoint && (
                        <HorizontalPercentageBar
                          colour={tintHexCode(
                            colourOptions[topLevelGroups.indexOf(group)],
                            isSecondary ? 0.5 : 1,
                          )}
                          value={statementDataPoint.score}
                          key={statementDataPoint.statement}
                          changeValue={statementDataPoint.change}
                          isSignificantChange={false}
                          alignment={isSecondary ? "right" : "left"}
                          compact={compactView}
                        />
                      )}
                    </div>
                  </div>
                  {getGroupDropdownState(statement) === false && <Divider />}
                  <Collapse in={getGroupDropdownState(statement)}>
                    <div className="flex flex-col w-full">
                      {groupDataPoints
                        .filter(
                          (data) =>
                            data.statement === statement && data.question,
                        )
                        .map((questionDataPoint) => (
                          <div
                            key={questionDataPoint.question}
                            className="w-full"
                          >
                            <Divider />
                            <div
                              className="flex flex-row items-center w-full"
                              style={{
                                paddingRight: isSecondary
                                  ? rem(LABEL_WIDTH)
                                  : undefined,
                              }}
                            >
                              {!isSecondary && (
                                <Text
                                  ff={FontFamily.PPNeueMontrealBook}
                                  miw={rem(LABEL_WIDTH)}
                                  maw={rem(LABEL_WIDTH)}
                                  pl={60}
                                  size={"13px"}
                                >
                                  {questionDataPoint.question}
                                </Text>
                              )}
                              <HorizontalPercentageBar
                                colour={tintHexCode(
                                  colourOptions[topLevelGroups.indexOf(group)],
                                  isSecondary ? 0.2 : 0.7,
                                )}
                                value={questionDataPoint.score}
                                boldValue={false}
                                key={questionDataPoint.question}
                                changeValue={questionDataPoint.change}
                                isSignificantChange={false}
                                alignment={isSecondary ? "right" : "left"}
                                compact={compactView}
                              />
                            </div>
                          </div>
                        ))}
                      <Divider />
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

import { HoverCard, Text } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useUser } from "../../context/UserContext";
import {
  getDataForWaveDate,
  getLatestWaveDate,
  roundValueForDisplay,
  splitByField,
} from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { getStatementOrderFn } from "../../util/getStatementOrderForClient";
import { Legend, LegendSeries } from "../Legend";
import { SmallLoader } from "../SmallLoader";
import { StackedVerticalBarGraphQuery as StackedVerticalBarGraphQueryType } from "./__generated__/StackedVerticalBarGraphQuery.graphql";
import { VisualisationProps } from "./types";

const StackedVerticalBarGraphQuery = graphql`
  query StackedVerticalBarGraphQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $metric: String!
    $category: String
    $roll: Int
    $statement: String
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        STATEMENT: $statement
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

export const StackedVerticalBarGraph: React.FC<VisualisationProps> = (
  props: VisualisationProps,
) => {
  const { dashBoardFilters, metric } = props;
  const { selectedClient } = useUser();

  const [series, setSeries] =
    useState<
      Record<string, StackedVerticalBarGraphQueryType["response"]["chartData"]>
    >();
  const [seriesAtLastLegendUpdate, setSeriesAtLastLegendUpdate] =
    useState<
      Record<string, StackedVerticalBarGraphQueryType["response"]["chartData"]>
    >();
  const [legendSeries, setLegendSeries] = useState<ReadonlyArray<LegendSeries>>(
    [],
  );

  const [colourOptions] = useState<string[]>([
    "#FFAAD2",
    "#4BA0CD",
    "#929E97",
    "#BD5E8D",
    "#D34B28",
    "#D8AE96",
    "#E1E145",
    "#87A13D",
    "#CFDBB5",
    "#8280D4",
    "#C2962C",
  ]);

  const queryData = useLazyLoadQuery<StackedVerticalBarGraphQueryType>(
    StackedVerticalBarGraphQuery,
    {
      ...dashBoardFilters,
    },
  );

  useEffect(() => {
    const latestWaveDate = getLatestWaveDate(queryData.chartData, true);
    if (!latestWaveDate) {
      return;
    }
    const latestWaveData = getDataForWaveDate(
      queryData.chartData,
      latestWaveDate,
    );
    if (!latestWaveData) {
      return;
    }
    const dataSortedByStatement = [...latestWaveData].sort((a, b) => {
      const statementOrderFn = getStatementOrderFn({
        clientConfig: selectedClient?.config,
        metric: metric || dashBoardFilters.metric,
      });
      return statementOrderFn(a?.STATEMENT, b?.STATEMENT);
    });
    const dataSplitByBrand = splitByField(dataSortedByStatement, "BRAND");

    setSeries(dataSplitByBrand);
  }, [
    dashBoardFilters.metric,
    metric,
    queryData.chartData,
    selectedClient?.config,
    selectedClient?.config?.statementOrder,
  ]);

  // Update the legend series when the data changes
  useEffect(() => {
    if (!series || seriesAtLastLegendUpdate === series) {
      return;
    }
    const statements = Object.values(series)
      .flat()
      .reduce((acc, dataPoint) => {
        if (!dataPoint?.STATEMENT) {
          return acc;
        }
        return acc.add(dataPoint.STATEMENT);
      }, new Set<string>());

    // const brands = Object.keys(series);
    const legendSeriesData: ReadonlyArray<LegendSeries> = Array.from(
      statements,
    ).map((statement, index) => {
      const currentValue = legendSeries.find(
        (legendSeriesToCheck) => legendSeriesToCheck.name === statement,
      );
      const enabled = currentValue ? currentValue.enabled : true;
      return {
        name: statement,
        colour: colourOptions[index % colourOptions.length],
        enabled,
      };
    });

    setLegendSeries(legendSeriesData);
    setSeriesAtLastLegendUpdate(series);
  }, [colourOptions, legendSeries, series, seriesAtLastLegendUpdate]);

  // Toggle the series on the chart
  const onToggleSeries = useCallback(
    (name: string) => {
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name === name) {
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      setLegendSeries(updatedLegendSeries);
    },
    [legendSeries],
  );

  return (
    <div className="flex flex-col h-[calc(100vh-200px)">
      <div className="flex justify-end h-24 mx-4 mb-4">
        {legendSeries.length > 0 && (
          <Legend
            marker="circle"
            series={legendSeries}
            onToggleSeries={onToggleSeries}
          />
        )}
      </div>
      <div className="flex flex-row w-full h-full gap-3 px-3 overflow-auto">
        {series ? (
          Object.entries(series).map(([seriesName, singleSeries]) => {
            const numberEnabled = legendSeries.filter(
              (series) => series.enabled,
            ).length;

            return (
              <div key={seriesName} className="flex flex-col w-full h-[55vh]">
                {singleSeries?.map((dataPoint, index) => {
                  if (!dataPoint) {
                    return null;
                  }
                  const legendSeriesData = legendSeries.findIndex(
                    (series) => series.name === dataPoint.STATEMENT,
                  );
                  if (
                    legendSeriesData === -1 ||
                    legendSeries[legendSeriesData].enabled === false
                  ) {
                    return null;
                  }
                  const barColour =
                    colourOptions[legendSeriesData % colourOptions.length];
                  const percentage = dataPoint?.PERCENTAGE || 0;
                  const value = dataPoint?.IS_SCORE
                    ? percentage
                    : percentage * 100;
                  const suffix = dataPoint?.IS_SCORE ? "" : "%";
                  const percentageLabelSize = numberEnabled < 5 ? "22px" : `xs`;
                  return (
                    <HoverCard
                      key={index}
                      position="top"
                      openDelay={300}
                      withArrow
                    >
                      <HoverCard.Target>
                        <div
                          className="flex flex-col w-full"
                          style={{
                            flex: value,
                            backgroundColor: barColour,
                          }}
                        >
                          {value > 10 && (
                            <Text
                              ff={FontFamily.PPNeueMontrealBold}
                              size={percentageLabelSize}
                              p={"5px"}
                            >
                              {roundValueForDisplay(value)}
                              {suffix}
                            </Text>
                          )}
                        </div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <div className="flex flex-row items-center justify-center">
                          <div
                            className="w-3 h-3 mr-2 rounded-full"
                            style={{ backgroundColor: barColour }}
                          />
                          <Text>{`${dataPoint?.STATEMENT}: ${roundValueForDisplay(value)}${suffix}`}</Text>
                        </div>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  );
                })}
                <Text
                  ff={
                    seriesName?.toLowerCase() ===
                    selectedClient?.name.toLowerCase()
                      ? FontFamily.PPNeueMontrealBold
                      : FontFamily.PPNeueMontrealMedium
                  }
                  w={"100%"}
                  ta={"center"}
                  pt={"sm"}
                  mih={"20px"}
                >
                  {seriesName}
                </Text>
              </div>
            );
          })
        ) : (
          <SmallLoader />
        )}
      </div>
    </div>
  );
};

import { Amplify } from "aws-amplify";

/**
 * Configures the Amplify SDK with the user pool ID and client ID.
 * This is only used for managing user authentication.
 */
export const configureAmplify = (): void => {
  const userPoolId = process.env.REACT_APP_USER_POOL_ID;
  const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
  try {
    if (userPoolId && userPoolClientId) {
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "",
          },
        },
      });
    } else {
      throw new Error("User pool ID is not defined");
    }
  } catch (error) {
    const message =
      error instanceof Error
        ? error.message
        : "Error while configuring amplify";
    throw new Error(message);
  }
};

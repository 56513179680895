import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { Client } from "@tra-nz/platform-common";
import { useEffect, useState } from "react";

import { useUser } from "../context/UserContext";
import { getAllClients } from "../util/getAllClients";

type ConfigureClientModalProps = {
  id: string;
};

export const ConfigureClientModal: React.FC<ConfigureClientModalProps> = (
  props: ConfigureClientModalProps,
) => {
  const { id } = props;
  const { currentUser } = useUser();

  const [client, setClient] = useState<Client>();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const updateClients = async () => {
      const allClients = await getAllClients(currentUser);
      const clientToEdit = allClients.find((client) => client.id === id);
      if (!clientToEdit) {
        notifications.show({
          title: "Error",
          message: "Client not found",
          color: "red",
        });
        modals.closeAll();
      }
      setClient(clientToEdit);
    };
    updateClients();
  }, [currentUser, id]);

  return (
    <div className="w-full">
      {client?.config &&
        JSON.stringify(client.config, null, 2)
          .split("\n")
          .map((line, index) => (
            <Text
              key={index}
              style={{
                whiteSpace: "pre",
              }}
            >
              {line}
            </Text>
          ))}
    </div>
  );
};

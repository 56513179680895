import {
  ComboboxItem,
  ComboboxItemGroup,
  Select,
  SelectProps,
} from "@mantine/core";
import { isString } from "lodash";
import { useCallback, useEffect } from "react";
import { RxCaretRight } from "react-icons/rx";
import { z } from "zod";

import { assertUnreachable } from "../util/assertUnreachable";
import { FontFamily } from "../util/fontFamily";
import classes from "./FilterDropdown.module.css";
import { BaseFilterComponentProps } from "./MultiFilterBar";

export type FilterDropdownProps = BaseFilterComponentProps & {
  readonly variant: "dropdown";
  readonly options: SelectProps["data"];
  readonly autoSelect?: boolean;
  readonly placeholder?: string;
  readonly fullWidth?: boolean;
  readonly searchable?: boolean;
};

const comboboxItemSchema = z.object({
  value: z.string(),
  label: z.string(),
  disabled: z.boolean().optional(),
});

const isComboboxItem = (item: unknown): item is ComboboxItem => {
  return comboboxItemSchema.safeParse(item).success;
};

const comboboxItemArraySchema = z.array(comboboxItemSchema);

const comboboxItemGroupSchema = z.union([
  z.object({
    group: z.string(),
    items: comboboxItemArraySchema,
  }),
  z.object({
    group: z.string(),
    items: z.array(z.string()),
  }),
]);

const comboboxItemGroupArraySchema = z.array(comboboxItemGroupSchema);

const isComboboxItemGroup = (item: unknown): item is ComboboxItemGroup => {
  return comboboxItemGroupSchema.safeParse(item).success;
};

const dropdownOptionsSchema = z.union([
  z.array(z.string()),
  comboboxItemArraySchema,
  comboboxItemGroupArraySchema,
]);

/**
 * A filter dropdown component that allows users to select from a list of options
 *
 * @param props the options to be displayed
 * @returns a filter dropdown component
 */
export const FilterDropdown: React.FC<FilterDropdownProps> = (
  props: FilterDropdownProps,
) => {
  const {
    options,
    value,
    onSelect,
    inputLabel,
    placeholder,
    autoSelect = true,
    fullWidth,
    searchable,
  } = props;

  const optionIncludesValue = useCallback(
    (value: unknown) => {
      const parsedOptions = dropdownOptionsSchema.safeParse(options);
      if (!parsedOptions.success || parsedOptions.data.length === 0) {
        return false;
      }
      const validOptions = parsedOptions.data;

      return validOptions.some((option) => {
        if (isString(option)) {
          return option === value;
        }
        if (isComboboxItem(option)) {
          return option.value === value;
        }
        if (isComboboxItemGroup(option)) {
          return option.items.some((item) => {
            if (isString(item)) {
              return item === value;
            }
            return item.value === value;
          });
        }
        assertUnreachable(option);
      });
    },
    [options],
  );

  // Ensure that the selected value is a valid option
  useEffect(() => {
    if (value && optionIncludesValue(value)) {
      return;
    }
    if (autoSelect === false) {
      return;
    }

    const parsedOptions = dropdownOptionsSchema.safeParse(options);

    if (!parsedOptions.success || parsedOptions.data.length === 0) {
      onSelect(null);
      return;
    }

    const firstOption = parsedOptions.data[0];

    if (isString(firstOption)) {
      onSelect(firstOption);
      return;
    }

    const potentialComboboxItem = comboboxItemSchema.safeParse(firstOption);
    if (potentialComboboxItem.success) {
      onSelect(potentialComboboxItem.data.value);
      return;
    }

    const potentialComboboxItemGroup =
      comboboxItemGroupSchema.safeParse(firstOption);
    if (
      potentialComboboxItemGroup.success &&
      potentialComboboxItemGroup.data.items.length > 0
    ) {
      const firstGroupItem = potentialComboboxItemGroup.data.items[0];
      if (isString(firstGroupItem)) {
        onSelect(firstGroupItem);
        return;
      }
      onSelect(firstGroupItem.value);
      return;
    }

    onSelect(null);
  }, [autoSelect, value, onSelect, optionIncludesValue, options]);

  return options && options.length > 0 ? (
    <Select
      variant="filled"
      disabled={!options || options.length < 1}
      placeholder={placeholder}
      classNames={classes}
      label={inputLabel}
      data={options}
      value={value}
      onChange={onSelect}
      allowDeselect={false}
      rightSection={<RxCaretRight />}
      w={fullWidth ? "100%" : undefined}
      miw={150}
      comboboxProps={{
        position: "bottom",
        middlewares: { flip: false, shift: false },
        offset: 0,
      }}
      styles={{
        input: {
          fontFamily: FontFamily.PPNeueMontrealRegular,
        },
      }}
      searchable={searchable}
    />
  ) : (
    <div />
  );
};

import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { LoadingSpinner } from "../component/LoadingSpinner";
import { CampaignBreakdownTab } from "../view/CampaignBreakdownTab";
import { ClientManagementPage } from "../view/ClientManagementPage";
import { DashboardHome } from "../view/DashboardHome";
import { DashboardTab } from "../view/DashboardTab";
import { DefaultPage } from "../view/DefaultPage";
import { ErrorPage } from "../view/ErrorPage";
import { ForgotPasswordPage } from "../view/ForgotPasswordPage";
import { LoginPage } from "../view/LoginPage";
import { ResetPasswordPage } from "../view/ResetPasswordPage";
import { SetPasswordPage } from "../view/SetPasswordPage";
import { UserManagementPage } from "../view/UserManagementPage";
import { ProtectedRoute } from "./ProtectedRoute";

/**
 * The main router for the application
 *
 * @returns a router
 */
const router = createBrowserRouter([
  {
    path: "*",
    errorElement: <ErrorPage />,
    element: <DefaultPage />,
  },
  {
    path: "/login",
    errorElement: <ErrorPage />,
    element: <LoginPage />,
  },
  {
    path: "/dashboard/:clientId/",
    errorElement: <ErrorPage />,
    element: <ProtectedRoute />,
    children: [
      {
        path: "home",
        element: <DashboardHome />,
      },
      {
        path: ":tabTitle/creative-edge/:campaignId",
        element: <CampaignBreakdownTab />,
      },
      {
        path: ":tabTitle/:visualisationTitle",
        element: <DashboardTab />,
      },
    ],
  },
  {
    path: "/admin",
    errorElement: <ErrorPage />,
    element: <ProtectedRoute />,
    children: [
      {
        path: "clients",
        errorElement: <ErrorPage />,
        element: <ClientManagementPage />,
      },
      {
        path: "users",
        errorElement: <ErrorPage />,
        element: <UserManagementPage />,
      },
    ],
  },
  {
    path: "/forgot-password",
    errorElement: <ErrorPage />,
    element: <ForgotPasswordPage />,
  },
  {
    path: "/set-password",
    errorElement: <ErrorPage />,
    element: <SetPasswordPage />,
  },
  {
    path: "/reset-password",
    errorElement: <ErrorPage />,
    element: <ResetPasswordPage />,
  },
]);

/**
 * The root router for the application
 * @returns a root router
 */
export const RootRouter: React.FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouterProvider router={router} fallbackElement={<LoadingSpinner />} />
    </Suspense>
  );
};

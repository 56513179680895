import axios from "axios";

/**
 * Create an axios instance with the backend data API
 */
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status >= 200 && status < 500,
});

export const setApiClientToken = (token?: string) => {
  if (!token) {
    delete apiClient.defaults.headers.common["Authorization"];
    return;
  }
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

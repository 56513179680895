import { Image, Text, rem } from "@mantine/core";

import creativeEdgeTriangle from "../../assets/creative_edge_triangle_small.svg";
import { FontFamily } from "../../util/fontFamily";

type SummaryScoreProps = {
  readonly score: number;
  readonly withCeTriangle?: boolean;
};

/**
 * Generate Pillar Score component for the campaign list
 *
 * @param props the score to display and whether to display the Creative Edge triangle
 * @returns the summary score component
 */
export const SummaryScore: React.FC<SummaryScoreProps> = (
  props: SummaryScoreProps,
) => {
  const { score, withCeTriangle } = props;

  const roundedScore = Math.round(score);

  if (!withCeTriangle) {
    return (
      <div className="flex flex-row items-center">
        <Text ff={FontFamily.PPNeueMontrealBook} size={"32px"} pr={"xs"}>
          {roundedScore}
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center">
      <Image
        src={creativeEdgeTriangle}
        className="m-2"
        style={{ width: rem(20) }}
      />
      <div className="flex flex-col items-end w-14">
        <Text ff={FontFamily.PPNeueMontrealBold} size={"32px"} pr={"xs"}>
          {roundedScore}
        </Text>
      </div>
    </div>
  );
};

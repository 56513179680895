/**
 * @generated SignedSource<<2df507685e2c5e2b27d8ea688d532852>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VerticalBarGraphQuery$variables = {
  brand?: string | null | undefined;
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
  statement?: string | null | undefined;
};
export type VerticalBarGraphQuery$data = {
  readonly brands: ReadonlyArray<{
    readonly BRAND: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly chartData: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly statements: ReadonlyArray<{
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly waveDates: ReadonlyArray<{
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type VerticalBarGraphQuery = {
  response: VerticalBarGraphQuery$data;
  variables: VerticalBarGraphQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brand"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statement"
},
v8 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v9 = {
  "kind": "Variable",
  "name": "AUDIENCE1",
  "variableName": "firstAudience"
},
v10 = {
  "kind": "Variable",
  "name": "AUDIENCE2",
  "variableName": "secondAudience"
},
v11 = {
  "kind": "Variable",
  "name": "BRAND_METRIC",
  "variableName": "metric"
},
v12 = {
  "kind": "Variable",
  "name": "CATEGORY",
  "variableName": "category"
},
v13 = {
  "kind": "Variable",
  "name": "ROLL",
  "variableName": "roll"
},
v14 = {
  "fields": [
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BRAND",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "STATEMENT",
  "storageKey": null
},
v17 = {
  "fields": [
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "kind": "Variable",
      "name": "BRAND",
      "variableName": "brand"
    },
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    {
      "kind": "Variable",
      "name": "STATEMENT",
      "variableName": "statement"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "WAVE_DATE",
  "storageKey": null
},
v19 = [
  {
    "alias": "brands",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "BRAND"
        ]
      },
      (v14/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v15/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "statements",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "STATEMENT"
        ]
      },
      (v14/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v16/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "chartData",
    "args": [
      (v8/*: any*/),
      (v17/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      (v15/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      (v16/*: any*/),
      (v18/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "waveDates",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "WAVE_DATE"
        ]
      },
      (v17/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v18/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerticalBarGraphQuery",
    "selections": (v19/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerticalBarGraphQuery",
    "selections": (v19/*: any*/)
  },
  "params": {
    "cacheID": "914f1f1c5d94cf47e93526db1859fc4d",
    "id": null,
    "metadata": {},
    "name": "VerticalBarGraphQuery",
    "operationKind": "query",
    "text": "query VerticalBarGraphQuery(\n  $clientId: String!\n  $firstAudience: String\n  $secondAudience: String\n  $brand: String\n  $metric: String!\n  $category: String\n  $roll: Int\n  $statement: String\n) {\n  brands: collatedData(clientId: $clientId, distinctSelect: [\"BRAND\"], filters: {BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience}) {\n    BRAND\n  }\n  statements: collatedData(clientId: $clientId, distinctSelect: [\"STATEMENT\"], filters: {BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience}) {\n    STATEMENT\n  }\n  chartData: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $brand, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll, STATEMENT: $statement}) {\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n  }\n  waveDates: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $brand, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll, STATEMENT: $statement}, distinctSelect: [\"WAVE_DATE\"]) {\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f47b7c58619051ecec7ee320f90e54c";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { CreativeEdgeMetrics } from "../../util/creativeEdge";
import { getFilterDropdownOptions } from "../../util/getValidFilterOptions";
import { alphabeticalSort, audienceGroupSortFn } from "../../util/sort";
import { FilterComponentProps, MultiFilterBar } from "../MultiFilterBar";
import { CreativeEdgeFilterBarQuery as CreativeEdgeFilterBarQueryType } from "./__generated__/CreativeEdgeFilterBarQuery.graphql";

export type CreativeEdgeFilters = {
  readonly audience: string;
  readonly secondaryAudience: string | null;
};

const CreativeEdgeFilterBarQuery = graphql`
  query CreativeEdgeFilterBarQuery(
    $clientId: String!
    $metric: String!
    $campaignId: Int
  ) {
    filterOptions: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: $metric, ROLL: $campaignId }
      distinctSelect: [
        "AUDIENCE1"
        "AUDIENCE_GROUP1"
        "AUDIENCE2"
        "AUDIENCE_GROUP2"
      ]
    ) {
      AUDIENCE1
      AUDIENCE_GROUP1
      AUDIENCE2
      AUDIENCE_GROUP2
    }
  }
`;

type CreativeEdgeFilterBarProps = {
  readonly clientId: string;
  readonly campaignId?: number;
  readonly onFilterChange: (filters: CreativeEdgeFilters) => void;
};

/**
 * A filter bar for the creative edge dashboard tab that allows the user to filter by audience and secondary audience
 *
 * @param props the component props
 * @returns a CreativeEdgeFilterBar component
 */
export const CreativeEdgeFilterBar: React.FC<CreativeEdgeFilterBarProps> = (
  props: CreativeEdgeFilterBarProps,
) => {
  const { clientId, campaignId, onFilterChange } = props;

  const queryData = useLazyLoadQuery<CreativeEdgeFilterBarQueryType>(
    CreativeEdgeFilterBarQuery,
    {
      clientId,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...(campaignId && { campaignId }),
    },
  );

  const validFilterCombinations = useMemo(() => {
    if (!queryData.filterOptions) {
      return [];
    }
    return queryData.filterOptions.filter(
      (item): item is NonNullable<typeof item> => !!item,
    );
  }, [queryData.filterOptions]);

  const [firstAudienceFilter, setFirstAudienceFilter] = useState<string | null>(
    null,
  );
  const [secondAudienceFilter, setSecondAudienceFilter] = useState<
    string | null
  >(null);
  const [noSecondAudience, setNoSecondAudience] = useState<boolean>(false);
  const [dashboardFilters, setDashboardFilters] = useState<
    FilterComponentProps[]
  >([]);
  const [currentSelectedFilters, setCurrentSelectedFilters] = useState<{
    AUDIENCE1: string | null;
    AUDIENCE2: string | null;
  }>({
    AUDIENCE1: firstAudienceFilter || "none",
    AUDIENCE2: noSecondAudience ? null : secondAudienceFilter || "none",
  });

  useEffect(() => {
    onFilterChange({
      audience: firstAudienceFilter || "none",
      secondaryAudience: noSecondAudience
        ? null
        : secondAudienceFilter || "none",
    });
    setCurrentSelectedFilters({
      AUDIENCE1: firstAudienceFilter,
      AUDIENCE2: secondAudienceFilter,
    });
  }, [
    firstAudienceFilter,
    noSecondAudience,
    onFilterChange,
    secondAudienceFilter,
  ]);

  // update the dashboard filters when the filter options change
  // accounts for the current filter values in figuring out what the options should be
  useEffect(() => {
    const updatedDashboardFilters: FilterComponentProps[] = [
      {
        variant: "dropdown",
        options: getFilterDropdownOptions({
          filterOptions: validFilterCombinations,
          currentFilters: currentSelectedFilters,
          filter: "AUDIENCE1",
          filterSortFn: alphabeticalSort,
          groupBy: "AUDIENCE_GROUP1",
          groupSortFn: audienceGroupSortFn,
        }),
        value: firstAudienceFilter,
        onSelect: setFirstAudienceFilter,
        inputLabel: "Audience",
      },
      {
        variant: "dropdown",
        options: getFilterDropdownOptions({
          filterOptions: validFilterCombinations,
          currentFilters: currentSelectedFilters,
          filter: "AUDIENCE2",
          filterSortFn: alphabeticalSort,
          groupBy: "AUDIENCE_GROUP2",
          groupSortFn: audienceGroupSortFn,
        }),
        value: secondAudienceFilter,
        onSelect: setSecondAudienceFilter,
        inputLabel: "Secondary Audience",
      },
    ];
    const NoOptionsRemovedFilters = updatedDashboardFilters.filter(
      (filter) => filter.options && filter.options.length > 0,
    );
    const isSecondAudienceFilterEmpty =
      getFilterDropdownOptions({
        filterOptions: validFilterCombinations,
        currentFilters: currentSelectedFilters,
        filter: "AUDIENCE2",
        filterSortFn: alphabeticalSort,
        groupBy: "AUDIENCE_GROUP2",
        groupSortFn: audienceGroupSortFn,
      })?.length === 0;
    setNoSecondAudience(isSecondAudienceFilterEmpty);
    setDashboardFilters(NoOptionsRemovedFilters);
  }, [
    currentSelectedFilters,
    firstAudienceFilter,
    secondAudienceFilter,
    validFilterCombinations,
  ]);

  return <MultiFilterBar filters={dashboardFilters} />;
};

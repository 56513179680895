import { isString } from "lodash";

export enum DateRange {
  ONE_MONTH = "1 Month",
  THREE_MONTHS = "3 Months",
  SIX_MONTHS = "6 Months",
  TWELVE_MONTHS = "12 Months",
  TWO_YEARS = "24 Months",
  ALL_TIME = "All Time",
}

/**
 * Type guard for DateRange
 * @param value the value to check
 * @returns true if the value is a DateRange
 */
export const isDateRange = (value: string): value is DateRange =>
  Object.values(DateRange).includes(value as DateRange);

/**
 * Converts the given date range to the number of months
 */
export const dateRangeToMonths: Record<DateRange, number> = {
  [DateRange.ONE_MONTH]: 1,
  [DateRange.THREE_MONTHS]: 3,
  [DateRange.SIX_MONTHS]: 6,
  [DateRange.TWELVE_MONTHS]: 12,
  [DateRange.TWO_YEARS]: 24,
  [DateRange.ALL_TIME]: 0,
};

/**
 * Converts the given date range to the number of months
 *
 * @param numberOfMonths the number of months to convert
 * @returns the date range for the given number of months
 */
export const numberOfMonthsToDateRange = (
  numberOfMonths: number,
): DateRange => {
  const roundedMonths = Math.round(numberOfMonths);
  switch (roundedMonths) {
    case 1:
      return DateRange.ONE_MONTH;
    case 3:
      return DateRange.THREE_MONTHS;
    case 6:
      return DateRange.SIX_MONTHS;
    case 12:
      return DateRange.TWELVE_MONTHS;
    case 24:
      return DateRange.TWO_YEARS;
    default:
      return DateRange.ALL_TIME;
  }
};

/**
 * Converts the given number of months to a date range
 *
 * Excludes 1 month as it is not a valid date range
 *
 * @param months the number of months to convert
 * @returns the date range for the given number of months
 */
export const dateToDateRanges = (fromMonth: Date): DateRange[] => {
  const now = new Date();
  const numberOfMonths =
    (now.getFullYear() - fromMonth.getFullYear()) * 12 +
    now.getMonth() -
    fromMonth.getMonth();
  return [
    ...(numberOfMonths > 3 ? [DateRange.THREE_MONTHS] : []),
    ...(numberOfMonths > 6 ? [DateRange.SIX_MONTHS] : []),
    ...(numberOfMonths > 12 ? [DateRange.TWELVE_MONTHS] : []),
    ...(numberOfMonths > 24 ? [DateRange.TWO_YEARS] : []),
    DateRange.ALL_TIME,
  ];
};

/**
 * Converts the given date options to a default date range and valid date ranges
 *
 * @param options the date options to convert
 * @returns the default date range and valid date ranges
 */
export const getDateRangeOptionsFromApiData = (
  options:
    | ReadonlyArray<
        | {
            readonly WAVE_DATE: unknown | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined,
): {
  defaultDateRange: DateRange;
  validDateRanges: DateRange[];
} => {
  if (!options) {
    return {
      defaultDateRange: DateRange.ALL_TIME,
      validDateRanges: [DateRange.ALL_TIME],
    };
  }
  const allWaveEpochs = options
    .map((waveDate) => {
      const dateText = waveDate?.WAVE_DATE;
      if (!isString(dateText)) {
        return null;
      }
      const epochTime = new Date(dateText).getTime();
      if (isNaN(epochTime)) {
        return null;
      }
      return epochTime;
    })
    .filter((epochTime): epochTime is number => epochTime !== null);
  const earliestDate = new Date(Math.min(...allWaveEpochs));
  const validDateRanges = dateToDateRanges(earliestDate);
  const defaultDateRange = validDateRanges.includes(DateRange.TWELVE_MONTHS)
    ? DateRange.TWELVE_MONTHS
    : DateRange.ALL_TIME;

  return {
    defaultDateRange,
    validDateRanges,
  };
};

import {
  ActionIcon,
  Button,
  Group,
  Menu,
  Modal,
  Pagination,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  Client,
  NonSuperUserRole,
  SuperUserRole,
} from "@tra-nz/platform-common";
import { chunk } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FiDelete } from "react-icons/fi";
import {
  MdOutlineDesktopAccessDisabled,
  MdOutlineDesktopWindows,
} from "react-icons/md";
import { TbDotsVertical, TbPencil } from "react-icons/tb";

import { ConfigureUserModal } from "../component/ConfigureUserModal";
import { Navbar } from "../component/Navbar/Navbar";
import { useUser } from "../context/UserContext";
import { User } from "../types/User";
import { UserConfig } from "../types/UserConfig";
import { apiClient } from "../util/apiClient";

/**
 * A page that allows admins to manage users
 * @returns a user management page
 */
export const UserManagementPage: React.FC = () => {
  const { currentUser } = useUser();
  const [activePage, setPage] = useState(1);
  const [users, setUsers] = useState<User[]>([]);
  const [openedEditUser, { open: openEditUser, close: closeEditUser }] =
    useDisclosure(false);
  const [editModeUser, setEditModeUser] = useState<Partial<User> | null>(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [clients, setClients] = useState<Client[]>();

  const fetchData = useCallback(async () => {
    setClients(currentUser?.clients);
    setLoading(true);
    try {
      const usersData = await apiClient.get("/users");
      if (usersData.status === 200 && Array.isArray(usersData.data.users)) {
        setUsers(usersData.data.users);
      } else {
        notifications.show({
          title: "Error",
          message: "Failed to load users",
          color: "red",
        });
      }
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Failed to load users",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  }, [currentUser?.clients]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchData();
  }, [currentUser, fetchData]);

  /**
   * Function to open the modal to configure a user
   * @param user
   */
  const openConfigureUserModal = useCallback(
    (user?: Partial<User>) => () => {
      openEditUser();
      setEditModeUser(user || null);
      const title = user ? "Edit User" : "Add User";
      setModalTitle(title);
    },
    [openEditUser],
  );

  /**
   * Function to close the modal, reset the edit user modal values to initial state and refresh the users data
   */
  const closeConfigureUserModal = () => {
    modals.closeAll();
    closeEditUser();
    setEditModeUser(null);
    setModalTitle("");
    fetchData();
  };

  /**
   * Checks if a user exists - used to validate when someone is trying to add a user with an existing email
   * @param email the email to check
   * @returns if the user exists
   */
  const checkIfUserExists = (email: string): boolean => {
    return !!users.find((user) => user.email === email);
  };

  /**
   * Handles the search input
   * @param e the input event
   */
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  /**
   * disable the given user
   * @param UserConfig user to disable
   * @returns void
   */
  const disableUser = useCallback(
    async (user: UserConfig) => {
      if (!user.id) {
        return;
      }
      try {
        const disableResponse = await apiClient.put(
          `/users/${user.id}/disable`,
          null,
        );
        if (disableResponse.status !== 200) {
          throw new Error("Failed to disable user");
        }
        notifications.show({
          title: "Success",
          message: `User ${user.email} has been disabled successfully`,
          color: "teal",
        });
        fetchData();
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to disable user",
          color: "red",
        });
      }
    },
    [fetchData],
  );

  /**
   * enable the given user
   * @param user to enable
   * @returns void
   */
  const enableUser = useCallback(
    async (user: UserConfig) => {
      if (!user.id) {
        return;
      }
      try {
        const enableResponse = await apiClient.put(
          `/users/${user.id}/enable`,
          null,
        );
        if (enableResponse.status !== 200) {
          throw new Error("Failed to enable user");
        }
        notifications.show({
          title: "Success",
          message: `User ${user.email} has been enabled successfully`,
          color: "teal",
        });
        fetchData();
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to enable user",
          color: "red",
        });
      }
    },
    [fetchData],
  );

  /**
   * delete the given user
   * @param user to delete
   * @returns void
   */
  const deleteUser = useCallback(
    async (user: UserConfig) => {
      if (!user.id) {
        return;
      }
      try {
        const deleteResponse = await apiClient.delete(`/users/${user.id}`);
        if (deleteResponse.status !== 204) {
          notifications.show({
            title: "Error",
            message: "Failed to delete user!",
            color: "red",
          });
          throw new Error("Failed to delete user");
        }
        notifications.show({
          title: "Success",
          message: `User ${user.email} has been deleted successfully`,
          color: "teal",
        });
        fetchData();
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to delete user",
          color: "red",
        });
      }
    },
    [fetchData],
  );

  /**
   * Shows a confirm modal to disable a user
   * @param user the user to disable
   * @returns void
   */
  const openDisableUserModal = useCallback(
    async (user: UserConfig) => {
      if (!user) {
        return;
      }
      modals.openConfirmModal({
        title: "Disable user",
        centered: true,
        children: (
          <Text size="sm">Are you sure you want to disable {user.email}?</Text>
        ),
        labels: { confirm: "Disable", cancel: "No" },
        confirmProps: { color: "red" },
        onCancel: () => {},
        onConfirm: () => disableUser(user),
      });
    },
    [disableUser],
  );

  /**
   * Shows a confirm modal to delete a user
   * @param user the user to delete
   * @returns void
   */
  const openDeleteUserModal = useCallback(
    async (user: UserConfig) => {
      if (!user) {
        return;
      }
      modals.openConfirmModal({
        title: "Delete user",
        centered: true,
        children: (
          <Text size="sm">Are you sure you want to delete {user.email}?</Text>
        ),
        labels: { confirm: "Delete", cancel: "No" },
        confirmProps: { color: "red" },
        onCancel: () => {},
        onConfirm: () => deleteUser(user),
      });
    },
    [deleteUser],
  );

  /**
   * This Function filters the users based on the search value. It filters the users based on email, role and clients.
   * It then chunks the filtered users into groups of 5. It also sorts the users based on email.
   * @returns the filtered users
   */
  const filteredUsers: User[][] = useMemo(() => {
    const usersToList = !searchValue
      ? users
      : users.filter((user) => {
          const role =
            user.role === SuperUserRole.SUPER_ADMIN
              ? "superadmin"
              : user.clients.map((k) => k.role).join(", ");

          return (
            user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
            role.toLowerCase().includes(searchValue.toLowerCase()) ||
            user.clients
              .map((c) => c.name)
              .join(", ")
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        });
    return chunk(usersToList, 5);
  }, [searchValue, users]);

  const genUserRow = useCallback(
    (user: User) => {
      const usersRole =
        user.role === SuperUserRole.SUPER_ADMIN
          ? "Super Admin"
          : user.clients.some(
                (client) => client.role === NonSuperUserRole.ADMIN,
              )
            ? "Admin"
            : "User";
      return (
        <Table.Tr key={user.id} bg={!user.aws_cognito_id ? "red" : ""}>
          <Table.Td className={!user.enabled ? "line-through" : ""}>
            {user.email}
          </Table.Td>
          <Table.Td>{usersRole}</Table.Td>
          <Table.Td>{user.active ? "Yes" : "No"}</Table.Td>
          <Table.Td className="flex gap-2">
            {user.clients.map((usersClient) => (
              <div
                key={usersClient.id}
                className="grid content-center justify-start gap-1 p-2 border border-gray-500 rounded-md"
              >
                <div>
                  {
                    clients?.find((client) => {
                      return client.id === usersClient.id;
                    })?.name
                  }
                </div>
                <div className="flex w-full border border-t border-gray-200 border-solid dark:border-gray-800"></div>
                <div>{usersClient.role}</div>
              </div>
            ))}
          </Table.Td>
          <Table.Td>
            <Group gap={0} justify="flex-end">
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={openConfigureUserModal({
                  id: user.id,
                  email: user.email,
                  role: user.role,
                  clients: user.clients,
                })}
              >
                <TbPencil className="w-4 h-4" />
              </ActionIcon>
              <Menu
                transitionProps={{ transition: "pop" }}
                withArrow
                position="bottom-end"
                withinPortal
              >
                <Menu.Target>
                  <ActionIcon variant="subtle" color="gray">
                    <TbDotsVertical className="w-4 h-4" />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  {user.enabled ? (
                    <Menu.Item
                      onClick={() =>
                        openDisableUserModal({
                          id: user.id,
                          email: user.email,
                          role: user.role,
                          clients: user.clients,
                        })
                      }
                      leftSection={
                        <MdOutlineDesktopAccessDisabled className="w-4 h-4" />
                      }
                      color="red"
                    >
                      Disable user
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      leftSection={
                        <MdOutlineDesktopWindows className="w-4 h-4" />
                      }
                      color="green"
                      onClick={() => enableUser(user)}
                    >
                      Enable user
                    </Menu.Item>
                  )}
                  {currentUser?.role === SuperUserRole.SUPER_ADMIN && (
                    <Menu.Item
                      leftSection={<FiDelete className="w-4 h-4" />}
                      color="red"
                      onClick={() =>
                        openDeleteUserModal({
                          id: user.id,
                          email: user.email,
                          role: user.role,
                          clients: user.clients,
                        })
                      }
                    >
                      Delete user
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Table.Td>
        </Table.Tr>
      );
    },
    [
      clients,
      currentUser?.role,
      enableUser,
      openConfigureUserModal,
      openDeleteUserModal,
      openDisableUserModal,
    ],
  );

  return (
    <div>
      <Navbar />
      <Modal
        opened={openedEditUser}
        onClose={closeConfigureUserModal}
        title={modalTitle}
        centered
      >
        <ConfigureUserModal
          existingUser={editModeUser}
          closeEdit={closeConfigureUserModal}
          checkIfUserExists={checkIfUserExists}
        />
      </Modal>

      <div className="grid m-4">
        <h1>Manage Users</h1>
        <span>{loading ? "Loading..." : ""}</span>
        {!loading && (
          <div className="grid gap-4">
            <div className="flex justify-between w-full">
              <TextInput
                className="w-1/4 max-w-80"
                placeholder="Search users"
                onChange={handleSearch}
              />
              <Button
                onClick={openConfigureUserModal()}
                leftSection={<FaPlus />}
              >
                Add User
              </Button>
            </div>
            <Table.ScrollContainer minWidth={800}>
              <Table verticalSpacing="xs" highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Role</Table.Th>
                    <Table.Th>Active</Table.Th>
                    <Table.Th>Clients</Table.Th>
                    <Table.Th className="flex justify-end ">Actions</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {clients &&
                    clients.length > 0 &&
                    filteredUsers[activePage - 1]?.map(genUserRow)}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
            <Pagination
              className="flex justify-center"
              total={filteredUsers.length}
              value={activePage}
              onChange={setPage}
              mt="sm"
            />
          </div>
        )}
      </div>
    </div>
  );
};

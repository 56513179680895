import { Text } from "@mantine/core";

import { FontFamily } from "../../util/fontFamily";
import { FOOTER_HEIGHT } from "../Visualisation/VisualisationFooter";

type CampaignFooterProps = {
  readonly baseSizeText: string | null;
};

/**
 * Footer component for the campaign breakdown views
 *
 * @param props the base size text to display
 * @returns the footer component
 */
export const CampaignFooter: React.FC<CampaignFooterProps> = (
  props: CampaignFooterProps,
) => {
  const { baseSizeText } = props;

  return (
    <div
      className="flex items-center w-full ml-5 bg-white"
      style={{ height: FOOTER_HEIGHT }}
    >
      <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
        {baseSizeText}
      </Text>
    </div>
  );
};

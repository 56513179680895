/**
 * @generated SignedSource<<0bbd313ecca4ee9f91bdfde5ea0872a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MultiFilterCommentBreakdownQuery$variables = {
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
  topicMetric: string;
};
export type MultiFilterCommentBreakdownQuery$data = {
  readonly brands: ReadonlyArray<{
    readonly BRAND: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly statementGroups: ReadonlyArray<{
    readonly STATEMENT_GROUP: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly waves: ReadonlyArray<{
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type MultiFilterCommentBreakdownQuery = {
  response: MultiFilterCommentBreakdownQuery$data;
  variables: MultiFilterCommentBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicMetric"
},
v6 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v7 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "AUDIENCE1",
      "variableName": "firstAudience"
    },
    {
      "kind": "Variable",
      "name": "AUDIENCE2",
      "variableName": "secondAudience"
    },
    {
      "kind": "Variable",
      "name": "BRAND_METRIC",
      "variableName": "topicMetric"
    },
    {
      "kind": "Variable",
      "name": "CATEGORY",
      "variableName": "category"
    },
    {
      "kind": "Variable",
      "name": "ROLL",
      "variableName": "roll"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v8 = [
  {
    "alias": "waves",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "WAVE_DATE"
        ]
      },
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WAVE_DATE",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "brands",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "BRAND"
        ]
      },
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BRAND",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "statementGroups",
    "args": [
      (v6/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "STATEMENT_GROUP"
        ]
      },
      (v7/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "STATEMENT_GROUP",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiFilterCommentBreakdownQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultiFilterCommentBreakdownQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "62b8ae4c1e96aad5a1b2c3fadbf5b644",
    "id": null,
    "metadata": {},
    "name": "MultiFilterCommentBreakdownQuery",
    "operationKind": "query",
    "text": "query MultiFilterCommentBreakdownQuery(\n  $clientId: String!\n  $roll: Int\n  $topicMetric: String!\n  $category: String\n  $firstAudience: String\n  $secondAudience: String\n) {\n  waves: collatedData(clientId: $clientId, distinctSelect: [\"WAVE_DATE\"], filters: {BRAND_METRIC: $topicMetric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience}) {\n    WAVE_DATE\n  }\n  brands: collatedData(clientId: $clientId, distinctSelect: [\"BRAND\"], filters: {BRAND_METRIC: $topicMetric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience}) {\n    BRAND\n  }\n  statementGroups: collatedData(clientId: $clientId, distinctSelect: [\"STATEMENT_GROUP\"], filters: {BRAND_METRIC: $topicMetric, CATEGORY: $category, ROLL: $roll, AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience}) {\n    STATEMENT_GROUP\n  }\n}\n"
  }
};
})();

(node as any).hash = "540e79781b2bb2655d29dffb13f68966";

export default node;

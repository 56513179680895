/* eslint-disable sonarjs/no-duplicate-string */
import { Divider, Text, TextInput } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useLazyLoadQuery } from "react-relay";

import { FontFamily } from "../../util/fontFamily";
import { UnpromptedMessageSummaryBoxQuery as UnpromptedMessageSummaryBoxQueryType } from "./__generated__/UnpromptedMessageSummaryBoxQuery.graphql";

const UnpromptedMessageSummaryBoxQuery = graphql`
  query UnpromptedMessageSummaryBoxQuery(
    $clientId: String!
    $questionNum: String!
    $category: String
    $subject: String
    $topic: String
    $commentGroup: String
  ) {
    comment(
      clientId: $clientId
      filters: {
        CATEGORY: $category
        SUBJECT: $subject
        TOPIC: $topic
        COMMENT_GROUP: $commentGroup
        QUESTION_NUM: $questionNum
      }
    ) {
      RESPONDENT_ID
      WAVE_DATE
      TOPIC
      COMMENT
    }
  }
`;

type UnpromptedMessageSummaryBoxProps = {
  readonly clientId: string;
  readonly questionNum: string;
  readonly fromWave?: string;
  readonly toWave?: string;
  readonly selectedCodes?: ReadonlyArray<{
    code: string;
    value: number;
  }>;
  readonly category?: string;
  readonly subject?: string;
  readonly commentGroup?: string | null;
};

/**
 * A component that displays a summary of unprompted messages and a searchable list of the messages.
 *
 * @param props The code and value of the selected code for the comments
 * @returns The UnpromptedMessageSummaryBox component.
 */
export const UnpromptedMessageSummaryBox: React.FC<
  UnpromptedMessageSummaryBoxProps
> = (props: UnpromptedMessageSummaryBoxProps) => {
  const {
    clientId,
    questionNum,
    category,
    commentGroup,
    fromWave,
    subject,
    toWave,
    selectedCodes,
  } = props;

  const { ref, height } = useElementSize();
  const [search, setSearch] = useState("");

  const queryData = useLazyLoadQuery<UnpromptedMessageSummaryBoxQueryType>(
    UnpromptedMessageSummaryBoxQuery,
    {
      clientId,
      questionNum,
      category,
      subject,
      commentGroup,
    },
  );

  const allStatements = useMemo(() => {
    const allComments = queryData.comment;
    if (!allComments) {
      return [];
    }
    const commentsForSelectedCodes = allComments.filter((comment) =>
      selectedCodes?.some(
        (selectedCode) => comment?.TOPIC === selectedCode.code,
      ),
    );

    const notBeforeWave = new Date(fromWave || 0);
    const notAfterWave = new Date(toWave || Date.now());
    const commentsForTimeWindow = commentsForSelectedCodes.filter((comment) => {
      const waveDate = new Date(comment?.WAVE_DATE || "");
      const isAfterNotBeforeWave =
        waveDate.getFullYear() > notBeforeWave.getFullYear() ||
        (waveDate.getFullYear() === notBeforeWave.getFullYear() &&
          waveDate.getMonth() >= notBeforeWave.getMonth());
      const isBeforeNotAfterWave =
        waveDate.getFullYear() < notAfterWave.getFullYear() ||
        (waveDate.getFullYear() === notAfterWave.getFullYear() &&
          waveDate.getMonth() <= notAfterWave.getMonth());

      return isAfterNotBeforeWave && isBeforeNotAfterWave;
    });

    const commentsWithUniqueRespondentId = commentsForTimeWindow.reduce(
      (acc, comment) => {
        if (!acc.some((c) => c?.RESPONDENT_ID === comment?.RESPONDENT_ID)) {
          acc.push(comment);
        }
        return acc;
      },
      [] as typeof commentsForTimeWindow,
    );

    return commentsWithUniqueRespondentId.map(
      (comment) => comment?.COMMENT || "",
    );
  }, [fromWave, queryData.comment, selectedCodes, toWave]);

  const searchedStatements = useMemo(() => {
    if (!search) {
      return allStatements;
    }
    const fuse = new Fuse(allStatements, {
      includeScore: true,
      threshold: 0.3,
    });
    return fuse.search(search).map((result) => result.item);
  }, [allStatements, search]);

  const focusedCode = useMemo(() => {
    if (selectedCodes?.length === 1) {
      return selectedCodes[0];
    }
    return undefined;
  }, [selectedCodes]);

  return (
    <div className="flex w-full h-full">
      <div
        ref={ref}
        className="flex w-full m-5 bg-white border-[#b7b7b7] border-solid rounded-lg"
      >
        <div
          className="flex flex-col justify-between w-full pt-9 p-7"
          style={{
            height,
          }}
        >
          {focusedCode && (
            <div className="flex flex-row w-full">
              <Text
                ff={FontFamily.PPNeueMontrealMedium}
                size={"40px"}
                pb={"xs"}
              >
                <Text span ff={FontFamily.PPNeueMontrealBold}>
                  {focusedCode.value}%
                </Text>{" "}
                said {focusedCode.code}
              </Text>
            </div>
          )}
          {!focusedCode && (
            <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"} pb={"xs"}>
              All comments
            </Text>
          )}
          {/* <Text ff={FontFamily.PPNeueMontrealMedium} size={"14px"} pb={"lg"}>
            {selectedCode ? "Comment Summary" : "All comments"}
          </Text>
          <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"} pb={"sm"}>
            lorem ipsum dolor sit amet consectetur adipiscing elit sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim
            ad minim veniam quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </Text> */}
          <TextInput
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            leftSection={<HiSearch size={16} />}
            placeholder={"Search"}
            my={"lg"}
          />
          <Divider />
          <div className="flex flex-col h-full overflow-y-auto">
            {searchedStatements.map((statement, index) => (
              <div key={index} className="flex flex-col w-full">
                <div className="flex flex-row w-full py-3">
                  <Text ff={FontFamily.PPNeueMontrealBook} size={"14px"}>
                    {`"${statement}"`}
                  </Text>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import { Text } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import { isString } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { useUser } from "../../context/UserContext";
import { offsetWaveDate } from "../../util/dataProcessing";
import { FontFamily } from "../../util/fontFamily";
import { genBrandCompareFn } from "../../util/genBrandCompareFn";
import { NpsSentiment, npsSentimentToHexCode } from "../../util/npsSentiment";
import { Legend, LegendSeries } from "../Legend";
import { FilterComponentProps, MultiFilterBar } from "../MultiFilterBar";
import { CodedUnpromptedMessagesBreakdown } from "./CodedUnpromptedMessagesBreakdown";
import { MultiFilterCommentBreakdownQuery as MultiFilterCommentBreakdownQueryType } from "./__generated__/MultiFilterCommentBreakdownQuery.graphql";
import { VisualisationProps } from "./types";

const MultiFilterCommentBreakdownQuery = graphql`
  query MultiFilterCommentBreakdownQuery(
    $clientId: String!
    $roll: Int
    $topicMetric: String!
    $category: String
    $firstAudience: String
    $secondAudience: String
  ) {
    waves: collatedData(
      clientId: $clientId
      distinctSelect: ["WAVE_DATE"]
      filters: {
        BRAND_METRIC: $topicMetric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
      }
    ) {
      WAVE_DATE
    }
    brands: collatedData(
      clientId: $clientId
      distinctSelect: ["BRAND"]
      filters: {
        BRAND_METRIC: $topicMetric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
      }
    ) {
      BRAND
    }
    statementGroups: collatedData(
      clientId: $clientId
      distinctSelect: ["STATEMENT_GROUP"]
      filters: {
        BRAND_METRIC: $topicMetric
        CATEGORY: $category
        ROLL: $roll
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
      }
    ) {
      STATEMENT_GROUP
    }
  }
`;

const MultiFilterCommentBreakdownOptionsSchema = z.object({
  questionNum: z.string(),
  sentimentMetric: z.string().optional(),
});

/**
 * A view of unprompted comments that uses the general CodedUnpromptedMessagesBreakdown component.
 * Also includes a filter bar for time, brand and group. And a legend for the sentiment.
 *
 * @param props The props for the component.
 * @returns The MultiFilterCommentBreakdown component.
 */
export const MultiFilterCommentBreakdown: React.FC<VisualisationProps> = (
  props: VisualisationProps,
) => {
  const { height, dashBoardFilters, metric, options } = props;
  const { clientId, roll, category, firstAudience, secondAudience } =
    dashBoardFilters;

  const parsedOptions = MultiFilterCommentBreakdownOptionsSchema.parse(options);
  const { sentimentMetric, questionNum } = parsedOptions;

  const { selectedClient } = useUser();

  const { ref, height: filterBarHeight } = useElementSize();

  const [timeFilter, setTimeFilter] = useState<string>("none");
  const [brandFilter, setBrandFilter] = useState<string>("none");
  const [groupFilter, setGroupFilter] = useState<string | null>("none");

  const queryData = useLazyLoadQuery<MultiFilterCommentBreakdownQueryType>(
    MultiFilterCommentBreakdownQuery,
    {
      clientId,
      roll,
      category,
      firstAudience,
      secondAudience,
      topicMetric: metric || "",
    },
  );

  const rangeFromWaveDate = useCallback(
    (waveDate: string): string => {
      const offsetWaveBy = roll === null ? 0 : roll - 1;
      const offsetWave = offsetWaveDate(waveDate, offsetWaveBy);
      const formattedWaveDate = new Date(waveDate).toLocaleDateString("en-GB", {
        year: "2-digit",
        month: "short",
      });
      const formattedOffsetWave = new Date(offsetWave).toLocaleDateString(
        "en-GB",
        {
          year: "2-digit",
          month: "short",
        },
      );
      if (formattedWaveDate === formattedOffsetWave) {
        return formattedWaveDate;
      }

      return `${formattedOffsetWave} - ${formattedWaveDate}`;
    },
    [roll],
  );

  const waveDates: ReadonlyArray<string> = useMemo(() => {
    if (!queryData?.waves) {
      return [];
    }
    return queryData.waves
      .map((wave) => {
        const waveValue = wave?.WAVE_DATE;
        if (isString(waveValue)) {
          return waveValue;
        }
        return "";
      })
      .filter((wave) => wave !== "")
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  }, [queryData?.waves]);

  const brands: ReadonlyArray<string> = useMemo(() => {
    const allBrands = queryData?.brands
      ?.map((brand) => brand?.BRAND)
      .filter((brand) => isString(brand));
    const clientConfig = selectedClient?.config;
    if (!allBrands || !clientConfig) {
      return [];
    }
    const sortFn = genBrandCompareFn(clientConfig);
    return allBrands.sort(sortFn).map((brand) => brand || "");
  }, [queryData?.brands, selectedClient?.config]);

  const statementGroups: ReadonlyArray<string> = useMemo(() => {
    const allStatementGroups = queryData?.statementGroups
      ?.map((group) => group?.STATEMENT_GROUP)
      .filter((group) => isString(group));
    if (!allStatementGroups || allStatementGroups.length === 0) {
      setGroupFilter(null);
    }
    return allStatementGroups?.map((group) => group || "") || [];
  }, [queryData?.statementGroups]);

  const filters: FilterComponentProps[] = [
    {
      variant: "dropdown",
      inputLabel: "Time",
      value: timeFilter,
      options: waveDates.map((wave) => ({
        label: rangeFromWaveDate(wave),
        value: wave,
      })),
      onSelect: (value) => {
        if (value) {
          setTimeFilter(value);
        }
      },
    },
    {
      variant: "dropdown",
      inputLabel: "Brand",
      value: brandFilter,
      options: brands,
      onSelect: (value) => {
        if (value) {
          setBrandFilter(value);
        }
      },
    },
    {
      variant: "dropdown",
      inputLabel: "Group",
      value: groupFilter,
      options: statementGroups,
      onSelect: (value) => {
        if (value) {
          setGroupFilter(value);
        }
      },
    },
  ];

  const [legendSeries, setLegendSeries] = useState<ReadonlyArray<LegendSeries>>(
    [
      {
        name: NpsSentiment.Positive,
        colour: npsSentimentToHexCode(NpsSentiment.Positive),
        enabled: true,
      },
      {
        name: NpsSentiment.Neutral,
        colour: npsSentimentToHexCode(NpsSentiment.Neutral),
        enabled: true,
      },
      {
        name: NpsSentiment.Negative,
        colour: npsSentimentToHexCode(NpsSentiment.Negative),
        enabled: true,
      },
    ],
  );

  const showSentiment: ReadonlyArray<NpsSentiment> = useMemo(() => {
    return legendSeries
      .filter((series) => series.enabled)
      .map((series) => series.name as NpsSentiment);
  }, [legendSeries]);

  const onToggleSentiment = useCallback(
    (sentiment: string) => {
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name === sentiment) {
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      setLegendSeries(updatedLegendSeries);
    },
    [legendSeries],
  );

  return (
    <div
      style={{
        height,
      }}
    >
      <div
        ref={ref}
        className="flex flex-row items-center justify-between w-full"
      >
        <MultiFilterBar filters={filters} />
        <div className="flex flex-col p-3">
          <Text ff={FontFamily.PPNeueMontrealMedium} size="13px" opacity={0.5}>
            Sentiment
          </Text>
          <Legend
            series={legendSeries}
            onToggleSeries={onToggleSentiment}
            marker="circle"
          />
        </div>
      </div>
      <CodedUnpromptedMessagesBreakdown
        height={height - filterBarHeight}
        dashBoardFilters={dashBoardFilters}
        metric={metric}
        options={{
          sentimentMetric,
          questionNum,
          showSentiment,
          subject: brandFilter,
          statementGroup: groupFilter,
          waveDate: timeFilter,
        }}
      />
    </div>
  );
};

import { Divider, TextInput, rem } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import graphql from "babel-plugin-relay/macro";
import React, { Suspense, useMemo, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";

import { AiSummary } from "../component/AiSummary";
import { CampaignListTableHeaderLabel } from "../component/CreativeEdge/CampaignListTableHeaderLabel";
import { CampaignSummaryListItem } from "../component/CreativeEdge/CampaignSummaryListItem";
import {
  CreativeEdgeFilterBar,
  CreativeEdgeFilters,
} from "../component/CreativeEdge/CreativeEdgeFilterBar";
import { TableRow } from "../component/CreativeEdge/TableRow";
import { DashboardTabHeader } from "../component/DashboardTabHeader";
import { FilterDropdown } from "../component/FilterDropdown";
import { NAVBAR_HEIGHT } from "../component/Navbar/Navbar";
import { SmallLoader } from "../component/SmallLoader";
import {
  CampaignSummary,
  CreativeEdgeMetrics,
  CreativeEdgePillars,
  downloadAllCampaigns,
  getCampaignSummaries,
} from "../util/creativeEdge";
import { CampaignListTabQuery as CampaignListTabQueryType } from "./__generated__/CampaignListTabQuery.graphql";

type FilterOptions =
  | "Date"
  | CreativeEdgePillars.CREATIVE_EDGE_SCORE
  | CreativeEdgePillars.REMARKABLE
  | CreativeEdgePillars.REWARDING
  | CreativeEdgePillars.REMEMBERED;

type CampaignListTabProps = {
  readonly clientId: string;
  readonly aiSummary?: string;
  readonly topDiagnostics?: ReadonlyArray<string>;
};

const CampaignListTabQuery = graphql`
  query CampaignListTabQuery(
    $clientId: String!
    $metric: String!
    $audience: String!
    $secondaryAudience: String
  ) {
    campaigns: collatedData(
      clientId: $clientId
      filters: {
        BRAND_METRIC: $metric
        AUDIENCE1: $audience
        AUDIENCE2: $secondaryAudience
      }
    ) {
      PROJECT_ID
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
      ROLL
      QUESTION_TEXT
    }
  }
`;

/**
 * Creative Edge Tab component
 *
 * Has the list of campaigns with their Creative Edge Scores
 * and links to the detailed view of each campaign
 *
 * @returns the Creative Edge Tab component
 */
export const CampaignListTab: React.FC<CampaignListTabProps> = (
  props: CampaignListTabProps,
) => {
  const { aiSummary, clientId } = props;
  const navigate = useNavigate();
  const { height: viewPortHeight } = useViewportSize();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterOptions>("Date");
  const [audienceFilters, setAudienceFilters] = useState<CreativeEdgeFilters>({
    audience: "Total",
    secondaryAudience: "Total",
  });
  const [showAiSummary, setShowAiSummary] = useState<boolean>(false);

  const queryData = useLazyLoadQuery<CampaignListTabQueryType>(
    CampaignListTabQuery,
    {
      clientId,
      metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
      ...audienceFilters,
    },
  );

  const campaigns = useMemo<ReadonlyArray<CampaignSummary>>(() => {
    const { campaigns: rawCampaignData } = queryData;
    return getCampaignSummaries(rawCampaignData);
  }, [queryData]);

  const filteredCampaigns = useMemo<ReadonlyArray<CampaignSummary>>(() => {
    if (!search) {
      return campaigns;
    }
    return campaigns.filter((campaign) =>
      campaign.title.toLowerCase().includes(search.toLowerCase()),
    );
  }, [campaigns, search]);

  const sortedCampaigns = useMemo<ReadonlyArray<CampaignSummary>>(() => {
    if (filter === "Date") {
      return filteredCampaigns;
    }
    return [...filteredCampaigns].sort((a, b) => {
      if (filter === CreativeEdgePillars.CREATIVE_EDGE_SCORE) {
        return b.score - a.score;
      }
      if (filter === CreativeEdgePillars.REMARKABLE) {
        return b.remarkable - a.remarkable;
      }
      if (filter === CreativeEdgePillars.REWARDING) {
        return b.rewarding - a.rewarding;
      }
      if (filter === CreativeEdgePillars.REMEMBERED) {
        return b.remembered - a.remembered;
      }
      return 0;
    });
  }, [filter, filteredCampaigns]);

  return (
    <div className="flex flex-row w-full">
      <div
        className="flex flex-col w-full"
        style={{
          height: viewPortHeight - NAVBAR_HEIGHT,
          width: showAiSummary ? "67%" : "100%",
        }}
      >
        <DashboardTabHeader
          title={"Creative Edge"}
          showAiSummary={() => setShowAiSummary(true)}
          filterComponent={
            <CreativeEdgeFilterBar
              clientId={clientId}
              onFilterChange={setAudienceFilters}
            />
          }
          downloadOptions={[
            {
              label: "Download CSV",
              onClick: () =>
                downloadAllCampaigns({
                  format: "csv",
                  campaigns: sortedCampaigns,
                  audienceFilters: audienceFilters,
                }),
            },
            {
              label: "Download XLSX",
              onClick: () =>
                downloadAllCampaigns({
                  format: "xlsx",
                  campaigns: sortedCampaigns,
                  audienceFilters: audienceFilters,
                }),
            },
          ]}
        />
        <Divider />

        <div className="w-full h-full p-5">
          <div className="flex flex-row w-full">
            <TextInput
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              leftSection={<HiSearch size={16} />}
              placeholder={"Search"}
              mb={"lg"}
              mr={"xl"}
              mt={25}
              w={600}
              maw={600}
            />
            <FilterDropdown
              variant="dropdown"
              inputLabel="Sort by"
              options={[
                "Date",
                CreativeEdgePillars.CREATIVE_EDGE_SCORE,
                CreativeEdgePillars.REMARKABLE,
                CreativeEdgePillars.REWARDING,
                CreativeEdgePillars.REMEMBERED,
              ]}
              value={filter}
              onSelect={(option) => setFilter(option as FilterOptions)}
            />
          </div>
          <Divider />
          {/* Table Header */}

          <TableRow height={rem(30)}>
            <div />
            <CampaignListTableHeaderLabel
              label={"Execution"}
              key={"Execution"}
            />
            <CampaignListTableHeaderLabel
              label={"Creative Edge Score"}
              key={CreativeEdgePillars.CREATIVE_EDGE_SCORE}
            />
            <CampaignListTableHeaderLabel
              label={CreativeEdgePillars.REMARKABLE}
              key={CreativeEdgePillars.REMARKABLE}
            />
            <CampaignListTableHeaderLabel
              label={CreativeEdgePillars.REWARDING}
              key={CreativeEdgePillars.REWARDING}
            />
            <CampaignListTableHeaderLabel
              label={CreativeEdgePillars.REMEMBERED}
              key={CreativeEdgePillars.REMEMBERED}
            />
          </TableRow>
          {/* Table Rows */}
          <div
            className="w-full overflow-x-hidden overflow-y-auto"
            style={{
              height: viewPortHeight - NAVBAR_HEIGHT - 224,
            }}
          >
            {sortedCampaigns.map((campaign, index) => (
              <CampaignSummaryListItem
                key={index}
                campaign={campaign}
                onClick={() => {
                  navigate(`./${campaign.campaignId}`, {
                    relative: "path",
                  });
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: showAiSummary ? undefined : "none",
          width: "33%",
          background: "white",
        }}
        className="flex flex-row h-full"
      >
        {showAiSummary}
        <Divider orientation={"vertical"} />
        <Suspense fallback={<SmallLoader />}>
          <AiSummary
            bodyText={aiSummary}
            dashBoardFilters={{
              clientId,
              metric: CreativeEdgeMetrics.CAMPAIGN_SCORE,
              roll: -1,
              category: "None",
              firstAudience: "None",
              secondAudience: "None",
            }}
            onClose={() => setShowAiSummary(false)}
          />
        </Suspense>
      </div>
    </div>
  );
};

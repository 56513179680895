import { CreativeEdgeFilters } from "../component/CreativeEdge/CreativeEdgeFilterBar";
import { CampaignListTabQuery } from "../view/__generated__/CampaignListTabQuery.graphql";
import { downloadCsv, downloadXlsx } from "./download";

export const CAMPAIGN_IMAGE_BASE_URL =
  "https://content-tra.co.nz/platform/campaign/";

/**
 * Generate the URL for a campaign image
 *
 * @param trackerProjectId the tracker project id
 * @param campaignId the campaign id
 * @returns the campaign image URL
 */
export const genCampaignImageURL = (
  trackerProjectId: number,
  campaignId: number,
) => {
  return `${CAMPAIGN_IMAGE_BASE_URL}${trackerProjectId}_${campaignId}.png`;
};

export enum CreativeEdgeMetrics {
  CAMPAIGN_SCORE = "campaign - scores",
  CAMPAIGN_MESSAGE = "campaign - message",
}

export enum CreativeEdgePillars {
  CREATIVE_EDGE_SCORE = "Creative Edge",
  REMARKABLE = "Remarkable",
  REWARDING = "Rewarding",
  REMEMBERED = "Remembered",
}

export type CampaignSummary = {
  readonly trackerProjectId: number;
  readonly campaignId: number;
  readonly title: string;
  readonly score: number;
  readonly remarkable: number;
  readonly rewarding: number;
  readonly remembered: number;
  readonly diagnostics: Record<string, number>;
  readonly baseSize: number;
};

export type CampaignsData = CampaignListTabQuery["response"]["campaigns"];

/**
 * Split the given campaign data by campaign
 *
 * Can't use the usual splitBy function because the data contains roll numbers for the campaign ID
 *
 * @param data
 * @returns
 */
export const splitByCampaign = (
  data: CampaignsData,
): Record<string, CampaignsData> => {
  if (!data) {
    return {};
  }
  return data.reduce(
    (acc, curr) => {
      if (!curr || !curr.BRAND) {
        return acc;
      }
      const brand = curr.BRAND;
      if (Array.isArray(acc[brand]) === false) {
        acc[brand] = [];
      }
      const currentBrandData = acc[brand];
      if (Array.isArray(currentBrandData)) {
        acc[brand] = [...currentBrandData, curr].sort();
      }
      return acc;
    },
    {} as Record<string, CampaignsData>,
  );
};

/**
 * Get the campaign summaries from the raw campaign data
 *
 * @param rawCampaignData the raw campaign data from the query
 * @returns the campaign summaries
 */
export const getCampaignSummaries = (
  rawCampaignData: CampaignsData,
): CampaignSummary[] => {
  if (rawCampaignData === null || rawCampaignData === undefined) {
    return [];
  }
  // Split the data by campaign
  const dataSplitByCampaign = splitByCampaign(rawCampaignData);
  // Format the data
  const formattedCampaigns = Object.keys(dataSplitByCampaign).map(
    (campaign): CampaignSummary | null => {
      const campaignData = dataSplitByCampaign[campaign];
      if (
        !campaignData ||
        !campaignData[0]?.ROLL ||
        !campaignData[0]?.PROJECT_ID
      ) {
        return null;
      }
      const trackerProjectId = campaignData[0]?.PROJECT_ID;
      const campaignId = campaignData[0]?.ROLL;
      const score = campaignData.find(
        (data) => data?.STATEMENT === CreativeEdgePillars.CREATIVE_EDGE_SCORE,
      )?.PERCENTAGE;
      const remarkable = campaignData.find(
        (data) => data?.STATEMENT === CreativeEdgePillars.REMARKABLE,
      )?.PERCENTAGE;
      const rewarding = campaignData.find(
        (data) => data?.STATEMENT === CreativeEdgePillars.REWARDING,
      )?.PERCENTAGE;
      const remembered = campaignData.find(
        (data) => data?.STATEMENT === CreativeEdgePillars.REMEMBERED,
      )?.PERCENTAGE;

      const diagnostics = campaignData
        .filter((data) => data?.QUESTION_TEXT === null && data?.IS_SCORE === 0)
        .reduce(
          (acc, data) => {
            if (!data?.PERCENTAGE || !data?.STATEMENT) {
              return acc;
            }
            return {
              ...acc,
              [data.STATEMENT]: data.PERCENTAGE,
            };
          },
          {} as Record<string, number>,
        );

      return {
        trackerProjectId,
        campaignId,
        title: campaign,
        score: score || 0,
        remarkable: remarkable || 0,
        rewarding: rewarding || 0,
        remembered: remembered || 0,
        diagnostics,
        baseSize: campaignData[0]?.BASE || 0,
      };
    },
  );
  // Filter out null values
  const allCampaignSummaries = formattedCampaigns.filter(
    (campaign): campaign is CampaignSummary => campaign !== null,
  );
  // Sort by campaignId in descending order
  return allCampaignSummaries.sort((a, b) => b.campaignId - a.campaignId);
};

/**
 * Download all campaigns as a CSV or XLSX file
 *
 * @param format the format to download the file as
 * @param campaigns the campaigns to download
 */
export const downloadAllCampaigns = (options: {
  format: "csv" | "xlsx";
  campaigns: CampaignSummary[] | ReadonlyArray<CampaignSummary>;
  audienceFilters: CreativeEdgeFilters;
}) => {
  const { format, campaigns, audienceFilters } = options;
  const title = "Creative Edge Campaigns";
  const subtitles = [
    `Audience - ${audienceFilters?.audience || "Total"}`,
    ...(audienceFilters?.secondaryAudience
      ? [`Secondary Audience - ${audienceFilters.secondaryAudience}`]
      : []),
  ];
  if (!campaigns || campaigns.length === 0) {
    return;
  }
  const diagnostics = Object.keys(campaigns[0].diagnostics);
  const header = [
    "Campaign",
    "Creative Edge Score",
    "Remarkable",
    "Rewarding",
    "Remembered",
    ...diagnostics,
    "Base Size",
  ];
  const rows = campaigns.map((campaign) => [
    campaign.title,
    campaign.score,
    campaign.remarkable,
    campaign.rewarding,
    campaign.remembered,
    ...diagnostics.map(
      (diagnostic) => `${campaign.diagnostics[diagnostic] * 100}%`,
    ),
    campaign.baseSize,
  ]);
  const data: ReadonlyArray<ReadonlyArray<string | number>> = [
    [title],
    subtitles,
    header,
    ...rows,
  ];
  const filename = `${title}.${format}`;
  const csvTable = data.map((row) => row.join(",")).join("\n");
  if (format === "csv") {
    downloadCsv(csvTable, filename);
  } else {
    downloadXlsx(csvTable, filename);
  }
};

import {
  Button,
  Checkbox,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  Client,
  NonSuperUserRole,
  SuperUserRole,
} from "@tra-nz/platform-common";
import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { MdRemove } from "react-icons/md";

import { useUser } from "../context/UserContext";
import { User } from "../types/User";
import { apiClient } from "../util/apiClient";

type ConfigureUserModalProps = {
  existingUser: Partial<User> | null;
  closeEdit: () => void;
  checkIfUserExists: (email: string) => boolean;
};

/**
 * A modal component to configure a user
 * @param props the existing user data, close function and a function to check if user exists
 * @returns a modal to configure a user
 */
export const ConfigureUserModal: React.FC<ConfigureUserModalProps> = (
  props,
) => {
  const { existingUser, closeEdit, checkIfUserExists } = props;

  const { currentUser } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [userCognitoStatus, setUserCognitoStatus] = useState<string>("");
  const [clientsToSelect, setClientsToSelect] = useState<Client[]>(
    currentUser?.clients || [],
  );
  const [currentUserManagedClients, setCurrentUserManagedClients] = useState<
    Client[]
  >(currentUser?.clients || []);

  const isCurrentUserAdminOfClient = useCallback(
    (clientId: string): boolean =>
      currentUserManagedClients.find((client) => client.id === clientId) !==
      undefined,
    [currentUserManagedClients],
  );

  /**
   * Preparing Combobox data for clients to be displayed in the form
   * If user is Super Admin, all clients are accessible
   * If user is not Super Admin, only clients that are managed by the user are accessible
   */
  const clients = existingUser?.clients
    ? existingUser.clients.map((client) => ({
        selectedClient: {
          value: client.id,
          label: isCurrentUserAdminOfClient(client.id)
            ? client.name
            : undefined,
        },
        disabled: isCurrentUserAdminOfClient(client.id) === false,
        role: {
          value: client.role,
          label: client.role,
        },
      }))
    : [];

  /**
   * useForm hook is used to manage form state and validation
   * Here we are initializing the form with user data passed from props
   */
  const userForm = useForm({
    mode: "uncontrolled",
    validateInputOnBlur: true,
    initialValues: existingUser
      ? {
          ...existingUser,
          clients,
        }
      : {
          id: "",
          email: "",
          role: null,
          clients: [],
        },
    validate: {
      email: (value, values) => {
        if (!isEmail(value)) {
          return "Invalid email";
        }
        if (value && values.id == "" && checkIfUserExists(value)) {
          return "User is already exists";
        }
        return null;
      },
      clients: (value, values) => {
        if (values.role !== SuperUserRole.SUPER_ADMIN && value.length === 0) {
          return "User should have at least one client";
        }
        return null;
      },
    },
  });

  /**
   * Function to adjust clients dropdown
   * This function is called when a client is added or removed from the form
   * It filters out the clients that are already selected in the form
   * and sets the remaining clients to be displayed in the dropdown
   */
  const adjustClientsToSelect = useCallback(() => {
    setClientsToSelect(
      currentUserManagedClients.filter(
        (managedClient) =>
          !userForm
            .getValues()
            .clients.find((uc) => uc.selectedClient.value === managedClient.id),
      ) || [],
    );
  }, [currentUserManagedClients, userForm]);

  useEffect(() => {
    if (currentUser) {
      const currentManagedClients = currentUser.clients.filter(
        (client) => client.role === NonSuperUserRole.ADMIN,
      );
      setCurrentUserManagedClients(currentManagedClients);
    }
    if (existingUser) {
      const fetchUserStatus = async () => {
        setLoading(true);
        try {
          // const usersData: { data: { enable: string; status: string } } =
          const userStatusResponse = await apiClient.get(
            `/users/${existingUser.id}/status`,
          );
          if (userStatusResponse.status === 200) {
            setUserCognitoStatus(userStatusResponse.data.status);
          }
          if (userStatusResponse.status !== 200) {
            notifications.show({
              title: "Failed",
              message: "Failed to fetch user status",
              color: "red",
            });
          }
        } catch (error) {
          notifications.show({
            title: "Failed",
            message: "Failed to fetch user status",
            color: "red",
          });
        } finally {
          setLoading(false);
        }
      };
      fetchUserStatus();
      adjustClientsToSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to add client to the user form when user is not a Super Admin
   */
  const addClientPermission = () => {
    if (currentUser) {
      const labeledNonManagedClients = clientsToSelect
        .map((clientOption) => ({
          value: clientOption.id,
          label: clientOption.name,
          disabled: isCurrentUserAdminOfClient(clientOption.id) === false,
        }))
        // Sort the clients to display the disabled clients at the end
        .sort((a, b) => {
          if (a.disabled && !b.disabled) {
            return 1;
          }
          if (!a.disabled && b.disabled) {
            return -1;
          }
          return 0;
        })[0];
      userForm.setFieldValue("clients", [
        ...userForm.getValues().clients,
        {
          selectedClient: {
            value: labeledNonManagedClients.value,
            label: labeledNonManagedClients.label,
          },
          disabled: false,
          role: {
            value: NonSuperUserRole.USER,
            label: NonSuperUserRole.USER,
          },
        },
      ]);
      adjustClientsToSelect();
    }
  };

  /**
   * Function to reinvite a user with an email to setup their first password
   * Button for this should only appear ff the user is already invited and has not setup the password
   * This feature is also useful when user's temporary password expires
   */
  const reInviteUser = async () => {
    if (existingUser) {
      try {
        const reinviteResponse = await apiClient.put(
          `/users/${existingUser.id}/reinvite`,
          {},
        );
        if (reinviteResponse.status !== 200) {
          notifications.show({
            title: "Failed",
            message: "Failed to reinvite user",
            color: "red",
          });
          return;
        }
        closeEdit();
        notifications.show({
          title: "Success",
          message: `User ${existingUser.email} has been sent an invite again with a new password to setup`,
          color: "teal",
        });
      } catch (error) {
        notifications.show({
          title: "Failed",
          message: "Failed to reinvite user",
          color: "red",
        });
      }
    }
  };

  /**
   * Removes a client from the form
   * @param formIndex
   * @returns
   */
  const removeClientFromForm = (formIndex: number) => () => {
    userForm.setFieldValue(
      "clients",
      userForm.getValues().clients.filter((_, index) => index !== formIndex),
    );
    adjustClientsToSelect();
  };
  const formValidate = () => {
    userForm.validate();
    return Object.keys(userForm.errors).length === 0;
  };

  /**
   * Handles inviting a user with an email to setup their first password
   * @returns
   */
  const handleInvite = async () => {
    if (!formValidate()) {
      return;
    }
    const user = userForm.getValues();
    if (user) {
      const payload = {
        email: user.email,
        role: user.role,
        clients:
          user.role === SuperUserRole.SUPER_ADMIN
            ? undefined
            : user.clients.map((client) => ({
                client_id: client.selectedClient.value,
                role: client.role.value,
                name: client.selectedClient.label,
              })),
      };
      try {
        const response = await apiClient.post("/users", payload);
        closeEdit();
        if (response.status !== 201) {
          notifications.show({
            title: "Failed",
            message: "Failed to invite user",
            color: "red",
          });
          return;
        }
        notifications.show({
          title: "User created",
          message: `Users ${user.email} has been invited successfully`,
          color: "teal",
        });
      } catch (error) {
        if ((error as AxiosError).response?.status === 409) {
          const message = "User already exists";
          userForm.setFieldError("email", message);
          notifications.show({
            title: "Failed",
            message: message,
            color: "red",
          });
        }
      }
    }
  };

  /**
   * Handles saving the edited user
   * @returns
   */
  const handleSave = async () => {
    if (!formValidate()) {
      return;
    }
    const user = userForm.getValues();
    if (user) {
      const payload = {
        role: user.role,
        clients:
          user.role === SuperUserRole.SUPER_ADMIN
            ? undefined
            : user.clients.map((client) => ({
                client_id: client.selectedClient.value,
                role: client.role.value,
                name: client.selectedClient.label,
              })),
      };
      try {
        const updateResponse = await apiClient.put(
          `/users/${user.id}`,
          payload,
        );
        closeEdit();
        if (updateResponse.status === 200) {
          notifications.show({
            title: `User ${user.email} has been updated`,
            message: "",
            color: "teal",
          });
          return;
        }
        notifications.show({
          title: "Failed",
          message: "Failed to update user",
          color: "red",
        });
      } catch (error) {
        notifications.show({
          title: "Failed",
          message: "Failed to update user",
          color: "red",
        });
      }
    }
  };

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className="grid gap-2.5 text-xs text-black rounded ">
          <div className="flex gap-2.5 justify-between bg-white rounded-2xl">
            {existingUser ? (
              <div>{existingUser.email}</div>
            ) : (
              <TextInput
                label="Enter Email"
                placeholder="Enter Email"
                value={userForm.getValues().email}
                onChange={(e) =>
                  userForm.setFieldValue("email", e.target.value)
                }
              />
            )}
          </div>
          {currentUser?.role === SuperUserRole.SUPER_ADMIN && (
            <div className="flex gap-2.5 mt-2.5 cursor-pointer">
              <Checkbox
                checked={
                  userForm.getValues().role === SuperUserRole.SUPER_ADMIN
                }
                onChange={(e) =>
                  userForm.setFieldValue(
                    "role",
                    e.target.checked ? SuperUserRole.SUPER_ADMIN : null,
                  )
                }
                label="Is Super admin"
              />
            </div>
          )}

          {userForm.getValues().role !== SuperUserRole.SUPER_ADMIN && (
            <div className="grid gap-2.5">
              {userForm.getValues().clients.map((c, index) => (
                <div key={index} className="flex gap-2.5">
                  <Select
                    data={currentUser?.clients
                      .filter(
                        (currentUserClient) =>
                          clientsToSelect.find(
                            (l) => currentUserClient.id === l.id,
                          ) || currentUserClient.id === c.selectedClient.value,
                      )
                      .map((uc) => ({
                        value: uc.id,
                        label: uc.name,
                        disabled: isCurrentUserAdminOfClient(uc.id) === false,
                      }))}
                    disabled={
                      c.disabled || currentUserManagedClients.length === 1
                    }
                    placeholder="Select Client"
                    value={c.selectedClient ? c.selectedClient.value : null}
                    onChange={(_value, option) => {
                      if (option) {
                        userForm.setFieldValue(
                          `clients.${index}.selectedClient`,
                          option,
                        );
                        adjustClientsToSelect();
                      }
                    }}
                  />
                  <Select
                    data={[
                      {
                        value: NonSuperUserRole.USER,
                        label: NonSuperUserRole.USER,
                      },
                      {
                        value: NonSuperUserRole.ADMIN,
                        label: NonSuperUserRole.ADMIN,
                      },
                    ]}
                    disabled={c.disabled}
                    placeholder="Select Role"
                    value={c.role ? c.role.value : null}
                    onChange={(_value, option) => {
                      if (option) {
                        userForm.setFieldValue(`clients.${index}.role`, option);
                      }
                    }}
                  />
                  <Button
                    display={c.disabled ? "none" : "block"}
                    onClick={removeClientFromForm(index)}
                    disabled={userForm.getValues().clients.length === 1}
                  >
                    <MdRemove />
                  </Button>
                </div>
              ))}
              {clientsToSelect.length > 0 && (
                <Button onClick={addClientPermission}>Add Client</Button>
              )}
            </div>
          )}
          {Object.keys(userForm.errors).length > 0 && (
            <div className="text-red-500">
              {Object.values(userForm.errors).join(" & ")}
            </div>
          )}
          <div className="flex justify-end gap-2.5 pl-20 mt-2.5 whitespace-nowrap">
            <Button onClick={closeEdit}>Cancel</Button>
            {existingUser && existingUser.email && (
              <Button onClick={handleSave}>Save</Button>
            )}
            {!existingUser && <Button onClick={handleInvite}>Invite</Button>}
            {userCognitoStatus === "FORCE_CHANGE_PASSWORD" && (
              <Button onClick={reInviteUser}>Reinvite</Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
